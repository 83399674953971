<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->
<vp-common-dialog [hideBody]="confirmArgs.operationType === mailOperations.SaveDraft" (closeClicked)="cancel()">
  <div dialog-title>
    <ng-container
      *ngIf="confirmArgs.operationType === mailOperations.EmptyFolder || confirmArgs.operationType === mailOperations.DeleteSavedSearch || confirmArgs.operationType === mailOperations.RemoveAttachment || confirmArgs.operationType === mailOperations.SecondInstance">
      {{ "WARNING" | translate }}</ng-container>
    <ng-container *ngIf="confirmArgs.operationType === mailOperations.SaveDraft">{{ "SAVE_AS_DRAF_LABEL" | translate }}
    </ng-container>
    <mat-icon *ngIf="confirmArgs.operationType === mailOperations.EmptyFolder">warning</mat-icon>

    <ng-container *ngIf="currentUrl.startsWith('/calendar')">
      <ng-container
        *ngIf="confirmArgs.operationType === mailOperations.TrashFolder||confirmArgs.operationType === mailOperations.DeleteFolder">
        {{ 'CALENDARS.DELETE_CALENDAR_LBL' | translate }}</ng-container>
    </ng-container>

    <ng-container *ngIf="!currentUrl.startsWith('/calendar')">
      <ng-container
        *ngIf="confirmArgs.operationType === mailOperations.TrashFolder||confirmArgs.operationType === mailOperations.DeleteFolder">
        {{ "DELETE_FOLDER_ACTION" | translate }}</ng-container>
    </ng-container>
  </div>

  <div dialog-body>
    <ng-container *ngIf="currentUrl.startsWith('/calendar')">
      <ng-container *ngIf="confirmArgs.operationType === mailOperations.TrashFolder">
        {{ "CALENDARS.TRASH_CALENDAR_CONFIRM_MSG" | translate: folder}}
      </ng-container>
      <ng-container *ngIf="confirmArgs.operationType === mailOperations.DeleteFolder">
        {{ "CALENDARS.DELETE_CALENDAR_CONFIRM_MSG" | translate: folder}}
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!currentUrl.startsWith('/calendar')">
      <ng-container *ngIf="confirmArgs.operationType === mailOperations.TrashFolder">
        {{ "TRASH_FODLER_CONFIRM_MSG" | translate: folder}}
      </ng-container>

      <ng-container *ngIf="confirmArgs.operationType === mailOperations.DeleteFolder">
        {{ "DELETE_FODLER_CONFIRM_MSG" | translate: folder}}
      </ng-container>
    </ng-container>

    <ng-container class="empty-folder-conf-msg" *ngIf="confirmArgs.operationType === mailOperations.EmptyFolder">
      {{ "EMPTY_FODLER_CONFIRM_MSG" | translate: folder}}</ng-container>
    <ng-container class="empty-folder-conf-msg" *ngIf="confirmArgs.operationType === mailOperations.SecondInstance">
      {{ "SECOND_INSTANCE_CONFIRM_MSG" | translate: folder}}</ng-container>
    <ng-container *ngIf="confirmArgs.operationType === mailOperations.RemoveAttachment">
      {{ "REMOVE_ATTACHMENT_WARNING_MSG" | translate: folder}}</ng-container>
    <ng-container class="delete-search-conf-msg" *ngIf="confirmArgs.operationType === mailOperations.DeleteSavedSearch">
      {{ "DELETE_SAVED_SEARCH_CONFIRM_MSG" | translate: folder}}</ng-container>

  </div>

  <div dialog-footer>
    <div class="buttons">
      <button mat-stroked-button
        *ngIf="confirmArgs.operationType === mailOperations.TrashFolder||confirmArgs.operationType === mailOperations.DeleteFolder || confirmArgs.operationType === mailOperations.DeleteSavedSearch"
        class="save_btn" (click)="deleteEmptyFolder()">
        {{ "DELETE_LBL" | translate }}
      </button>
      <button mat-stroked-button color="primary" *ngIf="confirmArgs.operationType === mailOperations.EmptyFolder"
        class="save_btn" (click)="deleteEmptyFolder()">{{ "OK_LBL" | translate }}</button>
      <button mat-stroked-button color="primary" *ngIf="confirmArgs.operationType === mailOperations.SecondInstance"
        class="save_btn" (click)="cancel()">{{ "OK_LBL" | translate }}</button>
      <button mat-stroked-button *ngIf="confirmArgs.operationType === mailOperations.SaveDraft" class="discard_btn"
        id="discard_draft" (click)="discardMail()">{{ "DISCARD_MAIL_LABEL" | translate }}</button>
      <!-- <button mat-stroked-button *ngIf="confirmArgs.operationType !== mailOperations.RemoveAttachment"
        class="cancel_btn" (click)="cancel()" id="cancel-popup-button">{{ "CANCEL_MAIL_LABEL" | translate }}</button> -->
      <button mat-stroked-button color="primary" *ngIf="confirmArgs.operationType === mailOperations.SaveDraft"
        class="save_btn" (click)="saveMail()" id="save-draft-button">{{ "SAVE_MAIL_LABEL" | translate }}</button>
      <button mat-stroked-button *ngIf="confirmArgs.operationType === mailOperations.RemoveAttachment"
        (click)="cancel()">{{ "NO_LABEL" | translate }}</button>
      <button mat-stroked-button color="primary" *ngIf="confirmArgs.operationType === mailOperations.RemoveAttachment"
        (click)="deleteAttachment()">{{ "YES_LABEL" | translate }}</button>
    </div>
  </div>
</vp-common-dialog>
