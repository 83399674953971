
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { IM } from "../../contacts/models/im.model";
import { Other } from "../../contacts/models/other.model";
import { UserURL } from "../../contacts/models/userurl.model";
import { Phone } from "../../contacts/models/phone.model";
import { Contact } from "../../contacts/models/contact.model";
import { Address } from "../../contacts/models/address.model";
import { ContactFolder } from "../../contacts/models/contact-folder.model";
import { ContactConstants } from "../../contacts/shared/contacts-constants";
import { ContactRequest } from "../../contacts/models/contact-request";
import { UserProfile } from "../../shared/models/user-profile";
import * as moment from "moment";
import { isArray } from "util";

export class ContactUtils {

  // Generate Contact object with required parameter from json object
  static getContactWithRequired(contactDetail: any) {
    const contact = new Contact();
    if (isArray(contactDetail)) {
      contactDetail = contactDetail[0];
    }
    contact.id = contactDetail.id;
    contact.parentId = contactDetail.l;
    const contactDetailMap = contactDetail._attrs;
    contact.firstName = contactDetailMap["firstName"];
    contact.lastName = contactDetailMap["lastName"];
    contact.nickname = contactDetailMap["nickname"];
    if (contactDetailMap.hasOwnProperty("image")) {
      contact.image = "/api/getAvatar?user_id=" + contact.id;
    }
    contact.email = this.getEmailAddress(contactDetailMap);
    contact.isSelected = false;
    return contact;
  }

  // Generate Contact object from json object
  static getContact(contactDetail: any, serverUrl, isLoggedInUserProfile: boolean = false) {
    if (isArray(contactDetail)) {
      contactDetail = contactDetail[0];
    }
    const contact = new Contact();
    contact.id = contactDetail.id;
    contact.parentId = contactDetail.l;
    if (contactDetail.tn) {
      contact.tags = (contactDetail.tn).split(",");
      contact.isFavourite = this.isFavoriteContact(contact.tags);
    }
    if (contactDetail.m) {
      contact.m = contactDetail.m;
    }
    const contactDetailMap = contactDetail._attrs;
    contact.firstName = contactDetailMap["firstName"];
    contact.lastName = contactDetailMap["lastName"];
    contact.nickname = contactDetailMap["nickname"];
    contact.type = contactDetailMap["type"];
    contact.fullName = contactDetailMap["fullName"];

    if (contact.firstName && contact.firstName !== undefined) {
      contact.firstName = isArray(contact.firstName) ? contact.firstName[0].trim() : contact.firstName.trim();
    }
    if (contact.lastName && contact.lastName !== undefined) {
      contact.lastName = isArray(contact.lastName) ? contact.lastName[0].trim() : contact.lastName.trim();
    }
    if (contact.nickname && contact.nickname !== undefined) {
      contact.nickname = isArray(contact.nickname) ? contact.nickname[0].trim() : contact.nickname.trim();
    }
    if (contact.type && contact.type !== undefined) {
      if (contact.fullName === undefined && contact.fullName === "") {
        contact.type = contact.type.trim();
        contact.fullName = contact.nickname ? contact.nickname.trim() : "";
      }
    } else {
      if (contact.fullName === undefined || contact.fullName === "") {
        contact.fullName = this.getFullName(contact.firstName, contact.lastName);
      }
    }
    if (contact.fullName) {
      contact.firstLastCharacters = this.getCharacters(contact.firstName, contact.lastName);
    } else {
      if (contact.type && contact.type !== undefined) {
        contact.firstLastCharacters = this.getCharacters(contact.fullName, contact.lastName);
      } else {
        contact.firstLastCharacters = "NL";
      }
    }

    if (contactDetailMap["jobTitle"] !== undefined) {
      contact.jobTitle = contactDetailMap["jobTitle"];
    }

    if (contactDetailMap["company"] !== undefined) {
      contact.company = contactDetailMap["company"];
    }

    if (contactDetailMap["notes"] !== undefined) {
      contact.notes = contactDetailMap["notes"];
    }

    if (contactDetailMap.hasOwnProperty("image")) {
      if (isLoggedInUserProfile) {
        contact.image = serverUrl + "/api/getAvatarProfileManager?user_id=" + contact.id; // + "&timestamp=" + new Date().getTime();
      } else {
        contact.image = serverUrl + "/api/getAvatar?user_id=" + contact.id; // + "&timestamp=" + new Date().getTime();
      }
    }
    contact.email = this.getEmailAddress(contactDetailMap);
    contact.address = this.getAddressDetail(contactDetailMap);
    contact.phone = this.getContactPhones(contactDetailMap);
    contact.userURL = this.getUrlDetail(contactDetailMap);
    contact.other = this.getOtherDetail(contactDetailMap);
    contact.im = this.getIMDetail(contactDetailMap);

    contact.isSelected = false;
    return contact;
  }

  private static getCharacters(firstName, lastName) {
    if (firstName && firstName !== undefined && lastName && lastName !== undefined) {
      const fName: string = firstName.trim();
      const lName: string = lastName.trim();
      const chr1 = fName.length > 0 ? fName.charAt(0) : "";
      const chr2 = lName.length > 0 ? lName.charAt(0) : "";
      return (chr1 + chr2).trim();
    } else if (firstName && firstName !== undefined) {
      const fName: string = firstName.trim();
      const chr1 = fName.length > 0 ? fName.charAt(0) : "";
      return chr1;
    } else if (lastName && lastName !== undefined) {
      const lName: string = lastName.trim();
      const chr2 = lName.length > 0 ? lName.charAt(0) : "";
      return chr2;
    } else {
      return "NA";
    }
  }

  static getFullName(firstName: string, lastName: string): string {
    if (firstName && lastName) {
      return firstName.trim() + " " + lastName.trim();
    } else {
      if (firstName && firstName !== undefined) {
        return firstName.trim();
      } else if (lastName && lastName !== undefined) {
        return lastName.trim();
      }
    }
  }

  private static getEmailAddress(contactDetailMap: any): string[] {
    let emailAddress: string[] = [];
    for (let key in contactDetailMap) {
      if (key === "email") {
        emailAddress.push(contactDetailMap[key]);
        delete contactDetailMap[key];
      }
    }
    for (let key in contactDetailMap) {
      if (contactDetailMap[key] !== undefined && contactDetailMap[key] !== "" && (key.indexOf("email") > -1)) {
        emailAddress.push(contactDetailMap[key]);
      }
    }
    return emailAddress;
  }

  private static getAddressDetail(contactDetailMap: any): Address[] {
    let addressDetail: Address[] = [];
    let addedTypes = [];
    let homeAddress = ["homeCity", "homeStreet", "homeState", "homePostalCode", "homeCountry"];
    let workAddress = ["workCity", "workStreet", "workState", "workPostalCode", "workCountry"];
    let otherAddress = ["otherCity", "otherStreet", "otherState", "otherPostalCode", "otherCountry"];

    for (let key in contactDetailMap) {
      if (key.toLowerCase().indexOf("city") > -1 || key.toLowerCase().indexOf("street") > -1 || key.toLowerCase().indexOf("state") > -1 ||
        key.toLowerCase().indexOf("postalcode") > -1 || key.toLowerCase().indexOf("country") > -1) {

        let type = "home",
          typeKey = "",
          index = "0";

        if (key.startsWith("work")) {
          type = "work";
        } else if (key.startsWith("other")) {
          type = "other";
        }

        if (type === "home") {
          const keyIndex = homeAddress.indexOf(key);

          if (keyIndex === -1) {
            homeAddress.forEach(homeKey => {
              if (key.indexOf(homeKey) > -1) {
                index = key.substring(homeKey.length, key.length);
              }
            });
          }
        } else if (type === "work") {
          const keyIndex = workAddress.indexOf(key);

          if (keyIndex === -1) {
            workAddress.forEach(workKey => {
              if (key.indexOf(workKey) > -1) {
                index = key.substring(workKey.length, key.length);
              }
            });
          }
        } else if (type === "other") {
          const keyIndex = otherAddress.indexOf(key);

          if (keyIndex === -1) {
            otherAddress.forEach(otherKey => {
              if (key.indexOf(otherKey) > -1) {
                index = key.substring(otherKey.length, key.length);
              }
            });
          }
        }

        typeKey = type + index;
        if (addedTypes.indexOf(typeKey) === -1) {
          addedTypes.push(typeKey);
          const address = new Address();
          address.type = type;
          address.city = index !== "0" ? contactDetailMap[type + "City" + index] : contactDetailMap[type + "City"];
          address.street = index !== "0" ? contactDetailMap[type + "Street" + index] : contactDetailMap[type + "Street"];
          address.state = index !== "0" ? contactDetailMap[type + "State" + index] : contactDetailMap[type + "State"];
          address.postalCode = index !== "0" ? contactDetailMap[type + "PostalCode" + index] : contactDetailMap[type + "PostalCode"];
          address.country = index !== "0" ? contactDetailMap[type + "Country" + index] : contactDetailMap[type + "Country"];

          this.removeUndefinedValuse(address);
          if (this.isAddressValid(address)) {
            addressDetail.push(address);
          }
        }
      }
    }
    return addressDetail;
  }

  private static removeUndefinedValuse(address: Address) {
    address.city = address.city !== "undefined" && address.city !== undefined ? address.city : "";
    address.street = address.street !== "undefined" && address.street !== undefined ? address.street : "";
    address.state = address.state !== "undefined" && address.state !== undefined ? address.state : "";
    address.postalCode = address.postalCode !== "undefined" && address.postalCode !== undefined ? address.postalCode : "";
    address.country = address.country !== "undefined" && address.country !== undefined ? address.country : "";
  }

  private static isAddressValid(address: Address): boolean {
    if (!address.city && !address.street && !address.state && !address.postalCode && !address.country) {
      return false;
    }
    return true;
  }

  private static getContactPhones(contactDetailMap): Phone[] {
    let phones: Phone[] = [];
    let contactPhoneKeys = [
      { type: "mobilePhone", label: "MOBILE", order: 0 },
      { type: "workPhone", label: "WORK", order: 1 },
      { type: "workFax", label: "WORKFAX", order: 2 },
      { type: "companyPhone", label: "COMPANY", order: 3 },
      { type: "homePhone", label: "HOME", order: 4 },
      { type: "homeFax", label: "HOMEFAX", order: 5 },
      { type: "pager", label: "PAGER", order: 6 },
      { type: "callbackPhone", label: "CALLBACK", order: 7 },
      { type: "assisstantPhone", label: "ASSISTANT", order: 8 },
      { type: "carPhone", label: "CAR", order: 9 },
      { type: "otherPhone", label: "OTHER", order: 10 },
      { type: "otherFax", label: "OTHERFAX", order: 11 }
    ];
    for (let key in contactDetailMap) {
      if (contactDetailMap[key] !== undefined && contactDetailMap[key] !== "" && (key.indexOf("Phone") > -1 || key.indexOf("Fax") > -1)) {
        contactPhoneKeys.forEach(contactType => {
          if (key === contactType.type || key.indexOf(contactType.type) > -1) {
            let contactPhone: string = contactDetailMap[key];
            let phone = new Phone();
            phone.type = contactType.type;
            phone.label = contactType.label;
            phone.value = contactPhone;
            phone.order = contactType.order;
            phones.push(phone);
          }
        });
      }
    }
    return phones;
  }

  private static getUrlDetail(contactDetailMap): UserURL[] {
    let urls: UserURL[] = [];
    let contactURLKeys = [
      { type: "homeURL", label: "HOME", order: 0 },
      { type: "workURL", label: "WORK", order: 1 },
      { type: "otherURL", label: "OTHER", order: 2 }
    ];
    for (let key in contactDetailMap) {
      if (contactDetailMap[key] !== undefined && contactDetailMap[key] !== "" && key.indexOf("URL") > -1) {
        contactURLKeys.forEach(contactType => {
          if (key === contactType.type || key.indexOf(contactType.type) > -1) {
            let url = new UserURL();
            url.type = contactType.type;
            url.label = contactType.label;
            url.value = contactDetailMap[key];
            url.order = contactType.order;
            urls.push(url);
          }
        });
      }
    }
    return urls;
  }

  private static getOtherDetail(contactDetailMap): Other[] {
    const others: Other[] = [];
    const contactOtherKeys = [
      { type: "birthday", label: "BIRTHDAY", order: 0, valueType: "date" },
      { type: "anniversary", label: "ANNIVERSARY", order: 1, valueType: "date" },
      { type: "custom", label: "CUSTOM", order: 2, valueType: "custom" }
    ];
    for (const key in contactDetailMap) {
      if (contactDetailMap[key] !== undefined && contactDetailMap[key] !== "" && (key.indexOf("birthday") > -1
      || key.indexOf("anniversary") > -1 || key.indexOf("custom") > -1)) {
        contactOtherKeys.forEach(contactType => {
          if (key === contactType.type || key.indexOf(contactType.type) > -1) {
            const other = new Other();
            other.type = contactType.type;
            other.label = contactType.label;
            other.order = contactType.order;
            other.value = this.getValue(contactType.type, contactDetailMap[key]);
            other.valueType = contactType.valueType;
            others.push(other);
          }
        });
      }
    }
    return others;
  }

  static getValue(key, value) {
    if (key !== "custom" && !!value) {
      return this.getDateFormat(value);
    }
    return value;
  }

  private static getIMDetail(contactDetailMap): IM[] {
    const imArray: IM[] = [];
    const contactURLKeys = [
      { type: "xmpp", order: 0 },
      { type: "yahoo", order: 1 },
      { type: "aol", order: 2 },
      { type: "msn", order: 3 },
      { type: "other", order: 4 }
    ];
    for (const key in contactDetailMap) {
      if (contactDetailMap[key] !== undefined && contactDetailMap[key] !== "" && key.indexOf("imAddress") > -1) {
        contactURLKeys.forEach(contactType => {
          if (contactDetailMap[key].indexOf(contactType.type) > -1) {
            const im = new IM();
            im.type = contactType.type;
            const value = contactDetailMap[key];
            im.value = value.substring(value.lastIndexOf("/") + 1, value.length);
            im.order = contactType.order;
            imArray.push(im);
          }
        });
      }
    }
    return imArray;
  }

  static getFullAddress(attrs: Address): string {
    const address = [];
    if (attrs.street) {
      address.push(attrs.street);
    }
    if (attrs.city) {
      address.push(attrs.city);
    }
    if (attrs.state) {
      address.push(attrs.state);
    }
    if (attrs.postalCode) {
      address.push(attrs.postalCode);
    }
    if (attrs.country) {
      address.push(attrs.country);
    }
    return address.join(", ");
  }

  static compareValues(key, order = "asc") {
    return function (a, b) {
      if (!a.hasOwnProperty(key) ||
        !b.hasOwnProperty(key)) {
        return 0;
      }

      const varA = (typeof a[key] === "string") ?
        a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === "string") ?
        b[key].toUpperCase() : b[key];


      let comparison = 0;
      if (varA === undefined) {
        comparison = 1;
      } else if (varB === undefined) {
        comparison = -1;
      } else if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === "desc") ?
          (comparison * -1) : comparison
      );
    };
  }

  static isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  static parseAllContactFolders(res: any): ContactFolder[] {
    const mailSubFolder: ContactFolder[] = [];
    let resp: any = res;
          if (resp.folder[0].folder && resp.folder[0].link) {
            resp = {
              folder: {
                  folder: resp.folder[0].folder,
                  link: resp.folder[0].link
              }
            };
          } else if (resp.folder[0].folder) {
            resp = {
              folder: {
                  folder: resp.folder[0].folder
              }
            };
          } else if (resp.folder[0].link) {
            resp = {
              folder: {
                  link: resp.folder[0].link
              }
            };
          }
    if (resp.folder) {
        if (resp.folder.folder) {
            resp.folder.folder.map(contact => {
                let c: any = {};
                c = contact;
                if (c) {
                    const newFolder: ContactFolder = c as ContactFolder;
                    newFolder.title = newFolder.name;
                    this.checkHasChildFolder(c, newFolder);
                    mailSubFolder.push(newFolder);
                }
            });
        } else {
            let sf: any = {};
            sf = resp.folder;
            const newFolder: ContactFolder = sf as ContactFolder;
            newFolder.title = newFolder.name;
            this.checkHasChildFolder(sf, newFolder);
            mailSubFolder.push(newFolder);
        }
        if (resp.folder.link) {
            if (isArray(resp.folder.link)) {
                resp.folder.link.map(contact => {
                    let s: any = {};
                    s = contact;
                    if (s) {
                        const newFolder: ContactFolder = s as ContactFolder;
                        newFolder.title = newFolder.name;
                        this.checkHasChildFolder(s, newFolder);
                        mailSubFolder.push(newFolder);
                    }
                });
            } else {
                let s: any = {};
                s = resp.folder.link;
                const newFolder: ContactFolder = s as ContactFolder;
                newFolder.title = newFolder.name;
                this.checkHasChildFolder(s, newFolder);
                mailSubFolder.push(newFolder);
            }
        }
    }
    return mailSubFolder;
  }

  static checkHasChildFolder(c: any, folder: ContactFolder) {
    if (c.hasOwnProperty("folder") && isArray(c.folder)) {
        folder.children = [];
        for (let i = 0; i < c.folder.length; i++) {
            folder.children.push(this.generateTreeStructure(c.folder[i], folder));
        }
    } else if (c.hasOwnProperty("folder") && c.folder) {
        folder.children = [];
        folder.children.push(this.generateTreeStructure(c.folder));
    }
  }

  static generateTreeStructure(c: any, parentFolder?: ContactFolder): ContactFolder {
    const folder: ContactFolder = c as ContactFolder;
    folder.title = folder.name;
    if (folder.id.indexOf(":") !== -1) {
        const absPath = folder.absFolderPath;
        if (!!absPath && !!folder) {
            folder.shareFolderSearchPath = parentFolder.name + absPath.substr(absPath.indexOf("/", 2), absPath.length);
        }
    }
    if (c.hasOwnProperty("folder") && isArray(c.folder)) {
        folder.children = [];
        for (let i = 0; i < c.folder.length; i++) {
            folder.children.push(this.generateTreeStructure(c.folder[i], parentFolder));
        }
    } else if (c.hasOwnProperty("folder") && c.folder) {
        folder.children = [];
        folder.children.push(this.generateTreeStructure(c.folder, parentFolder));
    }
    return folder;
  }

  private static isFavoriteContact(tags: any): boolean {
    let isFavorite: boolean = false;
    tags.forEach((tag) => {
      if (tag === ContactConstants.FAVORITE_TAG) {
        isFavorite = true;
      }
    });
    return isFavorite;
  }

  static getPhoneTypes(phoneType: any): any[] {
    let phoneTypes: any[] = [];
    phoneTypes.push({ value: "mobilePhone", viewValue: phoneType.MOBILE });
    phoneTypes.push({ value: "workPhone", viewValue: phoneType.WORK });
    phoneTypes.push({ value: "workFax", viewValue: phoneType.WORK_FAX });
    phoneTypes.push({ value: "companyPhone", viewValue: phoneType.COMPANY });
    phoneTypes.push({ value: "homePhone", viewValue: phoneType.HOME });
    phoneTypes.push({ value: "homeFax", viewValue: phoneType.HOME_FAX });
    phoneTypes.push({ value: "pager", viewValue: phoneType.PAGER });
    phoneTypes.push({ value: "callbackPhone", viewValue: phoneType.CALLBACK });
    phoneTypes.push({
      value: "assisstantPhone",
      viewValue: phoneType.ASSISTENT
    });
    phoneTypes.push({ value: "carPhone", viewValue: phoneType.CAR });
    phoneTypes.push({ value: "otherFax", viewValue: phoneType.OTHER_FAX });
    phoneTypes.push({ value: "otherPhone", viewValue: phoneType.OTHER });
    return phoneTypes;
  }

  static getImTypes(imType: any): any[] {
    let imTypes: any[] = [];
    imTypes.push({ value: "xmpp", viewValue: imType.XMPP });
    imTypes.push({ value: "yahoo", viewValue: imType.YAHOO });
    imTypes.push({ value: "aol", viewValue: imType.AOL });
    imTypes.push({ value: "msn", viewValue: imType.MSN });
    imTypes.push({ value: "other", viewValue: imType.OTHER });
    return imTypes;
  }

  static getAddressTypes(addressType: any): any[] {
    let addressTypes: any[] = [];
    addressTypes.push({ value: "home", viewValue: addressType.HOME });
    addressTypes.push({ value: "work", viewValue: addressType.WORK });
    addressTypes.push({ value: "other", viewValue: addressType.OTHER });
    return addressTypes;
  }

  static getEventTypes(eventType: any): any[] {
    let eventTypes: any[] = [];
    eventTypes.push({ value: "birthday", viewValue: eventType.BIRTHDAY });
    eventTypes.push({
      value: "anniversary",
      viewValue: eventType.ANNIVERSARY
    });
    eventTypes.push({ value: "custom", viewValue: eventType.CUSTOM });
    return eventTypes;
  }

  static getUrlTypes(urlType: any): any[] {
    let urlTypes: any[] = [];
    urlTypes.push({ value: "homeURL", viewValue: urlType.HOME });
    urlTypes.push({ value: "workURL", viewValue: urlType.WORK });
    urlTypes.push({ value: "otherURL", viewValue: urlType.OTHER });
    return urlTypes;
  }

  static getInitialContactDetailState() {
    return {
      firstName: "",
      lastName: "",
      fullName: "",
      nickname: "",
      jobTitle: "",
      company: "",
      notes: "",
      image: "",
      firstLastCharacters: "",
      emails: [{ value: "", key: "email" }],
      urls: [
        {
          type: "homeURL",
          value: "",
          order: "",
        }
      ],
      phones: [
        {
          type: "mobilePhone",
          value: "",
          order: "",
        }
      ],
      addresses: [
        {
          type: "home",
          city: "",
          street: "",
          state: "",
          postalcode: "",
          country: "",
          stateKey: "State",
          cityKey: "City",
          countryKey: "Country",
          streetKey: "Street",
          postalcodeKey: "PostalCode"
        }
      ],
      screens: [
        {
          type: "xmpp",
          value: "",
          key: "imAddress",
          order: ""
        }
      ],
      events: [
        {
          type: "birthday",
          value: "",
          order: ""
        }
      ]
    };
  }

  static getInitialContactGroupDetailState() {
    return {
      fileAs: "",
      nickname: "",
      // emails: [{ value: "", key: "email" }],
    };
  }


  static initializeEditContact(contact: Contact, contactDetails: any) {
    if (contact) {
      if (contact.firstName) {
        contactDetails.firstName = contact.firstName;
      }

      if (contact.lastName) {
        contactDetails.lastName = contact.lastName;
      }

      if (contact.nickname) {
        contactDetails.nickname = contact.nickname;
      }

      if (contact.fullName) {
        contactDetails.fullName = contact.fullName;
      }

      if (contact.jobTitle) {
        contactDetails.jobTitle = contact.jobTitle;
      }

      if (contact.company) {
        contactDetails.company = contact.company;
      }

      if (contact.image) {
        contactDetails.image = contact.image;
      }

      if (contact.notes) {
        contactDetails.notes = contact.notes;
      }

      if (contact.email.length > 0) {
        contactDetails.emails = [];
        contact.email.forEach(value => {
          contactDetails.emails.push({ value: value, key: "email" });
        });
      }

      if (contact.phone.length > 0) {
        contactDetails.phones = [];
        contact.phone.forEach(phone => {
          contactDetails.phones.push({ type: phone.type, value: phone.value, order: phone.order });
        });
      }

      if (contact.im.length > 0) {
        contactDetails.screens = [];
        contact.im.forEach(im => {
          contactDetails.screens.push({ type: im.type, value: im.value, key: "imAddress", order: im.order });
        });
      }

      if (contact.userURL.length > 0) {
        contactDetails.urls = [];
        contact.userURL.forEach(url => {
          contactDetails.urls.push({ type: url.type, value: url.value, order: url.order });
        });
      }

      if (contact.address.length > 0) {
        contactDetails.addresses = [];
        contact.address.forEach(address => {
          contactDetails.addresses.push(
            {
              type: address.type,
              city: address.city,
              street: address.street,
              state: address.state,
              postalcode: address.postalCode,
              country: address.country,
              stateKey: "State",
              cityKey: "City",
              countryKey: "Country",
              streetKey: "Street",
              postalcodeKey: "PostalCode"
            }
          );
        });
      }

      contactDetails.firstLastCharacters = contact.firstLastCharacters;

      if (contact.other.length > 0) {
        contactDetails.events = [];
        contact.other.forEach(other => {
          let value;
          if (other.type !== "custom") {
            value = new Date(other.value);
          } else {
            value = other.value;
          }
          contactDetails.events.push({ type: other.type, value: value, order: other.order });
        });
      }

    }
  }

  static getOfflineContact(contact: Contact, contactDetails: any, isCreate: boolean) {
    if (isCreate) {
      contact.id = Math.random().toString(36).substr(2, 9);
      contact.isOfflineCreate = true;
    } else {
      contact.isOfflineModify = true;
    }

    if (contactDetails) {
      if (contactDetails.firstName) {
        contact.firstName = contactDetails.firstName;
      }

      if (contactDetails.lastName) {
        contact.lastName = contactDetails.lastName;
      }

      if (contactDetails.nickname) {
        contact.nickname = contactDetails.nickname;
      }

      if (contactDetails.fullName) {
        contact.fullName = contactDetails.fullName;
      }

      contact.fullName = this.getFullName(contact.firstName, contact.lastName);

      if (contactDetails.jobTitle) {
        contact.jobTitle = contactDetails.jobTitle;
      }

      if (contactDetails.company) {
        contact.company = contactDetails.company;
      }

      if (contactDetails.image) {
        contact.image = contactDetails.image;
      }

      if (contactDetails.notes) {
        contact.notes = contactDetails.notes;
      }

      if (contactDetails.emails.length > 0) {
        contact.email = [];
        contactDetails.emails.forEach(data => {
          if (data.value &&  contact.email.indexOf(data.value) === -1) {
            contact.email.push(data.value);
          }
        });
      }

      if (contactDetails.phones.length > 0) {
        contact.phone = [];
        contactDetails.phones.forEach(phone => {
          if (phone.value) {
            contact.phone.push({ type: phone.type, value: phone.value, order: phone.order, label: phone.type });
          }
        });
      }

      if (contactDetails.screens.length > 0) {
        contact.im = [];
        contactDetails.screens.forEach(im => {
          if (im.value) {
            contact.im.push({ type: im.type, value: im.value, order: im.order });
          }
        });
      }

      if (contactDetails.urls.length > 0) {
        contact.userURL = [];
        contactDetails.urls.forEach(url => {
          if (url.value) {
            contact.userURL.push({ type: url.type, value: url.value, order: url.order, label: url.type });
          }
        });
      }

      if (contactDetails.addresses.length > 0) {
        contact.address = [];
        contactDetails.addresses.forEach(address => {
          if (address.city || address.street || address.state || address.country) {
            contact.address.push(
              {
                type: address.type,
                city: address.city,
                street: address.street,
                state: address.state,
                postalCode: address.postalCode,
                country: address.country
              }
            );
          }
        });
      }

      contact.firstLastCharacters = this.getCharacters(contact.firstName, contact.lastName);

      if (contactDetails.events.length > 0) {
        contact.other = [];
        contactDetails.events.forEach(other => {
          let value;
          let valueType;
          if (other.type !== "custom") {
            value = new Date(other.value);
            valueType = "date";
          } else {
            value = other.value;
            valueType = "custom";
          }
          if (other.value) {
            contact.other.push({ type: other.type, value: value, order: other.order, valueType: valueType, label: other.type });
          }
        });
      }
    }

    return contact;
  }

  static getContactField(fieldKey, fieldValue, req: ContactRequest) {
    req.contactAttrs.push({
      key: fieldKey,
      value: fieldValue
    });
  }

  static setIndexForEmails(oldEmails: any[], newEmails: any[], req: ContactRequest) {
    if (oldEmails.length > 0 && !!newEmails) {
      if (oldEmails.length > newEmails.length) {
        for (let j = newEmails.length; j < oldEmails.length; j++) {
          this.getContactField(newEmails[0].key + (j + 1), "", req);
        }
      }
    }
  }

  static setIndexForIm(oldIms: any[], newIms: any[], req: ContactRequest) {
    if (oldIms.length > 0) {
      if (oldIms.length > newIms.length) {
        for (let j = newIms.length; j < oldIms.length; j++) {
          this.getContactField(newIms[0].key + (j + 1), "", req);
        }
      }
    }
  }

  static setIndexForPhones(phoneTypes: any[], oldPhones: any[], newPhones: any[], req: ContactRequest) {
    if (oldPhones.length > 0) {
      phoneTypes.forEach((phoneType) => {
        let phonesLen = oldPhones
          .filter(ph => ph.type === phoneType.value).length;
        let detailPhoneLen = newPhones
          .filter(ph => ph.type === phoneType.value).length;
        if (phonesLen > detailPhoneLen) {
          for (let j = detailPhoneLen; j < phonesLen; j++) {
            if (j === 0) {
              this.getContactField(phoneType.value, "", req);
            } else {
              this.getContactField(phoneType.value + (j + 1), "", req);
            }
          }
        }
      });
    }
  }

  static setIndexForUrls(urlTypes: any[], oldUrls: any[], newUrls: any[], req: ContactRequest) {
    if (oldUrls.length > 0) {
      urlTypes.forEach((urlType) => {
        let urlsLen = oldUrls
          .filter(ul => ul.type === urlType.value).length;
        let detailUrlLen = newUrls
          .filter(ul => ul.type === urlType.value).length;
        if (urlsLen > detailUrlLen) {
          for (let j = detailUrlLen; j < urlsLen; j++) {
            if (j === 0) {
              this.getContactField(urlType.value, "", req);
            } else {
              this.getContactField(urlType.value + (j + 1), "", req);
            }
          }
        }
      });
    }
  }

  static setIndexForEvents(eventTypes: any[], oldEvents: any[], newEvents: any[], req: ContactRequest) {
    if (oldEvents.length > 0) {
      eventTypes.forEach((eventType) => {
        let eventsLen = oldEvents
          .filter(et => et.type === eventType.value).length;
        let detailEventLen = newEvents
          .filter(et => et.type === eventType.value).length;
        if (eventsLen > detailEventLen) {
          for (let j = detailEventLen; j < eventsLen; j++) {
            if (j === 0) {
              this.getContactField(eventType.value, "", req);
            } else {
              this.getContactField(eventType.value + (j + 1), "", req);
            }
          }
        }
      });
    }
  }

  static setIndexForAddress(addressTypes: any[], oldAddresses: any[], newAddresses: any[], req: ContactRequest) {
    if (oldAddresses.length > 0) {
      addressTypes.forEach((addressType) => {
        let addressLen = oldAddresses
          .filter(ad => ad.type === addressType.value).length;
        let detailAddressLen = newAddresses
          .filter(ad => ad.type === addressType.value).length;
        if (addressLen > detailAddressLen) {
          for (let j = detailAddressLen; j < addressLen; j++) {
            if (j === 0) {
              this.getContactField(addressType.value + newAddresses[0].cityKey, "", req);
              this.getContactField(addressType.value + newAddresses[0].streetKey, "", req);
              this.getContactField(addressType.value + newAddresses[0].stateKey, "", req);
              this.getContactField(addressType.value + newAddresses[0].postalcodeKey, "", req);
              this.getContactField(addressType.value + newAddresses[0].countryKey, "", req);
            } else {
              this.getContactField(addressType.value + newAddresses[0].cityKey + (j + 1), "", req);
              this.getContactField(addressType.value + newAddresses[0].streetKey + (j + 1), "", req);
              this.getContactField(addressType.value + newAddresses[0].stateKey + (j + 1), "", req);
              this.getContactField(addressType.value + newAddresses[0].postalcodeKey + (j + 1), "", req);
              this.getContactField(addressType.value + newAddresses[0].countryKey + (j + 1), "", req);
            }
          }
        }
      });
    }
  }

  static prepareContactRequest(contactDetails: any, contactRequest: ContactRequest, vncdRequest: any = {}) {
    this.getContactField("firstName", contactDetails.firstName, contactRequest);
    this.getContactField("lastName", contactDetails.lastName, contactRequest);
    this.getContactField("fullName", contactDetails.firstName + " " + contactDetails.lastName, contactRequest);
    this.getContactField("nickname", contactDetails.nickname, contactRequest);
    this.getContactField("jobTitle", contactDetails.jobTitle, contactRequest);
    this.getContactField("company", contactDetails.company, contactRequest);
    this.getContactField("notes", contactDetails.notes, contactRequest);
    vncdRequest.first_name = contactDetails.firstName;
    vncdRequest.last_name = contactDetails.lastName;
    vncdRequest.job_title = contactDetails.jobTitle;
    vncdRequest.company = contactDetails.company;
    vncdRequest.notes = contactDetails.notes;
    vncdRequest.addresses_attributes = [];
    vncdRequest.urls_attributes = [];
    vncdRequest.phones_attributes = [];
    vncdRequest.emails_attributes = [];
    vncdRequest.events_attributes = [];
    vncdRequest.im_accounts_attributes = [];
    vncdRequest.custom_fields_attributes = [];
    let i = 0;
    contactDetails.emails.forEach((email, index) => {
      if (i === 0) {
        this.getContactField(email.key, email.value, contactRequest);
        i++;
      } else if (i !== 0) {
        this.getContactField(email.key + (i + 1), email.value, contactRequest);
        i++;
      }
      vncdRequest.emails_attributes.push({
        email: email.value,
        email_type: "work"
      });
    });

    contactDetails.phones.forEach((phone, index) => {
      let phoneTypeIndex = contactDetails.phones
        .filter(ph => ph.type === phone.type)
        .indexOf(phone);
      if (phoneTypeIndex === 0) {
        this.getContactField(phone.type, phone.value, contactRequest);
      } else if (phoneTypeIndex !== 0) {
        this.getContactField(
          phone.type + (phoneTypeIndex + 1),
          phone.value,
          contactRequest
        );
      }
      vncdRequest.phones_attributes.push({
        phone: phone.value,
        phone_type: phone.type.replace("Phone", "")
      });
    });

    contactDetails.urls.forEach((url, index) => {
      let urlIndex = contactDetails.urls
        .filter(ul => ul.type === url.type)
        .indexOf(url);
      if (urlIndex === 0) {
        this.getContactField(url.type, url.value, contactRequest);
      } else if (urlIndex !== 0) {
        this.getContactField(url.type + (urlIndex + 1), url.value, contactRequest);
      }
      vncdRequest.urls_attributes.push({
        url: url.value,
        url_type: url.type.replace("URL", "")
      });
    });

    contactDetails.events.forEach((event, index) => {
      let eventIndex = contactDetails.events
        .filter(et => et.type === event.type)
        .indexOf(event);
      if (eventIndex === 0) {
        this.getContactField(event.type, this.getDateFormat(event.value.toString()), contactRequest);
      } else if (eventIndex !== 0) {
        this.getContactField(event.type + (eventIndex + 1), this.getDateFormat(event.value.toString()), contactRequest);
      }
      vncdRequest.events_attributes.push({
        event_date: event.value,
        event_type: event.type
      });
    });

    contactDetails.addresses.forEach((address, index) => {
      let addressIndex = contactDetails.addresses
        .filter(ad => ad.type === address.type)
        .indexOf(address);
      if (addressIndex === 0) {
        this.getContactField(address.type + address.cityKey, address.city, contactRequest);
        this.getContactField(address.type + address.streetKey, address.street, contactRequest);
        this.getContactField(address.type + address.stateKey, address.state, contactRequest);
        this.getContactField(address.type + address.postalcodeKey, address.postalcode, contactRequest);
        this.getContactField(address.type + address.countryKey, address.country, contactRequest);
      } else if (addressIndex > 0) {
        this.getContactField(address.type + address.cityKey + (addressIndex + 1), address.city, contactRequest);
        this.getContactField(address.type + address.streetKey + (addressIndex + 1), address.street, contactRequest);
        this.getContactField(address.type + address.stateKey + (addressIndex + 1), address.state, contactRequest);
        this.getContactField(address.type + address.postalcodeKey + (addressIndex + 1), address.postalcode, contactRequest);
        this.getContactField(address.type + address.countryKey + (addressIndex + 1), address.country, contactRequest);
      }
      vncdRequest.addresses_attributes.push({
        street1: address.street,
        city: address.city,
        state: address.state,
        postcode: address.postalcode,
        country_code: address.country,
        address_type: address.type
      });
    });

    i = 1;
    contactDetails.screens.forEach((im, index) => {
      if (im.value) {
        this.getContactField(im.key + i, im.type + "://" + im.value, contactRequest);
      } else {
        this.getContactField(im.key + i, "", contactRequest);
      }
      i++;
      vncdRequest.im_accounts_attributes.push({
        im_id: im.value,
        im_type: im.type
      });
    });
    contactRequest.vncdRequest = vncdRequest;
  }

  static prepareContactGroupRequest(contactDetails: any, contactRequest: ContactRequest) {
    this.getContactField("nickname", contactDetails.groupName, contactRequest);
    this.getContactField("fullName", contactDetails.groupName, contactRequest);
    this.getContactField("type", "group", contactRequest);
    contactDetails.selectedAddress.forEach((element, index) => {
      if (element.type === "I") {
        contactRequest.groupMembers.push({ type: element.type, value: element.email });
      } else {
        contactRequest.groupMembers.push({ type: element.type, value: element.id });
      }
    });
    contactRequest.folderId = contactDetails.folderId;
  }

  static prepareModifiedContactGroupRequest(contactDetails: any, contactRequest: ContactRequest) {
    this.getContactField("nickname", contactDetails.groupName, contactRequest);
    this.getContactField("fullName", contactDetails.groupName, contactRequest);
    contactDetails.selectedAddress.forEach((element, index) => {
      if (element.type === "I") {
        contactRequest.groupMembers.push({ op: element.op, type: element.type, value: element.email });
      } else {
        contactRequest.groupMembers.push({ op: element.op, type: element.type, value: element.id });
      }
    });
    contactRequest.folderId = contactDetails.folderId;
  }

  static getDateFormat(date: string) {
    let returnValue = "";
    if (date !== "") {
      const dateString = date.replace(/'/g, "");
      const dateItem = new Date(dateString);
      returnValue = moment(dateItem).format("YYYY-MM-DD");
    }
    return returnValue;
  }

  static removeAttributeFromKey(key: string, requestArray: any): void {
    let imageAttribute = requestArray.find(attr => attr.key === key);
    if (requestArray.indexOf(imageAttribute) !== -1) {
      requestArray.splice(requestArray.indexOf(imageAttribute), 1);
    }
  }

  static getVCFData(contact: Contact[]): string {
    // let fs = window["require"]("fs");
    let str: string = "";
    contact.forEach(contactItem => {
      let vCard: any = require("vcards-js");
      vCard = vCard();
      if (contactItem.firstName) {
        vCard.firstName = contactItem.firstName;
      }
      if (contactItem.lastName) {
        vCard.lastName = contactItem.lastName;
      }
      if (contactItem.nickname) {
        vCard.nickname = contactItem.nickname;
      }
      if (contactItem.company) {
        vCard.organization = contactItem.company;
      }
      if (contactItem.jobTitle) {
        vCard.role = contactItem.company;
      }
      if (contactItem.address.length > 0) {
        contactItem.address.forEach(address => {
          if (address.type === "home") {
            vCard.homeAddress.label = "Home Address";
            vCard.homeAddress.street = address.street;
            vCard.homeAddress.city = address.city;
            vCard.homeAddress.stateProvince = address.state;
            vCard.homeAddress.postalCode = address.postalCode;
            vCard.homeAddress.countryRegion = address.country;
          }
          if (address.type === "work") {
            vCard.workAddress.label = "Work Address";
            vCard.workAddress.street = address.street;
            vCard.workAddress.city = address.city;
            vCard.workAddress.stateProvince = address.state;
            vCard.workAddress.postalCode = address.postalCode;
            vCard.workAddress.countryRegion = address.country;
          }
        });
      }
      if (contactItem.email.length > 0) {
        vCard.email = contactItem.email;
      }
      let mobilePhone = [];
      let homeFax = [];
      let workFax = [];
      let homePhone = [];
      let workPhone = [];
      let otherPhone = [];
      if (contactItem.phone.length > 0) {
        contactItem.phone.forEach(phone => {
          if (phone.type === "companyPhone" || phone.type === "workPhone") {
            workPhone.push(phone.value);
          }
          if (phone.type === "homeFax") {
            homeFax.push(phone.value);
          }
          if (phone.type === "homePhone") {
            homePhone.push(phone.value);
          }
          if (phone.type === "workFax") {
            workFax.push(phone.value);
          }
          if (phone.type === "mobilePhone") {
            mobilePhone.push(phone.value);
          }
          if (phone.type === "otherPhone") {
            otherPhone.push(phone.value);
          }
        });
      }
      if (mobilePhone.length > 0) {
        vCard.cellPhone = mobilePhone;
      }
      if (homeFax.length > 0) {
        vCard.homeFax = homeFax;
      }
      if (workFax.length > 0) {
        vCard.workFax = workFax;
      }
      if (homePhone.length > 0) {
        vCard.homePhone = homePhone;
      }
      if (workPhone.length > 0) {
        vCard.workPhone = workPhone;
      }
      if (otherPhone.length > 0) {
        vCard.otherPhone = otherPhone;
      }

      if (contactItem.userURL.length > 0) {
        contactItem.userURL.forEach(url => {
          if (url.type === "homeURL") {
            vCard.url = url.value;
          }
          if (url.type === "workURL") {
            vCard.workUrl = url.value;
          }
        });
      }

      if (contactItem.other.length > 0) {
        contactItem.other.forEach(other => {
          if (other.type === "birthday") {
            vCard.birthday = new Date(other.value);
          }
          if (other.type === "anniversary") {
            vCard.anniversary = new Date(other.value);
          }
        });
      }
      if (contactItem.notes) {
        vCard.note = contactItem.notes;
      }
      str += vCard.getFormattedString();
    });
    return str;
  }

  static parseUserProfile(contact) {
    let contactUser = JSON.parse(contact);
    let user1: UserProfile = {};
    user1.firstName = contactUser.firstName;
    user1.lastName = contactUser.lastName;
    user1.email = contactUser.email;
    user1.avtarUrl = contactUser.avtarUrl;
    user1.fullName = contactUser.fullName;
    user1.firstLastCharacters = contactUser.firstLastCharacters;
    user1.contactId = contactUser.contactId;
    user1.imageData = contactUser.imageData;
    user1.app = "contacts";
    return user1;
  }

  static plainTextToHTML(str: string): string {
    if (str !== undefined && str !== null) {
        return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
    } else {
        return "";
    }
  }
}
