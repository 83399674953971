
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="calendar-move">
    <div class="mail__dialog-header desktop-header" layout="row center-center">
        <div class="header-title ">
            <span *ngIf="!isEventMove" class="disable-select">{{'CALENDARS.FIND_FOLDER_LBL' | translate }}</span>
            <span *ngIf="isEventMove" class="disable-select">{{ 'CALENDARS.MOVE_APPOINEMENT_LBL' | translate }}</span>
        </div>
    </div>
    <div class="mobile_display_header">
        <div class="disable-select">
            <span *ngIf="!isEventMove" class="disable-select">{{'CALENDARS.FIND_FOLDER_LBL' | translate }}</span>
            <span *ngIf="isEventMove" class="disable-select">{{ 'CALENDARS.MOVE_APPOINEMENT_LBL' | translate }}</span>
        </div>
    </div>
    <div class="mail__dialog-body">
        <div class="content">
            {{ 'CALENDARS.CALENDAR_MOVE_DIALOG_INFO_CONTENT' | translate }}
        </div>
        <div class="search-div">
            <mat-form-field class="search-form-field">
                <input type="text" matInput placeholder="{{ 'CALENDARS.FIND_CALENDAR_LBL' | translate }}" (input)="filterChanged($event.target.value)">
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
        </div>
        <div class="folder-tree">

            <cdk-virtual-scroll-viewport itemSize="10" class="folders-viewport">
                <ng-container *cdkVirtualFor="let item of calendarFolders"></ng-container>
                <mat-tree [dataSource]="calendarDataSource" [treeControl]="calendarTreeControl"
                    class="sidebar-tree-subfolders">
                    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding
                        style="white-space:no-wrap;" class="sub_folder_tree_node" [ngClass]="{'selected-folder': selectedFolder?.id === node.id}">
                        <li *ngIf="node" class="sub_folder">
                            <div class="mat-tree-node">
                                <button mat-icon-button disabled></button>
                                <div class="folder-information">
                                    <!-- <div (click)="routeToFolder(node)" class="folder-div"> -->
                                    <div class="folder-div disable-select" (click)="folderSelect(node)">
                                        <span *ngIf="!node.perm">
                                            <mat-icon *ngIf="node.id!=='3'" class="mdi-16px disable-select"
                                                fontSet="mdi" fontIcon="mdi-calendar-blank"
                                                [style.color]="node.folderColor">
                                            </mat-icon>
                                            <mat-icon *ngIf="node.id==='3'" class="mdi-16px disable-select"
                                                fontSet="mdi" fontIcon="mdi-delete"></mat-icon>
                                        </span>
                                        <span *ngIf="node.perm">
                                            <mat-icon class="mdi-16px disable-select" fontSet="mdi"
                                                fontIcon="mdi-account-multiple" [style.color]="node.folderColor">
                                            </mat-icon>
                                        </span>
                                        <!-- <span (click)="routeToFolder(node)" class="sidebar-title" title="{{getFolderNameKey(node.name)}}">
                                         -->
                                        <span class="sidebar-title disable-select"
                                            title="{{getFolderNameKey(node.name)}}">
                                            {{getFolderNameKey(node.name)}}</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </mat-tree-node>
                    <mat-tree-node *matTreeNodeDef="let node; when: hasNestedChild" matTreeNodePadding
                        style="white-space:no-wrap;" class="sub_folder_tree_node" [ngClass]="{'selected-folder': selectedFolder?.id === node.id}">
                        <li *ngIf="node" class="sub_folder">
                            <div class="mat-tree-node">
                                <button *ngIf="calendarTreeControl.isExpanded(node)" mat-icon-button matTreeNodeToggle
                                    [attr.aria-label]="'toggle ' + node.name">
                                    <mat-icon class="mat-icon-rtl-mirror">expand_more</mat-icon>
                                </button>

                                <button *ngIf="!calendarTreeControl.isExpanded(node)" mat-icon-button matTreeNodeToggle
                                    [attr.aria-label]="'toggle ' + node.name">
                                    <mat-icon class="mat-icon-rtl-mirror">chevron_right</mat-icon>
                                </button>
                                <div class="folder-information">
                                    <!-- <div (click)="routeToFolder(node)" class="folder-div"> -->
                                    <div class="folder-div disable-select" (click)="folderSelect(node)">
                                        <mat-icon class="mdi-16px disable-select" fontSet="mdi"
                                            fontIcon="mdi-calendar-blank" [style.color]="node.folderColor"
                                            *ngIf="!node.perm"></mat-icon>
                                        <mat-icon class="mdi-16px disable-select" fontSet="mdi"
                                            [style.color]="node.folderColor" fontIcon="mdi-account-multiple"
                                            *ngIf="node.perm"></mat-icon>
                                        <!-- <span (click)="routeToFolder(node)" class="sidebar-title" title="{{getFolderNameKey(node.name)}}">
                                         -->
                                        <span class="sidebar-title disable-select"
                                            title="{{getFolderNameKey(node.name)}}">
                                            {{getFolderNameKey(node.name)}}</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </mat-tree-node>
                </mat-tree>
            </cdk-virtual-scroll-viewport>
        </div>
    </div>
    <div class="footer-button">
        <div class="new-folder">
                <button mat-button (click)="createFolder()">{{ 'NEW' | translate }}</button>
        </div>
        <div class="operation">
                <button mat-button (click)="close()">{{ 'COMMON.CANCEL' | translate }}</button>
                <button mat-button (click)="moveAction()">{{ 'COMMON.MOVE' | translate }}</button>
        </div>
    </div>
</div>