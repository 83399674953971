
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, ChangeDetectorRef, Inject, OnDestroy, NgZone } from "@angular/core";
import { PreferenceConstants } from "src/app/common/utils/preference-constants";
import * as _ from "lodash";
import * as moment from "moment";
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from "@angular/forms";
import { takeUntil, take, distinctUntilChanged, debounceTime } from "rxjs/operators";
import { MailFolder } from "src/app/mail/models/mail-folder.model";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { Filter, FilterActions, FilterTests } from "../../../preference/shared/models";
import { PreferenceService } from "../../../preference/shared/services/preference.service";
import { MailFolderComponent } from "../mail-folder-dialog/mail-folder-dialog.component";
import { MailUtils } from "src/app/mail/utils/mail-utils";
import { MailBroadcaster } from "../../../common/providers/mail-broadcaster.service";
import { BroadcastKeys } from "../../../common/enums/broadcast.enum";
import { NgxHotkeysService } from "ngx-hotkeys-vnc";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { BreakpointObserver, BreakpointState, Breakpoints } from "@angular/cdk/layout";
import { ToastService } from "src/app/common/providers/toast.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CommonRepository } from "src/app/mail/repositories/common-repository";
import { VncLibraryService } from "vnc-library";

export interface CreateFilterData {
  filters: { incomingFilters: Filter[], outgoingFilters: Filter[] };
  type: "incoming" | "outgoing";
  subject?: string;
  action?: string;
  filter?: Filter;
  mail?: string;
  editFromMailDetail?: boolean;
  editMailDetailEmail?: string;
}

@Component({
  selector: "vp-create-filter",
  templateUrl: "./create-filter.component.html"
})
export class CreateFilterComponent implements OnInit, OnDestroy {
  form: FormGroup;
  name = new FormControl("", Validators.required);
  condition = new FormControl("any", Validators.required);
  filter: any;
  invalid: boolean = true;
  invalidAction: boolean = false;
  isExists: boolean;
  allFilters: Filter[];
  folders: MailFolder[] = [];
  filterConstants = PreferenceConstants;
  rule = {
    value: ""
  };
  errorText: any;
  subject: string = "";
  mail: string = "";
  globalOperationIcon = "check";
  action = "new";
  editedIndex: number;
  private isAlive$ = new Subject<boolean>();
  dataIsReady: boolean;
  isMobileView = false;
  doNotProcessAdditionFilter: boolean = true;
  constructor(
    private vncLibraryService: VncLibraryService,
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private dialog: MatDialog,
    private preferenceService: PreferenceService,
    private dialogRef: MatDialogRef<CreateFilterComponent>,
    private ngZone: NgZone,
    private mailBroadcaster: MailBroadcaster,
    private breakpointObserver: BreakpointObserver,
    public toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: CreateFilterData,
    private commonRepository: CommonRepository,
    private hotKeyService: NgxHotkeysService
    ) {
      this.hotKeyService.pause(this.hotKeyService.hotkeys);
      if (!!this.data.filters) {
        this.initData(this.data.filters);
      } else {
        this.preferenceService.getFilters("all").subscribe(filters => {
          console.log("[getFilters]", filters);
          this.initData(filters);
        });
      }

      this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_FILTER_CREATE_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
        this.ngZone.run(() => {
          this.cancel();
        });
    });
    this.isMobileView = this.breakpointObserver.isMatched("(max-width: 1023px)");
    this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.HandsetPortrait])
      .pipe(takeUntil(this.isAlive$))
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobileView = true;
        } else {
          this.isMobileView = false;
        }
        this.changeDetectorRef.markForCheck();
      });
  }

  private initData(filters): void {
    console.log("Initial Filter data", filters)

    if (this.data.type === "incoming") {
      this.allFilters = filters.incomingFilters;
    } else {
      this.allFilters = filters.outgoingFilters;
    }
    const sub = this.data.subject ? this.data.subject : "";
    if (this.data.mail) {
      this.form = this.fb.group({
        name: this.name,
        condition: this.condition,
        filterTests: this.fb.array([
          this.initFilterTestsWithMail(this.data.mail),
          this.initFilterTests(sub),
        ]),
        filterActions: this.fb.array([
          this.initFilterActions(),
        ])
      });
    } else if (this.data.filter) {
      this.action = "edit";
      const filter = this.data.filter;
      this.editedIndex = _.findIndex(this.allFilters, {name: filter.name});
      console.log("[CreateFilterComponent] edit", filter);
      this.form = this.fb.group({
        name: filter.name,
        condition: filter.filterTests[0].condition ? filter.filterTests[0].condition.replace("of", "") : "",
        filterTests: this.fb.array([]),
        filterActions: this.fb.array([])
      });
      this.initFilterTestsWithData(filter);
      this.initFilterActionsWithData(filter);
    } else {
      this.form = this.fb.group({
        name: this.name,
        condition: this.condition,
        filterTests: this.fb.array([
          this.initFilterTests(sub),
        ]),
        filterActions: this.fb.array([
          this.initFilterActions(),
        ])
      });
    }

    if (this.data.editMailDetailEmail) {
      this.form = this.fb.group({
        name: this.name,
        condition: this.condition,
        filterTests: this.fb.array([
          this.initFilterTestsWithMail(this.data.editMailDetailEmail)
        ]),
        filterActions: this.fb.array([
          this.initFilterActions(),
        ])
      });
    }
    if (this.data.editFromMailDetail) {
        this.invalid = false;
        this.invalidAction = false;
        this.changeDetectorRef.markForCheck();
    }
    this.form.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.isAlive$)).subscribe(changes => {
      this.form.markAllAsTouched();
      setTimeout(() => {
        const status = this.form.status === "INVALID";
        this.invalid = status;
        this.invalidAction = status;
        this.changeDetectorRef.markForCheck();
      });
    });

    this.form.get("name").valueChanges.pipe(distinctUntilChanged(), debounceTime(200), takeUntil(this.isAlive$)).subscribe(
      name => {
        this.validateName(name);
      }
    );

    this.dataIsReady = true;
    this.changeDetectorRef.markForCheck();
  }

  private initAddressTest(filter): void {
    let tests: any[] = [];
    const control = <FormArray>this.form.controls["filterTests"];
    if (filter.filterTests[0].addressTest) {
      tests = Array.isArray(filter.filterTests[0].addressTest) ? filter.filterTests[0].addressTest : [filter.filterTests[0].addressTest];
      tests.forEach(addressTest => {
        let stringComparison: string = addressTest.stringComparison.toLowerCase();
        if ( addressTest.negative && addressTest.negative === true) {
          if (stringComparison.indexOf("contains") !== -1) {
            stringComparison = "does not contain";
          } else if (stringComparison.indexOf("is") !== -1) {
            stringComparison = "does not match exactly";
          } else if (stringComparison.indexOf("matches") !== -1) {
            stringComparison = "does not match wildcard condition";
          }
        }
        if (!addressTest.negative) {
          if (stringComparison.indexOf("is") !== -1) {
            stringComparison = "matches exactly";
          } else if (stringComparison.indexOf("matches") !== -1) {
            stringComparison = "matches wildcard condition";
          }
        }
        let header = addressTest.header;
        if (header === "to,cc") {
          header = "To or Cc";
        }
        control.push(this.fb.group({
          rule: [MailUtils.capitalizeFirstLetter(header)],
          rule2: [stringComparison],
          rule3: [addressTest.value],
          rule4: [addressTest.part]
        }));
      });
    }
  }

  private initHeaderTest(filter: Filter): void {
    let tests: any[] = [];
    const control = <FormArray>this.form.controls["filterTests"];
    if (filter.filterTests[0].headerTest) {
      tests = Array.isArray(filter.filterTests[0].headerTest) ? filter.filterTests[0].headerTest : [filter.filterTests[0].headerTest];
      tests.forEach(headerTest => {
        let stringComparison = headerTest.stringComparison.toLowerCase();
        if (headerTest.negative && headerTest.negative === true) {
          if (stringComparison.indexOf("contains") !== -1) {
            stringComparison = "does not contain";
          } else if (stringComparison.indexOf("is") !== -1) {
            stringComparison = "does not match exactly";
          } else if (stringComparison.indexOf("matches") !== -1) {
            stringComparison = "does not match wildcard condition";
          }
        }
        if (!headerTest.negative) {
          if (stringComparison.indexOf("is") !== -1) {
            stringComparison = "matches exactly";
          } else if (stringComparison.indexOf("matches") !== -1) {
            stringComparison = "matches wildcard condition";
          }
        }
        const header = headerTest.header;
        if (header !== "subject") {
          control.push(this.fb.group({
            rule: ["Header Named"],
            rule2: [header],
            rule3: [stringComparison],
            rule4: [headerTest.value]
          }));
        } else {
          control.push(this.fb.group({
            rule: ["Subject"],
            rule2: [stringComparison],
            rule3: [headerTest.value],
            rule4: [""]
          }));
        }
      });
    }

    if (filter.filterTests[0].headerExistsTest) {
      tests = Array.isArray(filter.filterTests[0].headerExistsTest) ? filter.filterTests[0].headerExistsTest
        : [filter.filterTests[0].headerExistsTest];
      tests.forEach(headerTest => {
        if (filter.filterTests[0].headerExistsTest) {
          tests = Array.isArray(filter.filterTests[0].headerExistsTest) ?
          filter.filterTests[0].headerExistsTest :
          [filter.filterTests[0].headerExistsTest];
          tests.forEach(headerExistsTest => {
            let stringComparison = "exists";
            if (headerExistsTest.negative === true) {
              stringComparison = "does not exist";
            }
            const header = headerExistsTest.header;
            if (header !== "subject") {
              control.push(this.fb.group({
                rule: ["Header Named"],
                rule2: [header],
                rule3: [stringComparison],
                rule4: [""]
              }));
            } else {
              control.push(this.fb.group({
                rule: ["Header Named"],
                rule2: ["subject"],
                rule3: [stringComparison],
                rule4: [""]
              }));
            }
          });
        }
      });
    }
  }

  private initConversationTest(filter: Filter): void {
    let tests: any[] = [];
    const control = <FormArray>this.form.controls["filterTests"];
    if (filter.filterTests[0].conversationTest) {
      tests = Array.isArray(filter.filterTests[0].conversationTest) ? filter.filterTests[0].conversationTest
      : [filter.filterTests[0].conversationTest];
      tests.forEach(conversationTest => {
        console.log("[initFilterTestsWithData] conversationTest", conversationTest);
        let stringComparison = "is";
        if (conversationTest.negative === true) {
          stringComparison = "is not";
        }
        let where = conversationTest.where;
        if (where === "started") {
          where = "in conversations I started";
        } else if (where === "participated") {
          where = "in conversations I participated";
        }
        control.push(this.fb.group({
          rule: ["Message"],
          rule2: [stringComparison],
          rule3: [where],
          rule4: [""]
        }));
      });
    }
  }

  private initBulkTest(filter: Filter): void {
    let tests: any[] = [];
    const control = <FormArray>this.form.controls["filterTests"];
    if (filter.filterTests[0].bulkTest) {
      tests = Array.isArray(filter.filterTests[0].bulkTest) ? filter.filterTests[0].bulkTest
      : [filter.filterTests[0].bulkTest];
      tests.forEach(bulkTest => {
        let stringComparison = "is";
        if (bulkTest.negative === true) {
          stringComparison = "is not";
        }
        control.push(this.fb.group({
          rule: ["Message"],
          rule2: [stringComparison],
          rule3: ["mass marketing (bulk)"],
          rule4: [""]
        }));
      });
    }
  }

  private initFlaggedTest(filter: Filter): void {
    let tests: any[] = [];
    const control = <FormArray>this.form.controls["filterTests"];
    if (filter.filterTests[0].flaggedTest) {
      tests = Array.isArray(filter.filterTests[0].flaggedTest) ? filter.filterTests[0].flaggedTest
      : [filter.filterTests[0].flaggedTest];
      tests.forEach(flaggedTest => {
        let stringComparison = "is";
        if (flaggedTest.negative === true) {
          stringComparison = "is not";
        }
        if (flaggedTest.flagName === "flagged") {
          control.push(this.fb.group({
            rule: ["Message"],
            rule2: [stringComparison],
            rule3: ["flagged"],
            rule4: [""]
          }));
        } else {
          control.push(this.fb.group({
            rule: ["Message"],
            rule2: [stringComparison],
            rule3: ["marked as"],
            rule4: [flaggedTest.flagName]
          }));
        }
      });
    }
  }

  private initListTest(filter: Filter): void {
    let tests: any[] = [];
    const control = <FormArray>this.form.controls["filterTests"];
    if (filter.filterTests[0].listTest) {
      tests = Array.isArray(filter.filterTests[0].listTest) ? filter.filterTests[0].listTest
      : [filter.filterTests[0].listTest];
      tests.forEach(listTest => {
        let stringComparison = "is";
        if (listTest.negative === true) {
          stringComparison = "is not";
        }
        control.push(this.fb.group({
          rule: ["Message"],
          rule2: [stringComparison],
          rule3: ["from distribution list"],
          rule4: [""]
        }));
      });
    }
  }

  private initImportanceTest(filter: Filter): void {
    let tests: any[] = [];
    const control = <FormArray>this.form.controls["filterTests"];
    if (filter.filterTests[0].importanceTest) {
      tests = Array.isArray(filter.filterTests[0].importanceTest) ? filter.filterTests[0].importanceTest
      : [filter.filterTests[0].importanceTest];
      tests.forEach(importanceTest => {
        let stringComparison = "is";
        if (importanceTest.negative === true) {
          stringComparison = "is not";
        }
        control.push(this.fb.group({
          rule: ["Message"],
          rule2: [stringComparison],
          rule3: ["marked as"],
          rule4: [importanceTest.imp + " importance"]
        }));
      });
    }
  }

  private initSizeTest(filter: Filter): void {
    let tests: any[] = [];
    const control = <FormArray>this.form.controls["filterTests"];
    if (filter.filterTests[0].sizeTest) {
      tests = Array.isArray(filter.filterTests[0].sizeTest) ? filter.filterTests[0].sizeTest : [filter.filterTests[0].sizeTest];
      tests.forEach(sizeTest => {
        let numberComparison = sizeTest.numberComparison.toLowerCase();
        if (sizeTest.negative === true) {
          numberComparison = "not " + numberComparison;
        }
        let value = sizeTest.s;
        let size = "B";
        if (value.indexOf("K") !== -1) {
          value = value.replace("K", "");
          size = "KB";
        } else if (value.indexOf("M") !== -1) {
          value = value.replace("M", "");
          size = "MB";
        } else if (value.indexOf("G") !== -1) {
          value = value.replace("G", "");
          size = "GB";
        }
        control.push(this.fb.group({
          rule: ["Size"],
          rule2: [numberComparison],
          rule3: [value],
          rule4: [size]
        }));
      });
    }
  }

  private initDateTest(filter: Filter): void {
    let tests: any[] = [];
    const control = <FormArray>this.form.controls["filterTests"];
    if (filter.filterTests[0].dateTest) {
      tests = Array.isArray(filter.filterTests[0].dateTest) ? filter.filterTests[0].dateTest : [filter.filterTests[0].dateTest];
      tests.forEach(dateTest => {
        let dateComparison = dateTest.dateComparison.toLowerCase();
        if (dateTest.negative === true) {
          dateComparison = "not " + dateComparison;
        }
        const date = new Date(+dateTest.d * 1000);
        control.push(this.fb.group({
          rule: ["Date"],
          rule2: [dateComparison],
          rule3: [date],
          rule4: [""]
        }));
      });
    }
  }

  private initBodyTest(filter: Filter): void {
    let tests: any[] = [];
    const control = <FormArray>this.form.controls["filterTests"];
    if (filter.filterTests[0].bodyTest) {
      tests = Array.isArray(filter.filterTests[0].bodyTest) ? filter.filterTests[0].bodyTest : [filter.filterTests[0].bodyTest];
      tests.forEach(bodyTest => {
        let stringComparison = "contains";
        if (bodyTest.negative === true) {
          stringComparison = "does not contain";
        }
        control.push(this.fb.group({
          rule: ["Body"],
          rule2: [stringComparison],
          rule3: [bodyTest.value],
          rule4: [""]
        }));
      });
    }
  }

  private initAttachmentTest(filter: Filter): void {
    let tests: any[] = [];
    const control = <FormArray>this.form.controls["filterTests"];
    if (filter.filterTests[0].attachmentTest) {
      tests = Array.isArray(filter.filterTests[0].attachmentTest) ?
      filter.filterTests[0].attachmentTest :
      [filter.filterTests[0].attachmentTest];
      tests.forEach(attachmentTest => {
        let stringComparison = "exists";
        if (attachmentTest.negative === true) {
          stringComparison = "does not exist";
        }
        control.push(this.fb.group({
          rule: ["Attachment"],
          rule2: [stringComparison],
          rule3: [""],
          rule4: [""]
        }));
      });
    }
  }

  private initMimeHeaderTest(filter: Filter): void {
    let tests: any[] = [];
    const control = <FormArray>this.form.controls["filterTests"];
    if (filter.filterTests[0].mimeHeaderTest) {
      tests = Array.isArray(filter.filterTests[0].mimeHeaderTest) ?
      filter.filterTests[0].mimeHeaderTest :
      [filter.filterTests[0].mimeHeaderTest];
      tests.forEach(mimeHeaderTest => {
        let stringComparison = "exists";
        if (mimeHeaderTest.negative === true) {
          stringComparison = "does not exist";
        }
        control.push(this.fb.group({
          rule: ["Read Receipt"],
          rule2: [stringComparison],
          rule3: [""],
          rule4: [""]
        }));
      });
    }
  }

  private initAddressBookTest(filter: Filter): void {
    let tests: any[] = [];
    const control = <FormArray>this.form.controls["filterTests"];
    if (filter.filterTests[0].addressBookTest) {
      tests = Array.isArray(filter.filterTests[0].addressBookTest) ?
      filter.filterTests[0].addressBookTest :
      [filter.filterTests[0].addressBookTest];
      tests.forEach(addressBookTest => {
        let header = addressBookTest.header.toLowerCase();
        if (header === "to,cc") {
          header = "To or Cc";
        }
        header = MailUtils.capitalizeFirstLetter(header);
        const rule3 = addressBookTest.negative !== true ? "in" : "not in";
        const rule4 = "Contacts";
        control.push(this.fb.group({
          rule: ["Address in"],
          rule2: [header],
          rule3: [rule3],
          rule4: [rule4]
        }));
      });
    }
  }

  private initContactRankingTest(filter: Filter): void {
    let tests: any[] = [];
    const control = <FormArray>this.form.controls["filterTests"];
    if (filter.filterTests[0].contactRankingTest) {
      tests = Array.isArray(filter.filterTests[0].contactRankingTest) ? filter.filterTests[0].contactRankingTest :
      [filter.filterTests[0].contactRankingTest];
      tests.forEach(contactRankingTest => {
        let header = contactRankingTest.header.toLowerCase();
        if (header === "to,cc") {
          header = "To or Cc";
        }
        header = MailUtils.capitalizeFirstLetter(header);
        const rule3 = contactRankingTest.negative !== true ? "in" : "not in";
        const rule4 = "My Frequent Emails";
        control.push(this.fb.group({
          rule: ["Address in"],
          rule2: [header],
          rule3: [rule3],
          rule4: [rule4]
        }));
      });
    }
  }

  private initMeTest(filter: Filter): void {
    let tests: any[] = [];
    const control = <FormArray>this.form.controls["filterTests"];
    if (filter.filterTests[0].meTest) {
      tests = Array.isArray(filter.filterTests[0].meTest) ? filter.filterTests[0].meTest :
      [filter.filterTests[0].meTest];
      tests.forEach(meTest => {
        let header = meTest.header.toLowerCase();
        if (header === "to,cc") {
          header = "To or Cc";
        }
        header = MailUtils.capitalizeFirstLetter(header);
        const rule3 = meTest.negative !== true ? "is me" : "is not me";
        control.push(this.fb.group({
          rule: ["Address in"],
          rule2: [header],
          rule3: [rule3],
          rule4: [""]
        }));
      });
    }
  }

  private initInviteTest(filter: Filter): void {
    let tests: any[] = [];
    const control = <FormArray>this.form.controls["filterTests"];
    if (filter.filterTests[0].inviteTest) {
      tests = Array.isArray(filter.filterTests[0].inviteTest) ? filter.filterTests[0].inviteTest :
      [filter.filterTests[0].inviteTest];
      tests.forEach(inviteTest => {
        let method = inviteTest.method;
        if (method === "anyreply") {
          method = "invite is replied";
          if (inviteTest.negative === true) {
            method = "invite is not replied";
          }
        } else if (method === "anyrequest") {
          method = "invite is requested";
          if (inviteTest.negative === true) {
            method = "invite is not requested";
          }
        }
        control.push(this.fb.group({
          rule: ["Calendar"],
          rule2: [method],
          rule3: [""],
          rule4: [""]
        }));
      });
    }
  }

  private initSocialTest(filter: Filter): void {
    let tests: any[] = [];
    const control = <FormArray>this.form.controls["filterTests"];
    // facebookTest
    if (filter.filterTests[0].facebookTest) {
      tests = Array.isArray(filter.filterTests[0].facebookTest) ? filter.filterTests[0].facebookTest :
      [filter.filterTests[0].facebookTest];
      tests.forEach(facebookTest => {
        control.push(this.fb.group({
          rule: ["Social"],
          rule2: ["Facebook notifications"],
          rule3: [""],
          rule4: [""]
        }));
      });
    }
    // linkedinTest
    if (filter.filterTests[0].linkedinTest) {
      tests = Array.isArray(filter.filterTests[0].linkedinTest) ? filter.filterTests[0].linkedinTest :
      [filter.filterTests[0].linkedinTest];
      tests.forEach(linkedinTest => {
        control.push(this.fb.group({
          rule: ["Social"],
          rule2: ["LinkedIn messages and connections"],
          rule3: [""],
          rule4: [""]
        }));
      });
    }
    // twitterTest
    if (filter.filterTests[0].twitterTest) {
      tests = Array.isArray(filter.filterTests[0].twitterTest) ? filter.filterTests[0].twitterTest :
      [filter.filterTests[0].twitterTest];
      tests.forEach(twitterTest => {
        control.push(this.fb.group({
          rule: ["Social"],
          rule2: ["Twitter notifications"],
          rule3: [""],
          rule4: [""]
        }));
      });
    }
  }

  private initFilterActionsWithData(filter: Filter) {
    const control = <FormArray>this.form.controls["filterActions"];
    let actions = [];
    if (filter.filterActions[0].actionKeep) {
      actions = Array.isArray(filter.filterActions[0].actionKeep) ?
      filter.filterActions[0].actionKeep :
      [filter.filterActions[0].actionKeep];
      actions.forEach(action => {
        control.push(this.fb.group({
          action: ["Keep in Inbox"],
          value: [action.tagName],
          name: [""]
        }));
      });
    }
    // actionDiscard
    if (filter.filterActions[0].actionDiscard) {
      actions = Array.isArray(filter.filterActions[0].actionDiscard) ? filter.filterActions[0].actionDiscard
       : [filter.filterActions[0].actionDiscard];
      actions.forEach(action => {
        control.push(this.fb.group({
          action: ["Delete"],
          value: [action.tagName],
          name: [""]
        }));
      });
    }
    // actionFileInto
    if (filter.filterActions[0].actionFileInto) {
      actions = Array.isArray(filter.filterActions[0].actionFileInto) ?
      filter.filterActions[0].actionFileInto : [filter.filterActions[0].actionFileInto];
      actions.forEach(action => {
        control.push(this.fb.group({
          action: ["Move into folder"],
          value: [action.folderPath],
          name: [action.folderPath]
        }));
      });
    }
    // actionTag
    // if (filter.filterActions.actionTag) {
    //   actions = Array.isArray(filter.filterActions.actionTag) ? filter.filterActions.actionTag : [filter.filterActions.actionTag];
    //   actions.forEach(action => {
    //     control.push(this.fb.group({
    //       action: ["Tag with"],
    //       value: [action.tagName]
    //     }));
    //   });
    // }
    // actionFlag
    if (filter.filterActions[0].actionFlag) {
      actions = Array.isArray(filter.filterActions[0].actionFlag) ?
      filter.filterActions[0].actionFlag :
      [filter.filterActions[0].actionFlag];
      actions.forEach(action => {
        control.push(this.fb.group({
          action: ["Mark as"],
          value: [action.flagName],
          name: [""]
        }));
      });
    }

    // actionRedirect
    if (filter.filterActions[0].actionRedirect) {
      actions = Array.isArray(filter.filterActions[0].actionRedirect) ? filter.filterActions[0].actionRedirect
       : [filter.filterActions[0].actionRedirect];
      actions.forEach(action => {
        control.push(this.fb.group({
          action: ["Redirect to Address"],
          value: [action.a],
          name: [""]
        }));
      });
    }
    if (filter.filterActions[0].actionStop) {
      this.doNotProcessAdditionFilter = true;
    } else {
      this.doNotProcessAdditionFilter = false;
    }
    this.changeDetectorRef.markForCheck();
  }

  private initFilterTestsWithData(filter: Filter) {
    // addressTest
    this.initAddressTest(filter);
    // headerTest
    this.initHeaderTest(filter);
    // conversationTest
    this.initConversationTest(filter);
    // bulkTest
    this.initBulkTest(filter);
    // listTest
    this.initListTest(filter);
    // flaggedTest
    this.initFlaggedTest(filter);
    // importanceTest
    this.initImportanceTest(filter);
    // sizeTest
    this.initSizeTest(filter);
    // dateTest
    this.initDateTest(filter);
    // bodyTest
    this.initBodyTest(filter);
    // attachmentTest
    this.initAttachmentTest(filter);
    // mimeHeaderTest
    this.initMimeHeaderTest(filter);
    // addressBookTest
    this.initAddressBookTest(filter);
    // contactRankingTest
    this.initContactRankingTest(filter);
    // meTest
    this.initMeTest(filter);
    // inviteTest
    this.initInviteTest(filter);
    // init social test
    this.initSocialTest(filter);
  }

  private initFilterTests(subject): FormGroup {
    return this.fb.group({
      rule: ["Subject"],
      rule2: ["contains"],
      rule3: [subject],
      rule4: [""]
    });
  }

  private initFilterTestsWithMail(mail): FormGroup {
    return this.fb.group({
      rule: ["From"],
      rule2: ["contains"],
      rule3: [mail],
      rule4: ["all"]
    });
  }

  private initFilterActions(): FormGroup {
    return this.fb.group({
      action: ["Keep in Inbox"],
      name: [""],
      value: [""]
    });
  }

  openMailFolders(filterAction): void {
    console.log("[openMailFolders]", filterAction);
    const args = {
      autoFocus: false,
      maxWidth: "100%",
      height: "400px",
      panelClass: "mail__dialog",
    };
    this.dialog.open(MailFolderComponent, args).afterClosed().subscribe(data => {
      console.log("[openMailFolders] afterClosed", data);
      if (!!data) {
        if (data.icon) {
          this.translate.get(MailConstants.SYSTEM_FOLDERS)
          .pipe(take(1)).subscribe(result => {
            const name = result[`${data.name.toUpperCase()}_FOLDER`] || data.name;
            filterAction.controls.name.setValue(name);
          });
        } else {
          filterAction.controls.name.setValue(data.absFolderPath);
        }
        filterAction.controls.value.setValue(data.absFolderPath);
      }
    });
    this.changeDetectorRef.markForCheck();
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.hotKeyService.unpause(this.hotKeyService.hotkeys);
    this.changeDetectorRef.detach();
    this.isAlive$.next(false);
    this.isAlive$.complete();
    this.mailBroadcaster.broadcast("UPDATE_INCOMING_FILTER_LIST");
  }

  addFilterTests(): void {
    const control = <FormArray>this.form.controls["filterTests"];
    control.push(this.initFilterTests(""));
    setTimeout(() => {
      this.invalid = !this.form.valid;
      this.changeDetectorRef.markForCheck();
    }, 200);
  }

  addFilterActions(): void {
    const control = <FormArray>this.form.controls["filterActions"];
    control.push(this.initFilterActions());
    setTimeout(() => {
      this.invalid = !this.form.valid;
      this.changeDetectorRef.markForCheck();
    }, 200);
  }

  removeFilterTests(i: number) {
    const control = <FormArray>this.form.controls["filterTests"];
    control.removeAt(i);
  }

  removeFilterActions(i: number) {
    const control = <FormArray>this.form.controls["filterActions"];
    control.removeAt(i);
  }

  setDefaultValue(filterTest: FormGroup): void {
    const rule = filterTest.value.rule;
    const filterItem = ["Attachment", "Calendar", "Social", "Read Receipt"];
    const filterItemCheck = filterItem.includes(rule);
    if (PreferenceConstants.MENU2[rule] && Array.isArray(PreferenceConstants.MENU2[rule])) {
      filterTest.get("rule2").setValue(PreferenceConstants.MENU2[rule][0]);
    } else {
      filterTest.get("rule2").setValue("");
    }
    if (PreferenceConstants.MENU3[rule] && Array.isArray(PreferenceConstants.MENU3[rule])) {
      const getFilter = filterTest.get("rule3");
      if (!getFilter) filterTest.addControl("rule3", new FormControl("filterTests"));
      filterTest.get("rule3").setValue(PreferenceConstants.MENU3[rule][0]);
    } else {
      if (["from", "to", "cc", "to or cc"].includes(rule.toLowerCase())) {
        const getFilter = filterTest.get("rule3");
        if (!getFilter) filterTest.addControl("rule3", new FormControl("filterTests"));
        filterTest.get("rule3").setValue("");
        this.invalid = true;
      } else {
        if (filterItemCheck) filterTest.removeControl("rule3");
        else {
          const getFilter = filterTest.get("rule3");
          if (!getFilter) filterTest.addControl("rule3", new FormControl("filterTests"));
          filterTest.get("rule3").setValue("");
        }
      }
    }
    if (PreferenceConstants.MENU4[rule] && Array.isArray(PreferenceConstants.MENU4[rule])) {
      const getFilter = filterTest.get("rule4");
      if (!getFilter) filterTest.addControl("rule4", new FormControl("filterTests"));
      filterTest.get("rule4").setValue(PreferenceConstants.MENU4[rule][0]);
    } else {
      if (filterItemCheck) filterTest.removeControl('rule4');
      else {
        const getFilter = filterTest.get("rule4");
        if (!getFilter) filterTest.addControl("rule4", new FormControl("filterTests"));
        filterTest.get("rule4").setValue(" ");
      }
    }
  }

  processFilterActions() {
    const result: any = {};
    let index = 0;
    const data = this.form.value;
    for (const filterAction of data.filterActions) {
      const action = filterAction.action.toLowerCase();
      switch (action) {
        case "keep in inbox":
          if (!result.actionKeep) {
            result.actionKeep = [];
          }
          result.actionKeep.push({
            index: index
          });
          break;
        case "delete":
          if (!result.actionDiscard) {
            result.actionDiscard = [];
          }
          result.actionDiscard.push({
            index: index
          });
          break;
        case "move into folder":
          if (!result.actionFileInto) {
            result.actionFileInto = [];
          }
          result.actionFileInto.push({
            folderPath: filterAction.value,
            index: index
          });
          break;
        case "tag with":
          if (!result.actionTag) {
            result.actionTag = [];
          }
          result.actionTag.push({
            tagName: filterAction.value,
            index: index
          });
          break;
        case "mark as":
          if (!result.actionFlag) {
            result.actionFlag = [];
          }
          result.actionFlag.push({
            flagName: filterAction.value,
            index: index
          });
          break;
        case "redirect to address":
          if (!result.actionRedirect) {
            result.actionRedirect = [];
          }
          result.actionRedirect.push({
            a: filterAction.value,
            index: index
          });
          break;
        default:

      }
      index++;
    }
    console.log("[doNotProcessAdditionFilter]: ", this.doNotProcessAdditionFilter);
    if (this.doNotProcessAdditionFilter) {
      result.actionStop = [];
      result.actionStop.push({ index: index });
    }
    return result;
  }

  processFilterTests() {
    const result: any = {
      condition: this.form.value.condition + "of"
    };
    for (const filterTest of this.form.value.filterTests) {
      let header = filterTest.rule.toLowerCase();
      if (["subject", "header"].includes(header)) {
        if (!result.headerTest) {
          result.headerTest = [];
        }
        let stringComparison = filterTest.rule2.toLowerCase();
        let negative;
        if (stringComparison.indexOf("does not") !== -1) {
          negative = "1";
        }
        if (stringComparison.indexOf("exactly") !== -1) {
          stringComparison = "is";
        } else if (stringComparison.indexOf("contain") !== -1) {
          stringComparison = "contains";
        } else if (stringComparison.indexOf("wildcard") !== -1) {
          stringComparison = "matches";
        }
        result.headerTest.push({
          header: header,
          negative: negative,
          stringComparison: stringComparison,
          value: filterTest.rule3.toLowerCase()
        });
      } else if (["header named"].includes(header)) {

        let stringComparison = filterTest.rule3.toLowerCase();
        let negative;
        if (stringComparison.indexOf("does not") !== -1) {
          negative = "1";
        }
        if (stringComparison.indexOf("exist") !== -1) {
          if (!result.headerExistsTest) {
            result.headerExistsTest = [];
          }
          result.headerExistsTest.push({
            header: filterTest.rule2,
            negative: negative
          });
        } else {
          if (!result.headerTest) {
            result.headerTest = [];
          }
          if (stringComparison.indexOf("exactly") !== -1) {
            stringComparison = "is";
          } else if (stringComparison.indexOf("contain") !== -1) {
            stringComparison = "contains";
          } else if (stringComparison.indexOf("wildcard") !== -1) {
            stringComparison = "matches";
          }

          result.headerTest.push({
            header: filterTest.rule2,
            negative: negative,
            stringComparison: stringComparison,
            value: filterTest.rule4.toLowerCase()
          });
        }
      } else if (["body"].includes(header)) {
        if (!result.bodyTest) {
          result.bodyTest = [];
        }
        let stringComparison = filterTest.rule2.toLowerCase();
        let negative;
        if (stringComparison.indexOf("does not") !== -1) {
          negative = "1";
        }
        if (stringComparison.indexOf("contain") !== -1) {
          stringComparison = "contains";
        }
        result.bodyTest.push({
          negative: negative,
          value: filterTest.rule3.toLowerCase()
        });
      } else if (["read receipt"].includes(header)) {
        if (!result.mimeHeaderTest) {
          result.mimeHeaderTest = [];
        }
        const stringComparison = "contains";
        let negative;
        if (stringComparison.indexOf("does not") !== -1) {
          negative = "1";
        }
        result.mimeHeaderTest.push({
          header: "Content-Type",
          negative: negative,
          stringComparison: stringComparison,
          value: "message/disposition-notification"
        });
      } else if (["from", "to", "cc", "to or cc"].includes(header)) {
        if (header === "to or cc") {
          header = "to,cc";
        }
        if (!result.addressTest) {
          result.addressTest = [];
        }

        if (filterTest.rule3.toLowerCase().indexOf(";") > 0) {
          this.toastService.show("INVALID_VALUE_IN_CC");
          return false;
        }
        let stringComparison = filterTest.rule2.toLowerCase();
        let negative;
        if (stringComparison.indexOf("does not") !== -1) {
          negative = "1";
        }
        if (stringComparison.indexOf("exactly") !== -1) {
          stringComparison = "is";
        } else if (stringComparison.indexOf("contain") !== -1) {
          stringComparison = "contains";
        } else if (stringComparison.indexOf("wildcard") !== -1) {
          stringComparison = "matches";
        }
        result.addressTest.push({
          header: header,
          stringComparison: stringComparison,
          part: filterTest.rule4.toLowerCase(),
          negative: negative,
          value: filterTest.rule3.toLowerCase()
        });
      } else if (["message"].includes(header)) {
        let rule3 = filterTest.rule3.toLowerCase();
        let negative;
        if (filterTest.rule2.toLowerCase() === "is not") {
          negative = "1";
        }
        if (rule3.indexOf("started") !== -1 || rule3.indexOf("participated") !== -1) {
          if (!result.conversationTest) {
            result.conversationTest = [];
          }
          if (rule3.indexOf("started") !== -1) {
            rule3 = "started";
            result.conversationTest.push({
              where: rule3,
              negative: negative
            });
          } else if (rule3.indexOf("participated") !== -1) {
            rule3 = "participated";
            result.conversationTest.push({
              where: rule3,
              negative: negative
            });
          }
        } else if (rule3.indexOf("marked") !== -1 || rule3.indexOf("flagged") !== -1) {
          if (rule3.indexOf("marked") !== -1) {
            if (filterTest.rule4.indexOf("importance")) {
              if (!result.importanceTest) {
                result.importanceTest = [];
              }
              result.importanceTest.push({
                imp: filterTest.rule4.replace(" importance", ""),
                negative: negative
              });
            } else {
              if (!result.flaggedTest) {
                result.flaggedTest = [];
              }
              result.flaggedTest.push({
                flagName: filterTest.rule4,
                negative: negative
              });
            }
          } else {
            if (!result.flaggedTest) {
              result.flaggedTest = [];
            }
            result.flaggedTest.push({
              flagName: "flagged",
              negative: negative
            });
          }
        } else if (rule3.indexOf("bulk") !== -1) {
          if (!result.bulkTest) {
            result.bulkTest = [];
          }
          result.bulkTest.push({
            negative: negative
          });
        } else if (rule3.indexOf("distribution") !== -1) {
          if (!result.listTest) {
            result.listTest = [];
          }
          result.listTest.push({
            negative: negative
          });
        }

      } else if (["size"].includes(header)) {
        if (!result.sizeTest) {
          result.sizeTest = [];
        }
        let size = filterTest.rule4.trim();
        if (["B", "KB", "MB", "GB"].indexOf(size) !== -1) {
          size = size.replace("B", "");
        }
        if (filterTest.rule2.toLowerCase() === "not under") {
          result.sizeTest.push({
            numberComparison: "under",
            s: filterTest.rule3.toLowerCase().trim() + size,
            negative: "1"
          });
        } else if (filterTest.rule2.toLowerCase() === "not over") {
          result.sizeTest.push({
            numberComparison: "over",
            s: filterTest.rule3.toLowerCase().trim() + size,
            negative: "1"
          });
        } else {
          result.sizeTest.push({
            numberComparison: filterTest.rule2.toLowerCase(),
            s: filterTest.rule3.toLowerCase().trim() + size
          });
        }
      } else if (["date"].includes(header)) {
        if (!result.dateTest) {
          result.dateTest = [];
        }
        const endDate = moment(new Date(moment(filterTest.rule3).format("MM/DD/YYYY") + " 11:59 PM"));
        const newDate = endDate.utcOffset(0);
        const d = moment(newDate).unix();
        if (filterTest.rule2.toLowerCase() === "not before") {
          result.dateTest.push({
            dateComparison: "before",
            d: d,
            negative: "1"
          });
        } else if (filterTest.rule2.toLowerCase() === "not after") {
          result.dateTest.push({
            dateComparison: "after",
            d: d,
            negative: "1"
          });
        } else {
          result.dateTest.push({
            dateComparison: filterTest.rule2.toLowerCase(),
            d: d
          });
        }
      } else if (["attachment"].includes(header)) {
        if (!(result.attachmentTest)) {
          result.attachmentTest = [];
        }
        result.attachmentTest.push({
          negative: filterTest.rule2.toLowerCase() === "exists" ? "0" : "1"
        });
      } else if (["address in"].includes(header)) {
        if (!(result.addressBookTest)) {
          result.addressBookTest = [];
        }
        result.addressBookTest.push({
          header: filterTest.rule2.toLowerCase(),
          type: filterTest.rule3.toLowerCase()
        });
      } else if (["calendar"].includes(header)) {
        if (!(result.inviteTest)) {
          result.inviteTest = [];
        }

        let contentString: string = "";
        let isNegative: boolean = false;
        if (filterTest.rule2.toLowerCase() === "invite is not requested" || filterTest.rule2.toLowerCase() === "invite is requested") {
          contentString = "anyrequest";
          if (filterTest.rule2.toLowerCase() === "invite is not requested") {
            isNegative = true;
          }
        } else {
          contentString = "anyreply";
          if (filterTest.rule2.toLowerCase() === "invite is not replied") {
            isNegative = true;
          }
        }
        // result.inviteTest.push({
        //   method: [
        //     {
        //       "_content": contentString
        //     }
        //   ]
        // });
        result.inviteTest.push({
          index: 0
        });
      } else if (["social"].includes(header) && filterTest.rule2.toLowerCase().indexOf("facebook") !== -1) {
        if (!(result.facebookTest)) {
          result.facebookTest = [];
        }
        result.facebookTest.push({
        });
      } else if (["social"].includes(header) && filterTest.rule2.toLowerCase().indexOf("linkedin") !== -1) {
        if (!(result.linkedinTest)) {
          result.linkedinTest = [];
        }
        result.linkedinTest.push({
        });
      } else if (["social"].includes(header) && filterTest.rule2.toLowerCase().indexOf("twitter") !== -1) {
        if (!(result.twitterTest)) {
          result.twitterTest = [];
        }
        result.twitterTest.push({
        });
      }
    }
    return result;
  }

  private validateName(name): void {
    if (this.action !== "edit" || (this.action === "edit" && name !== this.data.filter.name)) {
      this.isExists = !!_.find(this.allFilters, { name: name });
    }
    console.log("[validateName]", this.allFilters, name, this.isExists);

    this.changeDetectorRef.markForCheck();
  }

  setDefaultActionValue(filterAction: FormControl) {
    if (filterAction.value.action.toLowerCase() === "redirect to address") {
      filterAction.get("value").setValue("");
      this.invalidAction = true;
      this.changeDetectorRef.markForCheck();
    }
    if (filterAction.value.action.toLowerCase() === "mark as") {
      filterAction.get("value").setValue("read");
    } else {
      filterAction.get("value").setValue("");
      this.changeDetectorRef.markForCheck();
    }
    if (filterAction.value.action.toLowerCase() !== "redirect to address") {
      filterAction.get("value").clearValidators();
      filterAction.get("value").setErrors(null);
      filterAction.get("value").setValue("");
      this.invalidAction = false;
      this.changeDetectorRef.markForCheck();
    }
    if (filterAction.value.action.toLowerCase() === "keep in inbox") {
      this.invalidAction = false;
      this.changeDetectorRef.markForCheck();
    }
    if (filterAction.value.action.toLowerCase() === "delete") {
      this.invalidAction = false;
      this.changeDetectorRef.markForCheck();
    }
    if (filterAction.value.action.toLowerCase() === "move into folder") {
      filterAction.get("name").setValue("");
      this.invalidAction = true;
      this.changeDetectorRef.markForCheck();
    }
  }

  onSubmit(): void {
    console.log(this.form);
  }

  cancel(): void {
    this.dialogRef.close({ saved: false });
  }

  getTranslateKey(text: string): string {
    return text.replace(/\s+/g, "_").replace(/\(|\)/g, "").toUpperCase();
  }

  save(): void {
    this.invalid = false;
    const filterName = this.form.get("name").value;
    if (filterName === "") {
      this.toastService.show("FILTER_NAME_REQUIRE");
      this.invalid = true;
      return;
    }
    if (_.find(this.allFilters, { name: filterName })) {
      if (this.action !== "edit" || (this.action === "edit" && filterName !== this.data.filter.name)) {
        this.invalid = true;
        return;
      }
    }
    let filters = this.allFilters;
    if (!this.processFilterTests()) {
      return;
    }
    const newFilter: Filter = {
      name: filterName,
      active: true,
      filterActions: [this.processFilterActions()],
      filterTests: [this.processFilterTests()],
    };
    if (this.action !== "edit") {
      filters = [...this.allFilters, ...[newFilter]];
    } else {
      filters[this.editedIndex] = newFilter;
    }
    console.log("[save]", this.data.type, filters);
    if (this.data.type === "incoming") {
      this.preferenceService.modifyFilterRules(filters).subscribe((res: any) => {
        this.handleResponse(res);
      });
    } else {
      this.preferenceService.modifyOutgoingFilterRules(filters).subscribe((res: any) => {
        this.handleResponse(res);
      });
    }
    this.changeDetectorRef.markForCheck();
  }

  private handleResponse(res: any): void {
    if (!res.fault) {
      this.translate.get("FILTERS_SAVED").pipe(take(1)).subscribe((text: string) => {
        this.vncLibraryService.openSnackBar(text, "checkmark",
        "", "", 2000, "bottom","left").subscribe(res => {
        });
        this.dialogRef.close({ saved: true });
      });
    } else {
      this.errorText = res.fault.reason.text;
    }

  }

  changeAdditionalFilter(ev: any): void {
    if (this.action === "edit") {
      this.invalid = false;
      this.changeDetectorRef.markForCheck();
    }
  }

  getFolderNameKey(name: string): string {
    if (!!name && name !== "" && name.charAt(0) === "/") {
      name = name.substr(1);
    }
    const folderNameItem = name.toUpperCase();
    const key = folderNameItem + "_FOLDER";
    let folderName = name;
    if (MailConstants.SYSTEM_FOLDERS.indexOf(key) !== -1) {
        this.translate.get(key).pipe(take(1)).subscribe(text => {
            folderName = text;
        });
    }
    return folderName;
  }

}
