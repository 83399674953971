<div class="right-sidebar-component">
  <div class="arrow-down-open"></div>
  <div class="right-sidebar-component__header">
    <vnc-header (onLeftIconClick)="closeSidebar()" style="width:100%;" size="l" title="{{ 'PROFILE' | translate }}"
      [iconTemplate]="backIconTemplate" type="back"></vnc-header>
    <ng-template #backIconTemplate>
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20z" fill="#000" fill-rule="evenodd"/>
    </svg>

    </ng-template>
  </div>
  <div class="right-sidebar-component__info">
    <div class="contact-avatar">
      <vp-avatar [type]="'big-profile'" [user]="contact"> </vp-avatar>

    </div>
    <div class="contact-info">
      <div class="contact-info__name">{{ fullName }}</div>
      <div class="contact-info__role">{{ contact?.job_title }}</div>
      <div *ngIf="(!!contact && contact.is_global === 'true')" class="chat-contact">{{ 'GLOBAL_CONTACT'| translate}}</div>
    </div>

  </div>
  <div class="right-sidebar-component__tabs">
    <vnc-profile [translation]="translations" profileLabel="{{ 'PROFILE' | translate }}" hrLabel="{{ 'HR_DATA' | translate }}"
      accountLabel="{{ 'ACCOUNT' | translate }}" activityLabel="{{ 'ACTIVITY' | translate }}"
      (onCopy)="copyEmail($event)"
      (openURL)="openURL($event)"
      [hideAudio]="contact?.email === currentUser?.email"
      [hideEmail]="contact?.email === currentUser?.email"
      [hideVideo]="contact?.email === currentUser?.email"
      [hideChat]="contact?.email === currentUser?.email"
      (profileAction)="handleProfileAction($event)"
      [activityTemplate]="activityTemplate" [hideFiles]="true" [hideChatInfo]="true"
      [maxTabs]="isLargeScreen ? 3 : 2" [alignActionCenter]="true" [profile]="contact"></vnc-profile>
  </div>
</div>

<ng-template #activityTemplate>
  <div class="detail-contact ">
  <div class="recent-activity">
    <mat-tab-group (selectedTabChange)="onSelectedTabChangeActivity($event)">
        <mat-tab label="{{ 'CHATS' | translate }}">
            <div class="recent-contact-tab">
                <div class="recent-items" *ngIf="!!searchResponse && searchResponse.docs.length > 0">
                    <div class="recent-item" *ngFor="let item of searchResponse.docs" (click)="openRecentActivity(item)">
                        <span *ngIf="item.type === 'talk' && !!item.chatType && item.chatType === 'chat'">
                            <div class="recen-item-inner">
                                <div class="left">
                                    <div class="avatar-item">
                                        <vp-avatar
                                            [user]="{firstLastCharacters: item.from.charAt(0), email: item.from, color: getBgAvatarColor(item.from), avatarURL: getAvatarUrl(item.from)}">
                                        </vp-avatar>
                                    </div>
                                </div>
                                <div class="right">
                                    <div class="title">
                                        <span class="title-content">{{ item.contentTxt}}</span>
                                        <span class="item-type"> •
                                            <span *ngIf="item.type === 'talk' && !!item.chatType && item.chatType === 'chat'">
                                                <mat-icon class="mdi-14px" fontSet="mdi" fontIcon="mdi-message"></mat-icon>
                                                {{'CHAT_LEBALE' | translate }}
                                            </span>
                                        </span>
                                    </div>
                                    <div class="time">
                                        {{ item.createdDt | date: "mediumDate"}} <span class="time-hours"> • {{ item.createdDt | date: "hh:mm a"}}</span>
                                    </div>
                                </div>
                            </div>
                            <mat-divider></mat-divider>
                        </span>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="{{ 'EMAILS' | translate }}">
            <div class="recent-contact-tab">
                <div class="recent-items" *ngIf="!!searchResponse && searchResponse.docs.length > 0">
                    <div class="recent-item" *ngFor="let item of searchResponse.docs" (click)="openRecentActivity(item)">
                        <span *ngIf="item.type === 'mail'">
                            <div class="recen-item-inner">
                                <div class="left">
                                    <div class="avatar-item">
                                        <vp-avatar
                                            [user]="{firstLastCharacters: item.from.charAt(0), email: item.from, color: getBgAvatarColor(item.from), avatarURL: getAvatarUrl(item.from)}">
                                        </vp-avatar>
                                    </div>
                                </div>
                                <div class="right">
                                    <div class="title">
                                        <span class="title-content">{{ item.title}}</span>
                                        <span class="item-type"> •
                                            <span *ngIf="item.type === 'mail'">
                                                <mat-icon class="mdi-14px" fontSet="mdi" fontIcon="mdi-email"></mat-icon>
                                                {{ 'EMAIL_LBL' | translate }}
                                            </span>
                                        </span>
                                    </div>
                                    <div class="time">
                                        {{ item.createdDt | date: "mediumDate"}} <span class="time-hours"> • {{ item.createdDt | date: "hh:mm a"}}</span>
                                    </div>
                                </div>
                            </div>
                            <mat-divider></mat-divider>
                        </span>
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
  </div>
  </div>
</ng-template>
