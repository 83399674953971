
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="mail-dialog">
    <div class="mail__dialog-header desktop_view">
        <div class="header_lbl disable-select">
            {{ 'ATTACH_EMAIL_LBL' | translate }}
        </div>
        <div class="submit">
            <button mat-button (click)="close()">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
    </div>
    <div class="mail__dialog-body mail__dialog-body--pad">
        <div class="content">
            <div class="search_div">
                <div class="searchField">
                    <mat-form-field>
                        <input matInput [(ngModel)]="searchField">
                    </mat-form-field>
                    <mat-icon (click)="searchMessages()">search</mat-icon>
                </div>
                <div class="pagging">
                    <button mat-button (click)="loadPreviousMail()" [disabled]="offset===0">
                        <mat-icon class="disable-select">keyboard_arrow_left</mat-icon>
                    </button>
                    <span *ngIf="limit!==0">{{offset + 1 }} - {{limit + offset}}</span>
                    <button mat-button (click)="loadNextMail()" [disabled]="isMoreMessage">
                        <mat-icon class="disable-select">keyboard_arrow_right</mat-icon>
                    </button>
                </div>
            </div>
            <div class="tree_email_div">
                <div class="tree_div">
                    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="folder-tree">
                        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                            <li class="mat-tree-node" (click)="folderItem(node)"
                                [class.selected-class]="selectedFolder !== null && node.id === selectedFolder.id">
                                <button mat-icon-button disabled></button>
                                <mat-icon [style.color]="node.rgb" *ngIf="node.id !==''">folder</mat-icon>{{getFolderNameKey(node.name)}}
                            </li>
                        </mat-tree-node>
                        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                            <li>
                                <div class="mat-tree-node" (click)="folderItem(node)"
                                    [class.selected-class]="selectedFolder !== null && node.id === selectedFolder.id">
                                    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
                                        <mat-icon class="mat-icon-rtl-mirror">
                                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                        </mat-icon>
                                    </button>
                                    <mat-icon [style.color]="node.rgb" *ngIf="node.id !==''">folder</mat-icon>
                                    {{getFolderNameKey(node.name)}}
                                </div>
                                <ul [class.folder-tree-invisible]="!treeControl.isExpanded(node)">
                                    <ng-container matTreeNodeOutlet></ng-container>
                                </ul>
                            </li>
                        </mat-nested-tree-node>
                    </mat-tree>
                </div>
                <div class="email_list">
                    <div *ngIf="msgs.length > 0">
                        <div *ngFor="let m of msgs; index as i;">
                            <div class="suggestion" (click)="selectEmail(m, i, $event)"
                                [ngClass]="{'is-selected': isSelectedEmailMessage(m)}">
                                <vp-avatar [type]="'mail-detail-avatar'"
                                    [user]="{firstLastCharacters: m.senderEmail?.charAt(0), color: '#317bbc', email: m.senderEmail}">
                                </vp-avatar>
                                <div class="email_name_division">
                                    <div class="disable-select subject_date">
                                        <div class="subject" [ngClass]="{'is-unread': m.f && m.f.indexOf('u')}">
                                            {{m.su}}
                                        </div>
                                        <div class="date">
                                            {{m.d | vpDisplayTime: 'MMM DD'}}
                                        </div>
                                    </div>
                                    <p class="disable-select">{{m.fr}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="btn">
        <button mat-button (click)="close()">
            {{ 'CANCEL' | translate }}
        </button>
        <button mat-button [disabled]="selectedEmails.length === 0" (click)="attachMessages();">
            {{ 'ATTACH_LBL' | translate }}
        </button>
    </div>
</div>