
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
    Directive,
    HostListener,
    Input,
    Output,
    EventEmitter
  } from "@angular/core";
  import { DateAdapter } from "../date-adapters/date-adapter";
  import { CalendarView } from "./calendar-view.enum";
  import { addDaysWithExclusions } from "./util";

  /**
   * Change the view date to the previous view. For example:
   *
   * ```typescript
   * <button
   *  vpCalendarPreviousView
   *  [(viewDate)]="viewDate"
   *  [view]="view">
   *  Previous
   * </button>
   * ```
   */
  @Directive({
    selector: "[vpCalendarPreviousView]"
  })
  export class CalendarPreviousViewDirective {
    /**
     * The current view
     */
    @Input() view: CalendarView;

    /**
     * The current view date
     */
    @Input() viewDate: Date;

    /**
     * Days to skip when going back by 1 day
     */
    @Input() excludeDays: number[] = [];

    /**
     * The number of days in a week. If set will subtract this amount of days instead of 1 week
     */
    @Input() daysInWeek: number;

    /**
     * Called when the view date is changed
     */
    @Output() viewDateChange: EventEmitter<Date> = new EventEmitter();

    constructor(private dateAdapter: DateAdapter) {}

    /**
     * @hidden
     */
    @HostListener("click")
    onClick(): void {
      const subFn: any = {
        day: this.dateAdapter.subDays,
        week: this.dateAdapter.subWeeks,
        month: this.dateAdapter.subMonths,
        year: this.dateAdapter.subYears,
      }[this.view];
      if (this.view === CalendarView.Day) {
        this.viewDateChange.emit(
          addDaysWithExclusions(
            this.dateAdapter,
            this.viewDate,
            -1,
            this.excludeDays
          )
        );
      } else if (this.view === CalendarView.DayThree) {
        this.viewDateChange.emit(
          addDaysWithExclusions(
            this.dateAdapter,
            this.viewDate,
            -3,
            this.excludeDays
          )
        );
      } else if (this.view === CalendarView.Week && this.daysInWeek) {
        this.viewDateChange.emit(
          addDaysWithExclusions(
            this.dateAdapter,
            this.viewDate,
            -this.daysInWeek,
            this.excludeDays
          )
        );
      } else {
        this.viewDateChange.emit(subFn(this.viewDate, 1));
      }
    }
  }
