
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ResizableModule } from "angular-resizable-element";
import { DragAndDropModule } from "angular-draggable-droppable";
import { CalendarDayThreeViewComponent } from "./calendar-day-three-view.component";
import { CalendarDayThreeViewHeaderComponent } from "./calendar-day-three-view-header.component";
import { CalendarDayThreeViewAppointmentComponent } from "./calendar-day-three-view-event.component";
import { CalendarCommonModule } from "../common/calendar-common.module";
import { CalendarWeekViewHourSegmentComponent } from "./calendar-day-three-view-hour-segment.component";
import { SharedModule } from "src/app/shared/shared.module";

export {
  CalendarDayThreeViewComponent,
  CalendarWeekViewBeforeRenderEvent
} from "./calendar-day-three-view.component";
export {
  WeekViewAllDayEvent as CalendarWeekViewAllDayEvent,
  WeekViewAllDayEventRow as CalendarWeekViewAllDayAppointmentRow,
  GetWeekViewArgs as CalendarGetWeekViewArgs
} from "calendar-utils";
export { getWeekViewPeriod } from "../common/util";

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    ResizableModule,
    DragAndDropModule,
    CalendarCommonModule
  ],
  declarations: [
    CalendarDayThreeViewComponent,
    CalendarDayThreeViewHeaderComponent,
    CalendarDayThreeViewAppointmentComponent,
    CalendarWeekViewHourSegmentComponent
  ],
  exports: [
    ResizableModule,
    DragAndDropModule,
    CalendarDayThreeViewComponent,
    CalendarDayThreeViewHeaderComponent,
    CalendarDayThreeViewAppointmentComponent,
    CalendarWeekViewHourSegmentComponent
  ]
})
export class CalendarDayThreeModule {}
