
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  Component, Input, Output, EventEmitter,
  TemplateRef, ElementRef, ViewChild, OnInit, OnDestroy
} from "@angular/core";
import { DayViewEvent } from "calendar-utils";
import { PlacementArray } from "positioning";
import { Subject, Subscription } from "rxjs";
import { CalenderUtils } from "../../utils/calender-utils";
import { CalendarRepository } from "../../repositories/calendar.repository";

@Component({
  selector: "vp-calendar-day-view-event",
  template: `
    <ng-template
      #defaultTemplate
      let-dayEvent="dayEvent"
      let-tooltipPlacement="tooltipPlacement"
      let-eventClicked="eventClicked"
      let-onDoubleClicked="onDoubleClicked"
      let-onContextMenuClicked="onContextMenuClicked"
      let-tooltipTemplate="tooltipTemplate"
      let-tooltipAppendToBody="tooltipAppendToBody"
      let-tooltipDelay="tooltipDelay"
      let-isSelecting="isSelecting">
      <div *ngIf="!dayEvent.event.isNewInMobile || (dayEvent.event.isNewInMobile && dayEvent.event.allDay)"
        class="cal-event"
        [class.cal-event-selecting]="isSelecting"
        [class.cal-allday-event]="dayEvent.event.allDay"
        [hidden]="!isCalendarCheck(dayEvent.event.folderId)"
        [ngStyle]="{
          background: getBackgroundColor(dayEvent.event.bgcolor),
          borderColor: dayEvent.event.color?.primary
        }"
        [vpCalendarTooltip]="dayEvent.event.title | vpCalendarAppointmentTitle: 'dayTooltip':dayEvent.event"
        [tooltipPlacement]="tooltipPlacement"
        [tooltipEvent]="dayEvent.event"
        [tooltipTemplate]="tooltipTemplate"
        [tooltipAppendToBody]="tooltipAppendToBody"
        [tooltipDelay]="tooltipDelay"
        (vpCalClick)="handleAppointmentClicked($event)"
        (dblclick)="handleDoubleClicked($event)"
        (contextmenu)="onContextMenuClicked.emit({ $event: $event, calendarEvent: dayEvent })"
        vpLongPress [timeout]="700" (onLongPress)="longPressAppointment(dayEvent.event);"
        >
        <span class="cal-visibility"
          [class.cal-visibility-F]="dayEvent.event.fba === 'F'"
          [class.cal-visibility-T]="dayEvent.event.fba === 'T'"
          [class.cal-visibility-B]="dayEvent.event.fba === 'B'"
          [class.cal-visibility-O]="dayEvent.event.fba === 'O'"
        ></span>
        <vp-calendar-appointment-actions
          [event]="dayEvent.event"
          [customTemplate]="eventActionsTemplate">
        </vp-calendar-appointment-actions>
        &ngsp;
        <vp-calendar-appointment-title
          [event]="dayEvent.event"
          [customTemplate]="eventTitleTemplate"
          view="day">
        </vp-calendar-appointment-title>
      </div>
      <div *ngIf="dayEvent.event.isNewInMobile && !dayEvent.event.allDay"
        class="cal-event cal-mobile-event-new"
        [class.cal-allday-event]="dayEvent.event.allDay"
        [ngStyle]="{
          borderColor: dayEvent.event.bgcolor
        }">
        <span class="cal-mobile-event-start-dot" [ngStyle]="{ backgroundColor: dayEvent.event.bgcolor }"></span>
        <span class="cal-mobile-event-end-dot" [ngStyle]="{ backgroundColor: dayEvent.event.bgcolor }"></span>
      </div>
    </ng-template>
    <ng-template
      [ngTemplateOutlet]="customTemplate || defaultTemplate"
      [ngTemplateOutletContext]="{
        dayEvent: dayEvent,
        tooltipPlacement: tooltipPlacement,
        eventClicked: eventClicked,
        onContextMenuClicked: onContextMenuClicked,
        tooltipTemplate: tooltipTemplate,
        tooltipAppendToBody: tooltipAppendToBody,
        tooltipDelay: tooltipDelay,
        isSelecting: isSelecting
      }">
    </ng-template>
  `
})
export class CalendarDayViewAppointmentComponent implements OnInit, OnDestroy {
  @Input() dayEvent: DayViewEvent;

  @Input() tooltipPlacement: PlacementArray;

  @Input() tooltipAppendToBody: boolean;

  @Input() customTemplate: TemplateRef<any>;

  @Input() eventTitleTemplate: TemplateRef<any>;

  @Input() eventActionsTemplate: TemplateRef<any>;

  @Input() tooltipTemplate: TemplateRef<any>;

  @Input() tooltipDelay: number | null;

  @Input() refresh: Subject<any>;

  @Output() eventClicked: EventEmitter<any> = new EventEmitter();

  @Output() onContextMenuClicked: EventEmitter<any> = new EventEmitter();

  @Output() onDoubleClicked: EventEmitter<any> = new EventEmitter();

  @Output() longPressClick: EventEmitter<any> = new EventEmitter();

  refreshSubscription: Subscription;

  isSelecting: boolean = false;

  constructor(private calendarRepository: CalendarRepository) {

  }

  ngOnInit(): void {
    if (this.refresh) {
      this.refreshSubscription = this.refresh.subscribe((res) => {
        if (this.dayEvent.event["eventId"] !== res.event["eventId"]) {
          this.isSelecting = false;
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

  private handleAppointmentClicked(event: any) {
    if (this.isSelecting) {
      this.isSelecting = false;
    } else {
      this.isSelecting = true;
    }

    this.eventClicked.emit({ isSelecting: this.isSelecting });
  }

  private handleDoubleClicked(event: any) {
    this.onDoubleClicked.emit({event: event});
  }

  getBackgroundColor(color: string): string {
    const grandient = CalenderUtils.getBackgroundGrandientColor(color);
    if (!!grandient) {
      return grandient;
    }
    return color;
  }

  isCalendarCheck(folderId: string): boolean {
    const checkUncheck = this.calendarRepository.isCalendarCheck(folderId);
    return checkUncheck;
  }

  longPressAppointment(ev: any): void {
    this.longPressClick.emit(ev);
  }

}
