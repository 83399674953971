
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Input, Output, EventEmitter, TemplateRef } from "@angular/core";
import { CalendarEvent, WeekDay } from "calendar-utils";
import { trackByWeekDayHeaderDate } from "../common/util";

@Component({
  selector: "vp-calendar-day-three-view-header",
  template: `
    <ng-template
      #defaultTemplate
      let-days="days"
      let-locale="locale"
      let-dayHeaderClicked="dayHeaderClicked"
      let-eventDropped="eventDropped"
      let-trackByWeekDayHeaderDate="trackByWeekDayHeaderDate"
    >
      <div class="cal-day-headers">
        <div
          class="cal-header"
          *ngFor="let day of days; trackBy: trackByWeekDayHeaderDate"
          [class.cal-past]="day.isPast"
          [class.cal-future]="day.isFuture"
          [class.cal-weekend]="day.isWeekend"
          [ngClass]="day.cssClass"
          (vpCalClick)="dayHeaderClicked.emit({ day: day })"
          mwlDroppable
          dragOverClass="cal-drag-over"
          (drop)="
            eventDropped.emit({
              event: $event.dropData.event,
              newStart: day.date
            })">
          <div class="cal-date-title-badge" [class.today]="day.isToday">
            <div id="threeDayDayOfMonthBadge" class="day-of-month-badge">
            {{ day.date | vpCalendarDate: 'dayThreeViewColumnHeader':locale }}</div>
            <span class="week-day-badge">{{ day.date | vpCalendarDate: 'dayThreeViewColumnSubHeader':locale }}</span>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template
      [ngTemplateOutlet]="customTemplate || defaultTemplate"
      [ngTemplateOutletContext]="{
        days: days,
        locale: locale,
        dayHeaderClicked: dayHeaderClicked,
        eventDropped: eventDropped,
        trackByWeekDayHeaderDate: trackByWeekDayHeaderDate
      }"
    >
    </ng-template>
  `
})
export class CalendarDayThreeViewHeaderComponent {
  @Input() days: WeekDay[];

  @Input() locale: string;

  @Input() customTemplate: TemplateRef<any>;

  @Output() dayHeaderClicked: EventEmitter<{ day: WeekDay }> = new EventEmitter<{ day: WeekDay; }>();

  @Output() eventDropped: EventEmitter<{ event: CalendarEvent; newStart: Date; }> =
    new EventEmitter<{ event: CalendarEvent; newStart: Date }>();

  trackByWeekDayHeaderDate = trackByWeekDayHeaderDate;
}
