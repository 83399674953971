
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="cal-day-view">
  <div *ngIf="!isMobileScreen" class="cal-date-title-badge" [class.today]="isToday()">
    <div id="desktopDayViewDayOfMonthBadge" class="day-of-month-badge">{{ viewDate | date: "dd":"" : locale }}</div>
    <span class="week-day-badge">{{ viewDate | date: "EEE":"" : locale }}</span>
  </div>
  <div
    id="dayViewCalAlldayEvents"
    class="cal-all-day-events"
    mwlDroppable
    dragOverClass="cal-drag-over"
    dragActiveClass="cal-drag-active"
    (drop)="appointmentDropped($event, view.period.start, true)"
    (vpCalClick)="alldayHourSegmentClicked.emit({ date: viewDate })"
    (swipeleft)="onViewSwipe($event)"
    (swiperight)="onViewSwipe($event)"
    [clickListenerDisabled]="alldayHourSegmentClicked.observers.length === 0"
    (contextmenu)="hourSegmentContextMenuClicked.emit({ $event: $event, segment: viewDate, allDay: true })"
  >
    <div class="cal-all-day-events-title">
      <span class="all-day-title">{{ 'CALENDARS.ALL_DAY_LBL' | translate }}</span>
      <div *ngIf="isMobileScreen" class="cal-date-title-badge" [class.today]="isToday()">
        <div id="mobileDayViewDayOfMonthBadge" class="day-of-month-badge">{{ viewDate | date: "dd":"": locale }}</div>
        <span class="week-day-badge">{{ viewDate | date: "EEE":"":locale }}</span>
      </div>
    </div>
    <div class="cal-all-day-events-row">
      <vp-calendar-day-view-event
        *ngFor="let event of view.allDayEvents; trackBy: trackByEventId"
        [ngClass]="event.cssClass"
        [dayEvent]="{ event: event }"
        [tooltipPlacement]="tooltipPlacement"
        [tooltipTemplate]="tooltipTemplate"
        [tooltipAppendToBody]="tooltipAppendToBody"
        [tooltipDelay]="tooltipDelay"
        [customTemplate]="eventTemplate"
        [eventTitleTemplate]="eventTitleTemplate"
        [eventActionsTemplate]="eventActionsTemplate"
        [refresh]="eventRefresh"
        (eventClicked)="handleAppointmentClicked(event, $event.isSelecting)"
        (onContextMenuClicked)="onContextMenuClicked.emit($event)"
        (onDoubleClicked)="handleDoubleClickedHandled(event)"
        (longPressClick)="longPressClicked.emit($event)"
      >
      </vp-calendar-day-view-event>
    </div>
  </div>
  <div
    id="dayViewCalHourRows"
    class="cal-hour-rows"
    #dayEventsContainer
    mwlDroppable
    (dragEnter)="eventDragEnter = eventDragEnter + 1"
    (dragLeave)="eventDragEnter = eventDragEnter - 1"
    (swipeleft)="onViewSwipe($event)"
    (swiperight)="onViewSwipe($event)"
  >
    <div class="cal-events">
      <div
        #event
        *ngFor="let dayEvent of view?.events; trackBy: trackByDayEvent"
        class="cal-event-container"
        [class.cal-draggable]="dayEvent.event.draggable"
        [class.cal-starts-within-day]="!dayEvent.startsBeforeDay"
        [class.cal-ends-within-day]="!dayEvent.endsAfterDay"
        [ngClass]="dayEvent.event.cssClass"
        mwlResizable
        [resizeSnapGrid]="{
          top: eventSnapSize || hourSegmentHeight,
          bottom: eventSnapSize || hourSegmentHeight
        }"
        [validateResize]="validateResize"
        (resizeStart)="resizeStarted(dayEvent, $event, dayEventsContainer)"
        (resizing)="resizing(dayEvent, $event)"
        (resizeEnd)="resizeEnded(dayEvent)"
        mwlDraggable
        dragActiveClass="cal-drag-active"
        [dropData]="{ event: dayEvent.event, calendarId: calendarId }"
        [dragAxis]="{
          x:
            !snapDraggedEvents &&
            dayEvent.event.draggable &&
            currentResizes.size === 0,
          y: dayEvent.event.draggable && currentResizes.size === 0
        }"
        [dragSnapGrid]="
          snapDraggedEvents ? { y: eventSnapSize || hourSegmentHeight } : {}
        "
        [validateDrag]="validateDrag"
        [ghostDragEnabled]="!snapDraggedEvents"
        (dragStart)="dragStarted(event, dayEventsContainer, dayEvent)"
        (dragging)="dragMove($event)"
        (dragEnd)="dragEnded(dayEvent, $event)"
        [style.marginTop.px]="dayEvent.top"
        [style.height.px]="dayEvent.height"
        [style.marginLeft.px]="dayEvent.left + eventMarginDefault"
        [style.width.px]="dayEvent.width - 1"
      >
        <div
          id="canEventResizeHandlerTop"
          class="cal-resize-handle cal-resize-handle-before-start"
          *ngIf="
            dayEvent.event?.resizable?.beforeStart && !dayEvent.startsBeforeDay
          "
          mwlResizeHandle
          [resizeEdges]="{ top: true }"
        ></div>
        <vp-calendar-day-view-event
          [dayEvent]="dayEvent"
          [tooltipPlacement]="tooltipPlacement"
          [tooltipTemplate]="tooltipTemplate"
          [tooltipAppendToBody]="tooltipAppendToBody"
          [tooltipDelay]="tooltipDelay"
          [customTemplate]="eventTemplate"
          [eventTitleTemplate]="eventTitleTemplate"
          [eventActionsTemplate]="eventActionsTemplate"
          [refresh]="eventRefresh"
          (eventClicked)="handleAppointmentClicked(dayEvent.event, $event.isSelecting)"
          (onContextMenuClicked)="onContextMenuClicked.emit($event)"
          (onDoubleClicked)="handleDoubleClickedHandled(dayEvent.event)"
          (longPressClick)="longPressClicked.emit($event)"
        >
        </vp-calendar-day-view-event>
        <div
          id="canEventResizeHandlerBottom"
          class="cal-resize-handle cal-resize-handle-after-end"
          *ngIf="dayEvent.event?.resizable?.afterEnd && !dayEvent.endsAfterDay"
          mwlResizeHandle
          [resizeEdges]="{ bottom: true }"
        ></div>
      </div>
    </div>
    <div
      id="dayViewCalHourRow_{{hourIndex}}"
      class="cal-hour"
      *ngFor="let hour of hours; trackBy: trackByHour; let hourIndex = index"
      [style.minWidth.px]="view?.width + 70"
    >
      <vp-calendar-day-view-hour-segment
        *ngFor="let segment of hour.segments; trackBy: trackByHourSegment"
        [style.height.px]="hourSegmentHeight"
        [segment]="segment"
        [segmentHeight]="hourSegmentHeight"
        [locale]="locale"
        [customTemplate]="hourSegmentTemplate"
        (vpCalClick)="hourSegmentClicked.emit({ date: segment.date })"
        [clickListenerDisabled]="hourSegmentClicked.observers.length === 0"
        mwlDroppable
        dragOverClass="cal-drag-over"
        dragActiveClass="cal-drag-active"
        (drop)="eventDropped($event, segment.date, false)"
        (hourSegmentContextMenuClicked)="hourSegmentContextMenuClicked.emit($event)"
      >
      </vp-calendar-day-view-hour-segment>
    </div>
    <div #calTimeline class="cal-timeline-container">
      <div class="timeline-bar">
        <mat-icon  class="mdi-24px" fontSet="mdi" fontIcon="mdi-ray-start-arrow"></mat-icon>
      </div>
    </div>
  </div>
</div>
