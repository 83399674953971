import { take } from "rxjs/operators";
import { Subject, Observable, of, EMPTY } from "rxjs";
import { DatabaseInterface } from "./database.interface";
import { Conversation } from "../../mail/shared/models/conversation.model";
import { Message } from "../../mail/shared/models/message.model";
import { Signature } from "../../preference/shared/models/signature.model";
import { MailFolder } from "src/app/mail/models/mail-folder.model";


export class DumbDBService implements DatabaseInterface {
  dbName = "OfflineDatabase3";

  dbVersion = 7;
  // A list of all versions:
  // 6 - initial
  // 7 - add  CURRENT_USER_DB_STORE
  pendingOperations = [];

  constructor() {
    console.log("[dumbDBService][constructor]");
  }
  dataBaseReadyCallback?: any;

  storeAvatar(avatarB64Url: string, email: string): Observable<any> {
    return EMPTY;
  }

  updateConversationMessages(convId: string, messages: any[]): Observable<any> {
    return EMPTY;
  }

  deleteAvatar(email: string): Observable<any> {
    return EMPTY;
  }

  getAvatarByEmail(email: string): Observable<any> {
    return EMPTY;
  }

  fetchAllAvatarFromDatabase(): Observable<any> {
    return of([]);
  }


  deleteDB(): Observable<any> {
    const response = new Subject<any>();
    response.next(true);
    return response.asObservable().pipe(take(1));
  }

  clearDB(): Observable<any> {
    console.info("[dumbDBService][clearDB]");

    const response = new Subject<any>();
    response.next(true);
    return response.asObservable().pipe(take(1));
  }

  // offline data


  addAppointments(appointments: any[], query?: any): Observable<any> {
    // console.log("[dumbDBService][addAppointments]", appointments);
    const response = new Subject<any>();
    setTimeout(() => {
      response.next(true);
    }, 10);
    return response.asObservable().pipe(take(1));
  }

  addMessages(messages: Message[], query?: any): Observable<any> {
    console.log("[dumbDBService][addMessages]", messages);
    const response = new Subject<any>();
    setTimeout(() => {
      response.next(true);
    }, 10);
    return response.asObservable().pipe(take(1));
  }

  private getMessagesByIds(ids: string[], ignoreLog?: boolean): Observable<any> {
    const messagesMap = {};
    const response = new Subject<any>();
    console.log("[dumbDBService][getMessagesByIds]", ids);
    response.next(messagesMap);
    return response.asObservable().pipe(take(1));
  }

  getMessagesByConversationId(conversationId): Observable<Message[]> {
    console.log("[dumbDBService][getMessagesByConversationId] conversationId", conversationId);
    const response = new Subject<Message[]>();
    const messagesMap = [];
    response.next(messagesMap);
    return response.asObservable().pipe(take(1));
  }

  getAppointmentsById(id): Observable<Message[]> {
    console.log("[dumbDBService][getAppointmentsById] id", id);
    const response = new Subject<Message[]>();
    const messagesMap = [];
    setTimeout(() => {

      response.next(messagesMap);
    }, 1);
    return response.asObservable().pipe(take(1));
  }

  fetchAttachmentById(id): Observable<any> {
    console.log("[dumbDBService][fetchAttachmentById] id", id);
    const response = new Subject<any>();
    const attMap = [];
    setTimeout(() => {

      response.next(attMap);
    }, 1);
    return response.asObservable().pipe(take(1));
  }

  fetchAttachmentsBefore(ts): Observable<any> {
    const response = new Subject<any>();
    const attMap = [];
    setTimeout(() => {

      response.next(attMap);
    }, 1);
    return response.asObservable().pipe(take(1));
  }

  getMessagesByFolder(folderName, query?, includingId?): Observable<Message[]> {
    console.log("[dumbDBService][getMessagesByFolder]", folderName, query);

    const response = new Subject<Message[]>();

    const messagesMap = [];
    response.next(messagesMap);

    return response.asObservable().pipe(take(1));
  }

  getMessagesByTag(tagName): Observable<Message[]> {
    console.log("[dumbDBService][getMessagesByTag]", tagName);
    const response = new Subject<Message[]>();

    const messagesMap = [];
    response.next(messagesMap);

    return response.asObservable().pipe(take(1));
  }

  getLatestMessage(): Observable<Message[]> {
    const response = new Subject<Message[]>();

    const messagesMap = [];
    response.next(messagesMap);

    return response.asObservable().pipe(take(1));
  }

  getFirstMessageByFolder(folderName, order): Observable<Message[]> {
    const response = new Subject<Message[]>();

    const messagesMap = [];
    setTimeout(() => {
      response.next(messagesMap);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  getMessageCountInDatabaseByFolder(folderName): Observable<any> {
    const response = new Subject<any>();
    setTimeout(() => {
      response.error(null);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  getMessageById(id, ignoreLog?: boolean): Observable<any> {
    if (!ignoreLog) {
      console.log("[dumbDBService][getMessageById]", id);
    }

    const response = new Subject<any>();

      console.log("[dumbDBService][getMessageById]", id);
    const messagesMap = {};
    setTimeout(() => {
      response.next(messagesMap);
    }, 20);

    return response.asObservable().pipe(take(1));
  }

  getMessagesStarred(): Observable<Message[]> {
    console.log("[dumbDBService][getMessagesStarred]");
    const response = new Subject<Message[]>();

    const messagesMap = [];
    setTimeout(() => {
      response.next(messagesMap);
    }, 20);

    return response.asObservable().pipe(take(1));
  }

  getMessagesSent(): Observable<Message[]> {
    console.log("[dumbDBService][getMessagesSent]");
    const response = new Subject<Message[]>();
    const messagesMap = [];
    setTimeout(() => {
      response.next(messagesMap);
    }, 20);

    return response.asObservable().pipe(take(1));
  }

  updateMessages(messages: Message[]): Observable<any> {
    console.log("[dumbDBService][updateMessages]", messages);

    const response = new Subject<any>();
    setTimeout(() => {
      response.next(true);
    }, 20);
    return response.asObservable().pipe(take(1));
  }

  updateMessagesAsStarred(messages: Message[], isStared: boolean): Observable<any> {
    return this.updateMessages(messages);
  }

  moveMessagesBetweenFolders(ids: string[], newFolder: string): Observable<any> {
    console.log("[dumbDBService][moveMessagesBetweenFolders]", ids, newFolder);

    const response = new Subject<any>();
    setTimeout(() => {
      response.next(true);
    }, 10);
    return response.asObservable().pipe(take(1));
  }

  deleteMessage(messageId: string): Observable<any> {
    console.log("[dumbDBService][deleteMessage]", messageId);

    return this.deleteMessages([messageId]);
  }

  deleteMessages(ids: string[]): Observable<any> {
    console.log("[dumbDBService][deleteMessages]", ids);

    const response = new Subject<any>();
    setTimeout(() => {
      response.next(true);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  deleteAppointments(ids: string[], type?: string): Observable<any> {
    console.log("[dumbDBService][deleteAppointments]", ids);

    const response = new Subject<any>();
    setTimeout(() => {
      response.next(true);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  deleteAttachment(ids: string[], type?: string): Observable<any> {
    console.log("[dumbDBService][deleteAttachment]", ids);

    const response = new Subject<any>();
    setTimeout(() => {
      response.next(true);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  deleteMessagesByConversation(convId: string): Observable<any> {
    console.log("[dumbDBService][deleteMessagesByConversation] convId", convId);

    const response = new Subject<any>();

    setTimeout(() => {
      response.next(true);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  ///
  ///

  addConversations(conversations): Observable<any> {
    console.log("[dumbDBService][addConversations]", conversations);

    const response = new Subject<any>();

    setTimeout(() => {
      response.next(true);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  getConversationsByFolder(folderName): Observable<Conversation[]> {
    console.log("[dumbDBService][getConversationsByFolder]", folderName);

    const response = new Subject<Conversation[]>();
    const messagesMap = [];
    setTimeout(() => {
      response.next(messagesMap);
    }, 20);

    return response.asObservable().pipe(take(1));
  }

  getAllAppointments(): Observable<any[]> {
    console.log("[dumbDBService][getConversationsByFolder]");

    const response = new Subject<any[]>();
    const appMap = [];
    setTimeout(() => {
      response.next(appMap);
    }, 20);

    return response.asObservable().pipe(take(1));
  }

  getConversationsByTag(tagName): Observable<Conversation[]> {
    const response = new Subject<Conversation[]>();

    const messagesMap = [];
    setTimeout(() => {
      response.next(messagesMap);
    }, 20);

    return response.asObservable().pipe(take(1));
  }

  getConversationById(id: string): Observable<Conversation> {
    console.log("[dumbDBService][getConversationById]", id);

    const response = new Subject<Conversation>();
    setTimeout(() => {
      response.error(null);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  getConversationsStarred(): Observable<Conversation[]> {
    console.log("[dumbDBService][getConversationsStarred]");
    const response = new Subject<Conversation[]>();

    const messagesMap = [];
    setTimeout(() => {
      response.next(messagesMap);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  getConversationsSent(): Observable<Conversation[]> {
    console.log("[dumbDBService][getConversationsSent]");
    const response = new Subject<Conversation[]>();

    const messagesMap = [];
    setTimeout(() => {
      response.next(messagesMap);
    }, 20);

    return response.asObservable().pipe(take(1));
  }

  updateConversations(convs: Conversation[]): Observable<any> {
    console.log("[dumbDBService][updateConversations]", convs);

    const response = new Subject<any>();
    setTimeout(() => {
      response.next(true);
    }, 10);


    return response.asObservable().pipe(take(1));
  }

  updateConversationsAsStarred(convs: Conversation[], isStared: boolean): Observable<any> {
    return this.updateConversations(convs);
  }

  addMessageToConversation(convId: string, message: Message): Observable<any> {
    console.info(`[dumbDBService][addMessageToConversation] convId = ${convId}, message = ${JSON.stringify(message)}`);

    const response = new Subject<any>();
    setTimeout(() => {
      response.next(true);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  moveConversationsBetweenFolders(ids: string[], newFolder: string): Observable<any> {
    console.log("[dumbDBService][moveConversationsBetweenFolders]", ids, newFolder);

    const response = new Subject<any>();

    setTimeout(() => {
      response.next(true);
    }, 10);


    return response.asObservable().pipe(take(1));
  }

  deleteConversation(conversationId: string, keepMessages?: boolean): Observable<any> {
    console.log(`[dumbDBService][deleteConversation]`, conversationId, keepMessages);

    return this.deleteConversations([conversationId], keepMessages);
  }

  deleteConversations(ids: string[], keepMessages?: boolean): Observable<any> {
    console.log(`[dumbDBService][deleteConversations]`, ids, keepMessages);

    const response = new Subject<any>();

    setTimeout(() => {
      response.next(true);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  ///

  addPendingOperation(objectId: string, op: string, request: any): Observable<any> {
    console.log("[dumbDBService][addPendingOperation]", objectId, op, request);
    const key = this.getPendingOperationKey(objectId, op);

    this.pendingOperations[key] = { id: key, objectId: objectId, op: op, request: request };
    console.log("[dumbDBService][addPendingOperation] post: ", this.pendingOperations);
    const response = new Subject<any>();
    setTimeout(() => {
      response.next(true);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  getAllPendingOperations(): Observable<any> {
    console.log("[dumbDBService][getAllPendingOperations] ", this.pendingOperations);
    const response = new Subject<any>();
    let results = [];
    let keys = Object.keys(this.pendingOperations);
    console.log("[dumbDBService][getAllPendingOperations] keys: ", keys);
    keys.forEach(key => {
      results.push(this.pendingOperations[key]);
    });
    setTimeout(() => {
      response.next(results);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  deletePendingOperation(key: string): Observable<any> {
    console.log("[dumbDBService][deletePendingOperations] pre: ", this.pendingOperations);
    let newPendingOps = this.pendingOperations.filter(op => op.id !== key);
    console.log("[dumbDBService][deletePendingOperations] post: ", newPendingOps);
    this.pendingOperations = newPendingOps;
    const response = new Subject<any>();
    setTimeout(() => {
      response.next(true);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  deletePendingOperations(keys: string[]): Observable<any> {
    console.log("[dumbDBService][deletePendingOperations]", keys);
    const response = new Subject<any>();
    let newPendingOps = this.pendingOperations.filter(op => (keys.indexOf(op.id) > -1));
    console.log("[dumbDBService][deletePendingOperations] post: ", newPendingOps);
    this.pendingOperations = newPendingOps;

    setTimeout(() => {
      response.next(true);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  deleteAllPendingOperations(): Observable<any> {
    console.log("[dumbDBService][deleteAllPendingOperations]");
    this.pendingOperations = [];
    const response = new Subject<any>();

    setTimeout(() => {
      response.next(true);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  getPendingOperationKey(objectId: string, op: string) {
    return `${objectId}_${op}`;
  }

  ///

  addFolders(folders: MailFolder[]): Observable<any> {
    console.log("[dumbDBService][addFolders]", folders);

    const response = new Subject<any>();

    setTimeout(() => {
      response.next(true);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  addCalendarFolders(folders: any[]): Observable<any> {
    console.log("[dumbDBService][addFolders]", folders);

    const response = new Subject<any>();

    setTimeout(() => {
      response.next(true);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  getFolders(): Observable<MailFolder[]> {
    console.log("[dumbDBService][getFolders]");

    const response = new Subject<any>();
    const messagesMap = {};
    setTimeout(() => {
      response.next(messagesMap);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  getCalendarFolders(): Observable<any[]> {
    console.log("[dumbDBService][getFolders]");

    const response = new Subject<any>();
    const messagesMap = {};
    setTimeout(() => {
      response.next(messagesMap);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  ///

  getSignatures(): Observable<Signature[]> {
    const response = new Subject<Signature[]>();

    const messagesMap = [];
    setTimeout(() => {
      response.next(messagesMap);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  setSignatures(signatures): Observable<any> {
    console.log("[dumbDBService][setSignatures]", signatures);
    console.log("[dumbDBService][setSignatures] length", signatures.length);
    const response = new Subject<any>();
    setTimeout(() => {
      response.next(true);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  deleteSignatures(ids: string[]): Observable<any> {
    console.log("[dumbDBService][deleteSignatures]", ids);

    const response = new Subject<any>();
    setTimeout(() => {
      response.next(true);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  createSignature(signature: Signature): Observable<any> {
    console.log("[dumbDBService][createSignature]", signature);

    const response = new Subject<Signature>();
    setTimeout(() => {
      response.error(null);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  updateSignature(signature: Signature): Observable<any> {
    console.log("[dumbDBService][updateSignature]", signature);

    const response = new Subject<any>();
    setTimeout(() => {
      response.error(null);
    }, 10);
    return response.asObservable().pipe(take(1));
  }

  ///

  addUsers(users): Observable<any> {
    console.log("[dumbDBService][addUsers]", users);

    const response = new Subject<any>();

    setTimeout(() => {
      response.next(true);
    }, 10);


    return response.asObservable().pipe(take(1));
  }

  getUsers(mailPart): Observable<any[]> {
    console.log("[dumbDBService][getUsers]", mailPart);

    const response = new Subject<any[]>();

    let results = [];
    setTimeout(() => {
      response.next(results);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  ///

  private sortObjects(objects) {
    objects.sort((o1, o2) => o2.d - o1.d);
    return objects;
  }

  ///

  addTags(tags: any[]): Observable<any> {
    const response = new Subject<any>();

    console.log("[dumbDBService][addTags]", tags);

    setTimeout(() => {
      response.next(true);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  getTags(): Observable<any[]> {
    const response = new Subject<any>();
    const messagesMap = {};
    setTimeout(() => {
      response.next(messagesMap);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  getCurrentDBUser(): Observable<any> {
    const response = new Subject<any>();
    const email = localStorage.getItem("currentUserEmail");
    setTimeout(() => {
      response.next(email);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  setCurrentDBUser(email): Observable<any> {
    const response = new Subject<any>();

    console.log("[dumbDBService][setCurrentDBUser]", email);

    setTimeout(() => {
      response.next(true);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  getDatabaseVersion() {
    return 0;
  }

  performDatabaseMigration(): Observable<any> {
    const response = new Subject<any>();
    setTimeout(() => {
      response.next(true);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  updateConvMessagesinDB(messages: Array<Message>, toFolderId: any): Observable<any> {
    const response = new Subject<any>();
    setTimeout(() => {
      response.next(true);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  createOrUpdateMessages(messages: Message[]): Observable<any> {
    return this.addMessages(messages);
  }

  addNewMessagesOnly(messages: Message[]): Observable<any> {
    return this.addMessages(messages);
  }

  getInvalidMsgIds() {
    return [];
  }

  clearInvalidMessageIds() {
    return true;
  }

  deleteAllMessagesFromDB(): Observable<any> {
    const response = new Subject<any>();
    setTimeout(() => {
      response.next(true);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  deleteAllConvsFromDB(): Observable<any> {
    const response = new Subject<any>();
    setTimeout(() => {
      response.next(true);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  createOrUpdateContacts(contacts: any): Observable<any> {
    const response = new Subject<any>();
    setTimeout(() => {
      response.next(true);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  searchContacts(searchText: string): Observable<any> {
    const response = new Subject<any>();
    const result = [];
    setTimeout(() => {
      response.next(result);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  addAttachment(attachment: any): Observable<any> {
    // console.log("[dumbDBService][attachment]", attachment);
    const response = new Subject<any>();
    setTimeout(() => {
      response.next(true);
    }, 10);
    return response.asObservable().pipe(take(1));
  }

  fetchAllUsersFromDatabase(email: string): Observable<string> {
    const response = new Subject<any>();
    const result = [];
    setTimeout(() => {
      response.next(result);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  searchContactsCalendar(searchText: string): Observable<any> {
    const response = new Subject<any>();
    const result = [];
    setTimeout(() => {
      response.next(result);
    }, 10);

    return response.asObservable().pipe(take(1));
  }
}
