
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div fxLayout="column" class="preference-dialog-form" *ngIf="!isMobileView">
  <div class="mail__dialog-header desktop_view">
    <div *ngIf="action !== 'edit'" class="header_lbl disable-select">
      {{ 'ADD_FILTER_HEADING' | translate }}
    </div>
    <div *ngIf="action === 'edit'" class="header_lbl disable-select">
      {{ 'EDIT_FILTER_HEADING' | translate }}
    </div>
    <div class="submit">
      <button mat-button (click)="cancel()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
  </div>
  <div *ngIf="dataIsReady">
    <div class="process-additional-filter" fxLayout="column">
        <mat-checkbox [(ngModel)]="doNotProcessAdditionFilter" (change)="changeAdditionalFilter($event)">{{ 'DO_NOT_PROCESS_ADDITIONAL_FILTER' | translate }}</mat-checkbox>
    </div>
    <form method="post" [formGroup]="form" (ngSubmit)="onSubmit()" [class.updated]="form.valid && form.dirty">
      <div class="filter-dialog-content">
        <div class="padding-left-right" fxLayout="column">
          <mat-form-field>
            <input matInput formControlName="name" placeholder="{{ 'FILTER_NAME' | translate}}" autofocus required>
          </mat-form-field>
          <p *ngIf="isExists" class="error">{{ 'THERE_IS_ALREADY_A_FILTER_BY_THAT_NAME' |translate }}</p>
          <p *ngIf="errorText" class="error existing-name ">
            {{ errorText }}
          </p>
        </div>
        <div class="padding-left-right" fxLayout="row" fxLayout.xs="column">
          <span fxLayout="column" fxLayoutAlign="center start">{{ 'IF' | translate}}</span>
          <span fxLayout="column" fxLayoutAlign="center start">
            <mat-form-field class="condition-form-control">
              <mat-select formControlName="condition">
                <mat-option [value]="'any'">{{ 'ANY_TEXT' | translate}}</mat-option>
                <mat-option [value]="'all'">{{ 'ALL_TEXT' | translate}}</mat-option>
              </mat-select>
            </mat-form-field>
          </span>
          <span fxLayout="column" fxLayoutAlign="center start">
              {{ 'OF_THE_FOLLOWING_CONDITIONS_ARE_MET' | translate}}</span>
        </div>
        <div fxLayout="column" class="filter-tests-section" formArrayName="filterTests">
          <span class="section-header">{{ 'FILTER_CONDITION' | translate }}</span>
          <div [formGroupName]="i" class="rule-section"
            *ngFor="let filterTest of form['controls'].filterTests['controls']; let i=index">
            <div style="width: 100%; display: flex; justify-content: space-between;">
            <div class="half-div">
              <mat-form-field class="full-width">
                <mat-select formControlName="rule" (selectionChange)="setDefaultValue(filterTest)">
                  <mat-option *ngFor="let test of filterConstants.TESTS" [value]="test">{{ getTranslateKey(test) | translate }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="half-div" style="float: right;">
              <mat-form-field class="full-width" *ngIf="filterConstants.MENU2[filterTest.value.rule] && filterConstants.MENU2[filterTest.value.rule] === 'input'">
                <input matInput formControlName="rule2" required>
              </mat-form-field>
              <mat-form-field *ngIf="filterConstants.MENU2[filterTest.value.rule] && filterConstants.MENU2[filterTest.value.rule] !== 'input' && filterConstants.MENU2[filterTest.value.rule] !== 'datetime'"
                class="full-width">
                <mat-select formControlName="rule2">
                  <mat-option *ngFor="let data of filterConstants.MENU2[filterTest.value.rule]" [value]="data">{{
                      getTranslateKey(data) | translate }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            </div>
            <div style="width: 100%;">
              <mat-form-field class="full-width" *ngIf="filterConstants.MENU3[filterTest.value.rule] && filterConstants.MENU3[filterTest.value.rule] === 'input'">
                <input matInput formControlName="rule3" required>
              </mat-form-field>
              <mat-form-field class="full-width" *ngIf="filterConstants.MENU3[filterTest.value.rule] && filterConstants.MENU3[filterTest.value.rule] === 'datetime'">
                <input matInput formControlName="rule3" [matDatepicker]="picker" placeholder="Choose date" required>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker panelClass="create-filter-date-picker"></mat-datepicker>
              </mat-form-field>
              <mat-form-field class="full-width" *ngIf="filterConstants.MENU3[filterTest.value.rule] && filterConstants.MENU3[filterTest.value.rule] !== 'input' && filterConstants.MENU3[filterTest.value.rule] !== 'datetime'">
                <mat-select formControlName="rule3">
                  <mat-option *ngFor="let data of filterConstants.MENU3[filterTest.value.rule]" [value]="data">{{
                      getTranslateKey(data) | translate }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field class="full-width" *ngIf="filterConstants.MENU4[filterTest.value.rule] && filterConstants.MENU4[filterTest.value.rule] === 'input'
              && (filterTest.value.rule !== 'Header Named' || (filterTest.value.rule === 'Header Named' && !['exists', 'does not exist'].includes(filterTest.value.rule3)))
              && (filterTest.value.rule !== 'Address in' || (filterTest.value.rule === 'Address in' && !['exists', 'does not exist', 'is me', 'is not me'].includes(filterTest.value.rule3)))">
                <input matInput formControlName="rule4" required>
              </mat-form-field>
              <mat-form-field class="full-width" *ngIf="filterConstants.MENU4[filterTest.value.rule] && filterConstants.MENU4[filterTest.value.rule] !== 'datetime'
              && filterConstants.MENU4[filterTest.value.rule] !== 'input'
              && ((filterTest.value.rule === 'Message' && filterTest.value.rule3 === 'marked as') || filterTest.value.rule !== 'Message')
              && (filterTest.value.rule !== 'Address in' || (filterTest.value.rule === 'Address in' && !['exists', 'does not exist', 'is me', 'is not me'].includes(filterTest.value.rule3)))
              ">
                <mat-select formControlName="rule4">
                  <mat-option *ngFor="let data of filterConstants.MENU4[filterTest.value.rule]" [value]="data">{{
                      getTranslateKey(data) | translate }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div>
                <div fxLayoutAlign="end" *ngIf="form.controls.filterTests.controls.length > 1">
                  <div fxLayout="row">
                    <button mat-button aria-label="remove" mat-button-icon class="remove-filter" (click)="$event.preventDefault();removeFilterTests(i)"
                      [disabled]="form.controls.filterTests.controls.length < 2">
                      {{ 'REMOVE' | translate }}
                    </button>
                  </div>
                </div>
              <div fxLayoutAlign="end" class="add-filter-part">
                  <div fxLayout="row">
                    <button mat-button aria-label="add" mat-button-icon class="remove-filter" (click)="$event.preventDefault();addFilterTests()">
                      {{ 'ADD_CONDITION' | translate }}
                    </button>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <!-- filter-actions-section -->
        <div fxLayout="column" class="filter-actions-section" formArrayName="filterActions">
          <span class="section-header">{{ 'ACTION' | translate }}</span>
          <div [formGroupName]="i" class="rule-section"
            *ngFor="let filterAction of form['controls'].filterActions['controls']; let i=index">
            <div style="width: 100%; display: flex; justify-content: space-between;">
            <div class="half-div">
              <mat-form-field class="full-width">
                <mat-select formControlName="action" (selectionChange)="setDefaultActionValue(filterAction)">
                  <mat-option *ngFor="let action of filterConstants.ACTIONS" [value]="action">{{ getTranslateKey(action) | translate }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="half-div">
              <mat-form-field class="full-width" *ngIf="filterAction.value.action === 'Redirect to Address'" class="full-width">
                <input type="email" matInput formControlName="value" email
                pattern="^[a-zA-Z0-9.%!#$%&'*+-/=?^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" required>
              </mat-form-field>
              <mat-form-field class="full-width" *ngIf="filterAction.value.action === 'Mark as'" class="full-width">
                <mat-select required formControlName="value">
                  <mat-option value="read">{{ 'READ' | translate}}</mat-option>
                  <mat-option value="flagged">{{ 'FLAGGED' | translate}}</mat-option>
                </mat-select>
              </mat-form-field>
              <div id="filter-action-move" class="full-width" *ngIf="filterAction.value.action === 'Move into folder'">
                  <input type="text" matInput style="display: none" formControlName="value" required>
                  <input type="text" matInput style="display: none" formControlName="name">
                  <button class="browse-button" (click)="openMailFolders(filterAction)" mat-button color="primary">{{ getFolderNameKey(filterAction.value.name) || ('BROWSE_BUTTON' | translate) }}</button>
              </div>
            </div>
            </div>
            <!-- <div fxFlex="30">
            </div> -->
            <!-- <div fxFlex="20" fxFlex.xs="100" fxLayout="row" fxLayoutAlign="end center"> -->
              <!-- <button aria-label="add" mat-button mat-button-icon (click)="addFilterActions()">
                <mat-icon>add</mat-icon>
              </button>
              <button aria-label="remove" mat-button mat-button-icon (click)="removeFilterActions(i)" [disabled]="form['controls'].filterActions['controls'].length < 2">
                <mat-icon>remove</mat-icon>
              </button> -->

            <!-- </div> -->
            <div>
              <div fxLayoutAlign="end" class="add-filter-part" *ngIf="form['controls'].filterActions['controls'].length > 1">
                <div fxLayout="row">
                  <button mat-button aria-label="remove" mat-button-icon class="remove-filter" (click)="$event.preventDefault();removeFilterActions(i)"
                    [disabled]="form['controls'].filterActions['controls'].length < 2">
                    {{ 'REMOVE' | translate }}
                  </button>
                </div>
              </div>
            <div fxLayoutAlign="end" class="add-filter-part">
                <div fxLayout="row">
                  <button mat-button aria-label="add" mat-button-icon class="remove-filter" (click)="$event.preventDefault();addFilterActions()">
                    {{ 'ADD_ACTION' | translate }}
                  </button>
                </div>
              </div>
          </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="preference-filter-bottom desktop-save action-notifier disable-select" *ngIf="!invalid && !isExists &&!invalidAction">
    <div class="notifiler-title disable-select" *ngIf="action !== 'edit'">
        {{ 'ADD_FILTER_HEADING' | translate }}
    </div>
    <div class="notifiler-title disable-select" *ngIf="action === 'edit'">
        {{ 'EDIT_FILTER_HEADING' | translate }}
    </div>
    <div class="save_button_desktop">
      <button mat-button aria-label="add" (click)="save()">
        <mat-icon>check</mat-icon>
      </button>
    </div>
</div>
</div>

<div  class="vp-filter-create-dialog mail-dialog" *ngIf="isMobileView">
  <div class="mail__dialog-header" layout="row center-center ">
      <div>
          <button (click)="cancel()" class="brand-color mat-button show-in-mobile">
              <mat-icon class="disable-select">chevron_left</mat-icon>
          </button>
          <button class="brand-color mat-button hide-in-mobile">
              <mat-icon class="disable-select">account_circle</mat-icon>
          </button>
      </div>
      <div *ngIf="action !== 'edit'" class="header-title ">
          <span class="disable-select">{{ 'ADD_FILTER_HEADING' | translate }}</span>
      </div>
      <div *ngIf="action === 'edit'" class="header_lbl disable-select">
        {{ 'EDIT_FILTER_HEADING' | translate }}
      </div>
  </div>
  <div class="mail__dialog-body">
    <div *ngIf="dataIsReady">
      <div class="process-additional-filter">
        <mat-checkbox [(ngModel)]="doNotProcessAdditionFilter" (change)="changeAdditionalFilter($event)">{{ 'DO_NOT_PROCESS_ADDITIONAL_FILTER' | translate }}</mat-checkbox>
      </div>
      <form method="post" [formGroup]="form" (ngSubmit)="onSubmit()" [class.updated]="form.valid && form.dirty">
        <div class="filter-dialog-content">
          <div class="filter-control">
            <p *ngIf="errorText" class="error existing-name ">
              {{ errorText }}
            </p>
            <mat-form-field>
              <input matInput formControlName="name" placeholder="{{ 'FILTER_NAME' | translate}}" autofocus required>
            </mat-form-field>
            <p *ngIf="isExists" class="error">{{ 'THERE_IS_ALREADY_A_FILTER_BY_THAT_NAME' |translate }}</p>

          </div>
          <div class="filter-control">
            <div class="condition-control">
                <span>{{ 'IF' | translate}}</span>
                <span>
                  <mat-form-field class="condition-form-control">
                    <mat-select formControlName="condition">
                      <mat-option [value]="'any'">{{ 'ANY_TEXT' | translate}}</mat-option>
                      <mat-option [value]="'all'">{{ 'ALL_TEXT' | translate}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </span>
            </div>

            <span>
                {{ 'OF_THE_FOLLOWING_CONDITIONS_ARE_MET' | translate}}</span>
          </div>
          <mat-divider></mat-divider>
          <div fxLayout="column" class="filter-tests-section" formArrayName="filterTests">
            <span class="section-header">{{ 'FILTER_CONDITION' | translate }}</span>
            <div [formGroupName]="i" fxLayout="row" fxLayout.xs="column" class="rule-section"
              *ngFor="let filterTest of form['controls'].filterTests['controls']; let i=index">
              <div fxLayout="column" fxFlex="20" fxFlex.xs="100">
                <mat-form-field class="full-width">
                  <mat-select formControlName="rule" (selectionChange)="setDefaultValue(filterTest)">
                    <mat-option *ngFor="let test of filterConstants.TESTS" [value]="test">{{ getTranslateKey(test) | translate }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div fxFlex="30" fxFlex.xs="100">
                <mat-form-field *ngIf="filterConstants.MENU2[filterTest.value.rule] && filterConstants.MENU2[filterTest.value.rule] === 'input'">
                  <input matInput formControlName="rule2" required>
                </mat-form-field>
                <mat-form-field *ngIf="filterConstants.MENU2[filterTest.value.rule] && filterConstants.MENU2[filterTest.value.rule] !== 'input' && filterConstants.MENU2[filterTest.value.rule] !== 'datetime'"
                  class="full-width">
                  <mat-select formControlName="rule2">
                    <mat-option *ngFor="let data of filterConstants.MENU2[filterTest.value.rule]" [value]="data">{{
                        getTranslateKey(data) | translate }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div fxFlex="30" fxFlex.xs="100">
                <mat-form-field *ngIf="filterConstants.MENU3[filterTest.value.rule] && filterConstants.MENU3[filterTest.value.rule] === 'input'">
                  <input matInput formControlName="rule3" required>
                </mat-form-field>
                <mat-form-field *ngIf="filterConstants.MENU3[filterTest.value.rule] && filterConstants.MENU3[filterTest.value.rule] === 'datetime'">
                  <input matInput formControlName="rule3" [matDatepicker]="picker" placeholder="Choose date" required>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker panelClass="create-filter-date-picker"></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="full-width" *ngIf="filterConstants.MENU3[filterTest.value.rule] && filterConstants.MENU3[filterTest.value.rule] !== 'input' && filterConstants.MENU3[filterTest.value.rule] !== 'datetime'">
                  <mat-select formControlName="rule3">
                    <mat-option *ngFor="let data of filterConstants.MENU3[filterTest.value.rule]" [value]="data">{{
                        getTranslateKey(data) | translate }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div fxFlex="20" fxFlex.xs="100">
                <mat-form-field *ngIf="filterConstants.MENU4[filterTest.value.rule] && filterConstants.MENU4[filterTest.value.rule] === 'input' && (filterTest.value.rule !== 'Header Named' || (filterTest.value.rule === 'Header Named' && !['exists', 'does not exist'].includes(filterTest.value.rule3)))">
                  <input matInput formControlName="rule4" required>
                </mat-form-field>
                <mat-form-field class="full-width" *ngIf="filterConstants.MENU4[filterTest.value.rule] && filterConstants.MENU4[filterTest.value.rule] !== 'datetime' && filterConstants.MENU4[filterTest.value.rule] !== 'input' && ((filterTest.value.rule === 'Message' &&filterTest.value.rule3 === 'marked as') || filterTest.value.rule !== 'Message')">
                  <mat-select formControlName="rule4">
                    <mat-option *ngFor="let data of filterConstants.MENU4[filterTest.value.rule]" [value]="data">{{
                        getTranslateKey(data) | translate }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div fxFlex="10" fxFlex.xs="100" fxLayout="column" fxLayoutAlign="center end" *ngIf="form.controls.filterTests.controls.length > 1">
                <div fxLayout="row">
                  <button mat-button aria-label="remove" mat-button-icon class="remove-filter" (click)="$event.preventDefault();removeFilterTests(i)"
                    [disabled]="form.controls.filterTests.controls.length < 2">
                    {{ 'REMOVE' | translate }}
                  </button>
                </div>
              </div>
            </div>
            <div fxFlex="10" fxFlex.xs="100" fxLayout="column" fxLayoutAlign="center end" class="add-filter-part">
                <div fxLayout="row">
                  <button mat-button aria-label="add" mat-button-icon class="remove-filter" (click)="$event.preventDefault();addFilterTests()">
                    {{ 'ADD_CONDITION' | translate }}
                  </button>
                </div>
              </div>
          </div>
          <!-- filter-actions-section -->
          <mat-divider></mat-divider>
          <div fxLayout="column" class="filter-actions-section" formArrayName="filterActions">
            <span class="section-header">{{ 'ACTION' | translate }}</span>
            <div [formGroupName]="i" fxLayout="row" fxLayout.xs="column" class="rule-section"
              *ngFor="let filterAction of form['controls'].filterActions['controls']; let i=index">
              <div fxFlex="20" fxFlex.xs="100">
                <mat-form-field class="full-width">
                  <mat-select formControlName="action" (selectionChange)="setDefaultActionValue(filterAction)">
                    <mat-option *ngFor="let action of filterConstants.ACTIONS" [value]="action">{{ getTranslateKey(action) | translate }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div fxFlex="30" fxFlex.xs="100" fxLayout="column" fxLayoutAlign="center start">
                <mat-form-field *ngIf="filterAction.value.action === 'Redirect to Address'" class="full-width">
                  <input type="email" matInput formControlName="value" email
                  pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" required>
                </mat-form-field>
                <mat-form-field *ngIf="filterAction.value.action === 'Mark as'" class="full-width">
                  <mat-select formControlName="value">
                    <mat-option value="read">{{ 'READ' | translate}}</mat-option>
                    <mat-option value="flagged">{{ 'FLAGGED' | translate}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <div *ngIf="filterAction.value.action === 'Move into folder'">
                  <input type="text" style="display: none" matInput formControlName="value" required>
                  <input type="text" style="display: none" matInput formControlName="name">
                  <button class="browse-button" (click)="openMailFolders(filterAction)" mat-button color="primary">{{ getFolderNameKey(filterAction.value.name)|| ('BROWSE_BUTTON' | translate) }}</button>
                </div>
              </div>
              <div fxFlex="30">

              </div>
              <div fxFlex="20" fxFlex.xs="100" fxLayout="row" fxLayoutAlign="end center" *ngIf="form['controls'].filterActions['controls'].length > 1">
                <button mat-button aria-label="remove" mat-button-icon class="remove-filter" (click)="removeFilterActions(i)"
                [disabled]="form['controls'].filterActions['controls'].length < 2">
                {{ 'REMOVE' | translate }}
                </button>
              </div>
            </div>

            <div fxFlex="10" fxFlex.xs="100" fxLayout="column" fxLayoutAlign="center end" class="add-filter-part">
                <div fxLayout="row">
                  <button mat-button aria-label="add" mat-button-icon class="remove-filter" (click)="addFilterActions()">
                    {{ 'ADD_ACTION' | translate }}
                  </button>
                </div>
              </div>
          </div>
          <mat-divider></mat-divider>
          <div *ngIf="!invalid && !isExists && !invalidAction" style="height: 45px;"></div>
          <div class="action-notifier disable-select" *ngIf="!invalid && !isExists && !invalidAction">
              <div class="notifiler-title disable-select" *ngIf="action !== 'edit'">
                  {{ 'ADD_FILTER_HEADING' | translate }}
              </div>
              <div class="notifiler-title disable-select" *ngIf="action === 'edit'">
                  {{ 'EDIT_FILTER_HEADING' | translate }}
              </div>
          </div>
          <vp-mail-operation-button (click)="save()" [iconType]="globalOperationIcon" *ngIf="!invalid && !isExists && !invalidAction"></vp-mail-operation-button>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="mail-loading-spinner" *ngIf="!dataIsReady">
  <vp-custom-spinner></vp-custom-spinner>
</div>

