
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  Component, ElementRef, Input, OnChanges, Output, EventEmitter, ChangeDetectorRef,
  LOCALE_ID, Inject, OnInit, OnDestroy, TemplateRef, ChangeDetectionStrategy, ViewChild, AfterViewInit
} from "@angular/core";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import {
  DayView, DayViewHour, DayViewHourSegment, DayViewEvent, ViewPeriod, WeekViewAllDayEvent as WeekViewAllDayAppointment
} from "calendar-utils";
import { Observable, Subject, Subscription, timer } from "rxjs";
import { Store } from "@ngrx/store";
import { takeUntil } from "rxjs/operators";
import { ResizeEvent } from "angular-resizable-element";
import { CalendarDragHelper } from "../common/calendar-drag-helper.provider";
import { CalendarResizeHelper } from "../common/calendar-resize-helper.provider";
import {
  CalendarAppointmentTimesChangedEvent,
  CalendarAppointmentTimesChangedEventType
} from "../common/calendar-appointment-times-changed-event.interface";
import { CalendarUtils } from "../common/calendar-utils.provider";
import {
  validateEvents,
  trackByEventId,
  trackByHour,
  trackByHourSegment,
  getMinutesMoved,
  getDefaultEventEnd,
  getMinimumEventHeightInMinutes,
  trackByDayOrWeekEvent,
  isDraggedWithinPeriod,
  shouldFireDroppedEvent
} from "../common/util";
import { DateAdapter } from "../date-adapters/date-adapter";
import {
  DragEndEvent,
  DragMoveEvent,
  ValidateDrag
} from "angular-draggable-droppable";
import { PlacementArray } from "positioning";
import { CalendarRepository } from "../../repositories/calendar.repository";
import { CalendarAppointment } from "../../../common/models/calendar.model";
import { CalendarState } from "src/app/reducers/calendar.reducer";
import { CalenderUtils } from "../../utils/calender-utils";

export interface CalendarDayViewBeforeRenderEvent {
  body: {
    hourGrid: DayViewHour[];
    allDayEvents: CalendarAppointment[];
  };
  period: ViewPeriod;
}

/**
 * @hidden
 */
export interface DayViewEventResize {
  originalTop: number;
  originalHeight: number;
  edge: string;
}

@Component({
  selector: "vp-calendar-day-view",
  templateUrl: "./calendar-day-view.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarDayViewComponent implements OnChanges, OnInit, OnDestroy, AfterViewInit {
  /**
   * The current view date
   */
  @Input() viewDate: Date;

  /**
   * The number of segments in an hour. Must be <= 6
   */
  @Input() hourSegments: number = 4;

  /**
   * The height in pixels of each hour segment
   */
  @Input() hourSegmentHeight: number = 25;

  /**
   * The day start hours in 24 hour time. Must be 0-23
   */
  @Input() dayStartHour: number = 0;

  /**
   * The day start minutes. Must be 0-59
   */
  @Input() dayStartMinute: number = 0;

  /**
   * The day end hours in 24 hour time. Must be 0-23
   */
  @Input() dayEndHour: number = 23;

  /**
   * The day end minutes. Must be 0-59
   */
  @Input() dayEndMinute: number = 59;

  /**
   * The width in pixels of each event on the view
   */
  @Input() eventWidth: number = 150;

  /**
   * An observable that when emitted on will re-render the current view
   */
  @Input() refresh: Subject<any>;

  /**
   * The locale used to format dates
   */
  @Input() locale: string;

  /**
   * The grid size to snap resizing and dragging of events to
   */
  @Input() eventSnapSize: number;

  /**
   * The placement of the event tooltip
   */
  @Input() tooltipPlacement: PlacementArray = "auto";

  /**
   * A custom template to use for the event tooltips
   */
  @Input() tooltipTemplate: TemplateRef<any>;

  /**
   * Whether to append tooltips to the body or next to the trigger element
   */
  @Input() tooltipAppendToBody: boolean = true;

  /**
   * The delay in milliseconds before the tooltip should be displayed. If not provided the tooltip
   * will be displayed immediately.
   */
  @Input() tooltipDelay: number | null = null;

  /**
   * A custom template to use to replace the hour segment
   */
  @Input() hourSegmentTemplate: TemplateRef<any>;

  /**
   * A custom template to use for day view events
   */
  @Input() eventTemplate: TemplateRef<any>;

  /**
   * A custom template to use for event titles
   */
  @Input() eventTitleTemplate: TemplateRef<any>;

  /**
   * A custom template to use for event actions
   */
  @Input() eventActionsTemplate: TemplateRef<any>;

  /**
   * Whether to snap events to a grid when dragging
   */
  @Input() snapDraggedEvents: boolean = true;

  /**
   * Called when an event title is clicked
   */
  @Output()
  eventClicked = new EventEmitter<{
    event: CalendarAppointment;
    isSelecting: boolean;
  }>();

  /**
   * Called when an hour segment is clicked
   */
  @Output()
  hourSegmentClicked = new EventEmitter<{
    date: Date;
  }>();

  @Output()
  alldayHourSegmentClicked = new EventEmitter<{
    date: Date
  }>();

  /**
   * Called when an event is resized or dragged and dropped
   */
  @Output()
  appointmentTimesChanging = new EventEmitter<CalendarAppointmentTimesChangedEvent>();

  @Output()
  appointmentTimesChanged = new EventEmitter<CalendarAppointmentTimesChangedEvent>();

  /**
   * An output that will be called before the view is rendered for the current day.
   * If you add the `cssClass` property to an hour grid segment it will add that class to the hour segment in the template
   */
  @Output()
  beforeViewRender = new EventEmitter<CalendarDayViewBeforeRenderEvent>();

  @Output()
  onViewSwiped = new EventEmitter<any>();

  @Output()
  onContextMenuClicked = new EventEmitter<any>();

  @ViewChild("calTimeline", {static: false}) calTimeline: ElementRef;

  @Input() events: CalendarAppointment[] = [];

  @Output()
  handleDoubleClicked = new EventEmitter<{
    event: CalendarAppointment;
  }>();

  @Output() hourSegmentContextMenuClicked = new EventEmitter<any>();
  @Output()
  longPressClicked = new EventEmitter<any>();

  /**
   * @hidden
   */
  hours: DayViewHour[] = [];

  /**
   * @hidden
   */
  view: DayView;

  /**
   * @hidden
   */
  width: number = 0;

  /**
   * @hidden
   */
  refreshSubscription: Subscription;

  eventRefresh: Subject<any> = new Subject();

  /**
   * @hidden
   */
  currentResizes: Map<DayViewEvent, DayViewEventResize> = new Map();

  /**
   * @hidden
   */
  eventDragEnter = 0;

  /**
   * @hidden
   */
  calendarId = Symbol("vp calendar day view id");

  /**
   * @hidden
   */
  dragAlreadyMoved = false;

  /**
   * @hidden
   */
  currentDrag?: {
    dayEvent: DayViewEvent;
    originalTop: number;
    originalLeft: number;
  };

  /**
   * @hidden
   */
  validateDrag: ValidateDrag;

  /**
   * @hidden
   */
  validateResize: (args: any) => boolean;

  /**
   * @hidden
   */
  trackByEventId = trackByEventId;

  /**
   * @hidden
   */
  trackByHour = trackByHour;

  /**
   * @hidden
   */
  trackByHourSegment = trackByHourSegment;

  /**
   * @hidden
   */
  trackByDayEvent = trackByDayOrWeekEvent;
  timelineInterval: Subscription;
  eventMarginDefault: number = 70;

  isMobileScreen: boolean = false;
  private isAlive$ = new Subject<boolean>();
  setupHammer: any;

  /**
   * @hidden
   */
  constructor(
    protected cdr: ChangeDetectorRef,
    protected utils: CalendarUtils,
    @Inject(LOCALE_ID) locale: string,
    protected dateAdapter: DateAdapter,
    private breakpointObserver: BreakpointObserver,
    private store: Store<CalendarState>,
    private calendarRepository: CalendarRepository
  ) {
    this.locale = locale;
    this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 599px)");
    this.eventWidth = this.isMobileScreen ? 50 : 150;
    this.hourSegmentHeight = this.isMobileScreen ? 20 : 20;
    this.eventMarginDefault = this.isMobileScreen ? 50 : 70;
    this.snapDraggedEvents = false;
  }

  /**
   * @hidden
   */
  ngOnInit(): void {
    this.timelineInterval = timer(0, 10000).subscribe(result => {
      const calTimelinePosition: any = this.calendarRepository.getCalendarTimelinePosition(this.hourSegmentHeight);
      if (this.calTimeline) {
        this.calTimeline.nativeElement.style.top = calTimelinePosition.position + "px";
      }
    });

    if (this.refresh) {
      this.refreshSubscription = this.refresh.subscribe(() => {
        this.refreshAll();
        this.cdr.markForCheck();
      });
    }
    this.breakpointObserver
    .observe(["(max-width: 599px)"])
    .pipe(takeUntil(this.isAlive$))
    .subscribe((state: BreakpointState) => {
      if (state.matches && !this.setupHammer) {
        if (!!document.querySelector("#dayViewCalHourRows")) {
          this.setupHammer = true;
          new Hammer(<HTMLElement> document.querySelector("#dayViewCalHourRows")).on("swipeleft", () => {
            this.onViewSwiped.emit("swipeleft");
          });
          new Hammer(<HTMLElement> document.querySelector("#dayViewCalHourRows")).on("swiperight", () => {
              this.onViewSwiped.emit("swiperight");
          });
        }
      }
    });
  }

  /**
   * @hidden
   */
  ngOnDestroy(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
      this.timelineInterval.unsubscribe();
      this.isAlive$.next(false);
      this.isAlive$.unsubscribe();
    }
  }

  /**
   * @hidden
   */
  ngOnChanges(changes: any): void {
    const refreshHourGrid =
      changes.viewDate ||
      changes.dayStartHour ||
      changes.dayStartMinute ||
      changes.dayEndHour ||
      changes.dayEndMinute ||
      changes.hourSegments;

    const refreshView =
      changes.viewDate ||
      changes.events ||
      changes.dayStartHour ||
      changes.dayStartMinute ||
      changes.dayEndHour ||
      changes.dayEndMinute ||
      changes.eventWidth;

    if (refreshHourGrid) {
      this.refreshHourGrid();
    }

    if (changes.events) {
      validateEvents(this.events);
    }

    if (refreshView) {
      this.refreshView();
    }

    if (refreshHourGrid || refreshView) {
      this.emitBeforeViewRender();
    }
  }

  appointmentDropped(
    dropEvent: { dropData?: { event?: CalendarAppointment; calendarId?: symbol } },
    date: Date,
    allDay: boolean
  ): void {
    if (shouldFireDroppedEvent(dropEvent, date, allDay, this.calendarId)) {
      // All day event for Day view did not drag and drop
      if (dropEvent.dropData.event.allDay) {
        return;
      }
      this.appointmentTimesChanged.emit({
        type: CalendarAppointmentTimesChangedEventType.Drop,
        event: dropEvent.dropData.event,
        newStart: date,
        allDay
      });
    }
  }

  handleAppointmentClicked(event, isSelecting: boolean) {
    this.eventRefresh.next({ event: event, isSelecting: isSelecting });
    this.eventClicked.emit({ event: event, isSelecting: isSelecting });
  }

  resizeStarted(
    event: DayViewEvent,
    resizeEvent: ResizeEvent,
    dayEventsContainer: HTMLElement
  ): void {
    this.currentResizes.set(event, {
      originalTop: event.top,
      originalHeight: event.height,
      edge: typeof resizeEvent.edges.top !== "undefined" ? "top" : "bottom"
    });
    const resizeHelper: CalendarResizeHelper = new CalendarResizeHelper(
      dayEventsContainer
    );
    this.validateResize = ({ rectangle }) =>
      resizeHelper.validateResize({ rectangle });
    this.cdr.markForCheck();
  }

  resizing(dayEvent: DayViewEvent, resizeEvent: ResizeEvent): void {
    const currentResize: DayViewEventResize = this.currentResizes.get(dayEvent);
    if (typeof resizeEvent.edges.top !== "undefined") {
      dayEvent.top = currentResize.originalTop + +resizeEvent.edges.top;
      dayEvent.height = currentResize.originalHeight - +resizeEvent.edges.top;
    } else if (typeof resizeEvent.edges.bottom !== "undefined") {
      dayEvent.height = currentResize.originalHeight + +resizeEvent.edges.bottom;
    }

    const resizingBeforeStart = currentResize.edge === "top";
    let pixelsMoved: number;
    if (resizingBeforeStart) {
      pixelsMoved = dayEvent.top - currentResize.originalTop;
    } else {
      pixelsMoved = dayEvent.height - currentResize.originalHeight;
    }

    const minutesMoved = getMinutesMoved(
      pixelsMoved,
      this.hourSegments,
      this.hourSegmentHeight,
      this.eventSnapSize
    );

    let newStart: Date = dayEvent.event.start;
    let newEnd: Date = getDefaultEventEnd(
      this.dateAdapter,
      dayEvent.event,
      getMinimumEventHeightInMinutes(this.hourSegments, this.hourSegmentHeight)
    );
    if (resizingBeforeStart) {
      newStart = this.dateAdapter.addMinutes(newStart, minutesMoved);
    } else {
      newEnd = this.dateAdapter.addMinutes(newEnd, minutesMoved);
    }

    this.appointmentTimesChanging.emit({
      newStart,
      newEnd,
      event: dayEvent.event,
      type: CalendarAppointmentTimesChangedEventType.Resize
    });
  }

  resizeEnded(dayEvent: DayViewEvent): void {
    const currentResize: DayViewEventResize = this.currentResizes.get(dayEvent);

    const resizingBeforeStart = currentResize.edge === "top";
    let pixelsMoved: number;
    if (resizingBeforeStart) {
      pixelsMoved = dayEvent.top - currentResize.originalTop;
    } else {
      pixelsMoved = dayEvent.height - currentResize.originalHeight;
    }

    dayEvent.top = currentResize.originalTop;
    dayEvent.height = currentResize.originalHeight;

    const minutesMoved = getMinutesMoved(
      pixelsMoved,
      this.hourSegments,
      this.hourSegmentHeight,
      this.eventSnapSize
    );

    let newStart: Date = dayEvent.event.start;
    let newEnd: Date = getDefaultEventEnd(
      this.dateAdapter,
      dayEvent.event,
      getMinimumEventHeightInMinutes(this.hourSegments, this.hourSegmentHeight)
    );
    if (resizingBeforeStart) {
      newStart = this.dateAdapter.addMinutes(newStart, minutesMoved);
    } else {
      newEnd = this.dateAdapter.addMinutes(newEnd, minutesMoved);
    }

    this.appointmentTimesChanged.emit({
      newStart,
      newEnd,
      event: dayEvent.event,
      type: CalendarAppointmentTimesChangedEventType.Resize
    });
    this.currentResizes.delete(dayEvent);
  }

  dragStarted(
    event: HTMLElement,
    dayEventsContainer: HTMLElement,
    dayEvent: DayViewEvent
  ): void {
    const dragHelper: CalendarDragHelper = new CalendarDragHelper(
      dayEventsContainer,
      event
    );
    this.validateDrag = ({ x, y, transform }) =>
      this.currentResizes.size === 0 &&
      dragHelper.validateDrag({
        x,
        y,
        snapDraggedEvents: this.snapDraggedEvents,
        dragAlreadyMoved: this.dragAlreadyMoved,
        transform
      });
    this.eventDragEnter = 0;
    this.dragAlreadyMoved = false;
    this.currentDrag = {
      dayEvent,
      originalTop: dayEvent.top,
      originalLeft: dayEvent.left
    };
    this.cdr.markForCheck();
  }

  /**
   * @hidden
   */
  dragMove(coords: DragMoveEvent) {
    this.dragAlreadyMoved = true;
    if (this.snapDraggedEvents) {
      this.currentDrag.dayEvent.top = this.currentDrag.originalTop + coords.y;
      this.currentDrag.dayEvent.left = this.currentDrag.originalLeft + coords.x;
    }
  }

  dragEnded(dayEvent: DayViewEvent, dragEndEvent: DragEndEvent): void {
    this.currentDrag.dayEvent.top = this.currentDrag.originalTop;
    this.currentDrag.dayEvent.left = this.currentDrag.originalLeft;
    this.currentDrag = null;
    if (this.eventDragEnter > 0) {
      let minutesMoved = getMinutesMoved(
        dragEndEvent.y,
        this.hourSegments,
        this.hourSegmentHeight,
        this.eventSnapSize
      );
      let newStart: Date = this.dateAdapter.addMinutes(
        dayEvent.event.start,
        minutesMoved
      );
      if (dragEndEvent.y < 0 && newStart < this.view.period.start) {
        minutesMoved += this.dateAdapter.differenceInMinutes(
          this.view.period.start,
          newStart
        );
        newStart = this.view.period.start;
      }
      let newEnd: Date;
      if (dayEvent.event.end) {
        newEnd = this.dateAdapter.addMinutes(dayEvent.event.end, minutesMoved);
      }
      if (isDraggedWithinPeriod(newStart, newEnd, this.view.period)) {
        this.appointmentTimesChanged.emit({
          newStart,
          newEnd,
          event: dayEvent.event,
          type: CalendarAppointmentTimesChangedEventType.Drag,
          allDay: false
        });
      }
    }
  }

  onViewSwipe(event) {
    if (this.isMobileScreen) {
      this.onViewSwiped.emit(event.type);
    }
  }

  isToday(): boolean {
    const viewDate = new Date(this.viewDate);
    const today = new Date();

    return this.dateAdapter.isSameYear(viewDate, today)
      && this.dateAdapter.isSameMonth(viewDate, today)
      && this.dateAdapter.isSameDay(viewDate, today);
  }

  protected refreshHourGrid(): void {
    this.hours = this.utils.getDayViewHourGrid({
      viewDate: this.viewDate,
      hourSegments: this.hourSegments,
      dayStart: {
        hour: this.dayStartHour,
        minute: this.dayStartMinute
      },
      dayEnd: {
        hour: this.dayEndHour,
        minute: this.dayEndMinute
      }
    });
  }

  protected refreshView(): void {
    this.view = this.utils.getDayView({
      events: this.events,
      viewDate: this.viewDate,
      hourSegments: this.hourSegments,
      dayStart: {
        hour: this.dayStartHour,
        minute: this.dayStartMinute
      },
      dayEnd: {
        hour: this.dayEndHour,
        minute: this.dayEndMinute
      },
      eventWidth: this.eventWidth,
      segmentHeight: this.hourSegmentHeight
    });
  }

  protected refreshAll(): void {
    this.refreshHourGrid();
    this.refreshView();
    this.emitBeforeViewRender();
  }

  protected emitBeforeViewRender(): void {
    if (this.hours && this.view) {
      this.beforeViewRender.emit({
        body: {
          hourGrid: this.hours,
          allDayEvents: this.view.allDayEvents
        },
        period: this.view.period
      });
    }
  }

  longPress(ev, event): void {
    console.log("[LongPress] event: ", event);
    this.onContextMenuClicked.emit({ $event: ev, calendarEvent: event });
  }

  handleDoubleClickedHandled(ev): void {
    this.handleDoubleClicked.emit({event: ev});
  }

  ngAfterViewInit(): void {
    CalenderUtils.calendarTimeLineScroll();
  }

}
