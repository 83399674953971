
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ResizableModule } from "angular-resizable-element";
import { DragAndDropModule } from "angular-draggable-droppable";
import { CalendarDayViewComponent } from "./calendar-day-view.component";
import { CalendarDayViewHourSegmentComponent } from "./calendar-day-view-hour-segment.component";
import { CalendarDayViewAppointmentComponent } from "./calendar-day-view-event.component";
import { CalendarCommonModule } from "../common/calendar-common.module";
import { SharedModule } from "./../../../shared/shared.module";

export {
  CalendarDayViewComponent,
  CalendarDayViewBeforeRenderEvent
} from "./calendar-day-view.component";

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    ResizableModule,
    DragAndDropModule,
    CalendarCommonModule
  ],
  declarations: [
    CalendarDayViewComponent,
    CalendarDayViewHourSegmentComponent,
    CalendarDayViewAppointmentComponent
  ],
  exports: [
    ResizableModule,
    DragAndDropModule,
    CalendarDayViewComponent,
    CalendarDayViewHourSegmentComponent,
    CalendarDayViewAppointmentComponent
  ]
})
export class CalendarDayModule {}
