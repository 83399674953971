
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { KeyValue } from "@angular/common";
import { Component, OnInit, OnDestroy, Inject, ViewChild, Input, OnChanges, SimpleChanges, ChangeDetectorRef } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";
import { TranslateService } from "@ngx-translate/core";
import { CalendarEvent } from "calendar-utils";
import moment from "moment";
import { debounceTime, distinctUntilChanged, filter, takeUntil, take } from "rxjs";
import { ConfirmDialogComponent } from "src/app/calendar/confirm-dialog/confirm-dialog.component";
import { EditAppointmentDialogComponent } from "src/app/calendar/edit-appointment/edit-appointment-dialog.component";
import { CalendarRepository } from "src/app/calendar/repositories/calendar.repository";
import { CalenderUtils } from "src/app/calendar/utils/calender-utils";
import { Appointment } from "src/app/common/models/appoinment.model";
import { CalendarFolder, CalendarConfirmData } from "src/app/common/models/calendar.model";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";
import { ToastService } from "src/app/common/providers/toast.service";
import { CalendarConfirmTemplate, CalendarConstants } from "src/app/common/utils/calendar-constants";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { MailService } from "src/app/mail/shared/services/mail-service";
import { MailUtils } from "src/app/mail/utils/mail-utils";
import { getUserProfile, getCalendarFolders } from "src/app/reducers";
import { CalendarState } from "src/app/reducers/calendar.reducer";
import { CommonService } from "src/app/services/ common.service.";
import { ElectronService } from "src/app/services/electron.service";
import { MoveCalendarDialogComponent } from "src/app/shared/components/move-calendar-dialog/move-calendar-dialog.component";
import { ProfileDetailDialogComponent } from "src/app/shared/components/profile-detail-dialog/profile-detail-dialog.component";
import { UserProfile } from "src/app/shared/models";
import { isArray } from "util";
import { Subject } from "rxjs";
import { Store } from "@ngrx/store";
import jstimezonedetect from "jstimezonedetect";


@Component({
    selector: "vp-appointment-preview-common-directive",
    templateUrl: "./appointment-preview-common-directive.component.html",
    styleUrls: ["./appointment-preview-common-directive.component.scss"],
})
export class AppointmentPreviewCommonDirectiveComponent implements OnChanges, OnInit{

    appointment: Appointment;
    startDate: Date;
    endDate: Date;
    calendarFolders: CalendarFolder[] = [];
    alarmReminder: string = "0";
    alarmReminderOption: any;
    alaramList: any[] = [];
    organizeByMe: boolean = true;
    private isAlive$ = new Subject<boolean>();
    currentUser: UserProfile;
    recur: string;
    replyType: string = "";
    readOnly: boolean = false;
    locale: string = "en";
    description: string = "";
    attachedFileList: any[] = [];
    isLoading: boolean;
    organizerAvatar: string = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXUu3vw3S8WLFiJWU44aGj5MtyX3uno_ZHOKq3zfFjSg&s";
    showAttendeesNames: string = "";
    @Input() moduleType = "mail";
    data:any;
    calendarFolderList: any = [];
    isSharedCalendarPrivateEvent: boolean = false;
    visibleData: boolean = false;
    constructor(
        private store: Store<CalendarState>,
        private calendarRepository: CalendarRepository,
        private commonService: CommonService,
        private toastService: ToastService,
        private matDialog: MatDialog,
        private mailService: MailService,
        private translateService: TranslateService,
        private broadcaster: MailBroadcaster,
        private electronService: ElectronService,
        private changeDetectorRef: ChangeDetectorRef,
    ) {


        this.store.select(getCalendarFolders).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.calendarFolderList = res;
        });

        this.commonService.$eventData.pipe(distinctUntilChanged(), debounceTime(50)).subscribe(data => {
            this.data = data;

            this.store.select(getUserProfile).pipe(filter(v => !!v), takeUntil(this.isAlive$)).subscribe(res => {
                let tempProfile = null;
                if (!!res) {
                    tempProfile = res;
                    tempProfile.email = this.getSingleEmail(res.email);
                    this.currentUser = tempProfile;
                } else {
                    const contactUser = localStorage.getItem("profileUser");
                    if (!!contactUser) {
                        this.currentUser = MailUtils.parseUserProfile(contactUser);
                    }
                }
            });
            this.store.select(getCalendarFolders).pipe(take(1)).subscribe(res => {
                if (!!res && res !== null) {
                    this.calendarFolders = res;
                    this.calendarFolders = this.calendarFolders.filter(f => f.id !== "3");
                }
            });
            this.appointment = this.data.appointment;
            this.alaramList = this.calendarRepository.reminderOptions();

            if (this.calendarFolderList.length) {
                this.calendarFolderList.map(cF => {
                    if (cF && cF.zid) {
                        if (this.data?.event?.id?.indexOf(cF.zid) !== -1) {
                            if (this.data.appointment.class === "PRI") {
                                this.isSharedCalendarPrivateEvent = true;
                            }
                        }
                    }
                });
            }

            console.log("[PreviewAppointmentDirective] : ", this.data);
            this.setAttendeesName()
            this.readOnly = this.isReadOnlyFolder(this.appointment?.ciFolder);
            console.log("[PreviewAppointmentDirective ReadOnly Appointment]: ", this.readOnly);
            let clientTimeZoneId = jstimezonedetect.determine().name();
            clientTimeZoneId = clientTimeZoneId?.replace("Asia/Calcutta", "Asia/Kolkata");
            if (this.data.ridZ) {
                const startDate = this.appointment?.startDateData[0]?.d ? moment(this.appointment?.startDateData[0]?.d) : moment();
                const endDate = this.appointment?.endDateData[0]?.d ? moment(this.appointment?.endDateData[0]?.d) : moment();
                const diffDay = endDate.diff(startDate, "days");
                const diffHours = endDate.diff(startDate, "hours");
                const newStartDate = moment(this.data.ridZ).toDate();
                const newEndDate = moment(moment(this.data.ridZ).toDate()).add(diffDay, "days").add(diffHours, "hours").toDate();
                this.startDate = moment(newStartDate).toDate();
                this.endDate = moment(newEndDate).toDate();
            } else if (this.appointment.startDateData && this.appointment.startDateData[0]) {
                if (this.appointment.startDateData[0].tz) {
                    const inviteTimeZone = this.appointment.startDateData[0].tz;
                    if (clientTimeZoneId === inviteTimeZone) {
                        const startDT = this.appointment.startDateData[0].d;
                        this.startDate = moment(startDT).toDate();
                        const endDT = this.appointment.endDateData[0].d;
                        this.endDate = moment(endDT).toDate();
                    } else {
                        const startDT = this.appointment.startDateData[0].d;
                        this.startDate = MailUtils.convertDateToTimezone(moment(startDT).toDate(), inviteTimeZone, clientTimeZoneId);
                        const endDT = this.appointment.endDateData[0].d;
                        this.endDate = MailUtils.convertDateToTimezone(moment(endDT).toDate(), inviteTimeZone, clientTimeZoneId);
                    }
                } else {
                    const startDT = this.appointment.startDateData[0].d;
                    this.startDate = moment(startDT).toDate();
                    const endDT = this.appointment.endDateData[0].d;
                    this.endDate = moment(endDT).toDate();
                    // console.log("[PreviewAppointmentDirective dates] : ", this.startDate, this.endDate);
                }
            }


            if (!this.appointment?.recur) {
                this.recur = "NON";
            } else {
                if(this.appointment && this.appointment.recur && this.appointment.recur[0] &&
                    this.appointment.recur[0]?.add &&
                    this.appointment.recur[0]?.add[0] && this.appointment.recur[0]?.add[0].rule &&
                    this.appointment.recur[0]?.add[0].rule[0] && this.appointment.recur[0].add[0].rule[0].freq
                   ) {
                     this.recur = this.appointment.recur[0]?.add[0]?.rule[0]?.freq;
                   }
            }
            this.organizeByMe = this.appointment.isOrg;
            this.setAlarmToCompose();
            if (this.appointment.reply && this.appointment.reply[0]) {
                this.replyType = this.appointment.reply[0].ptst;
            }
            let body: string = "";
            if (this.appointment?.descHTML) {
                body = this.appointment.descHTML;
            } else if (this.appointment.desc) {
                body = CalenderUtils.plainTextToHTML(this.appointment.desc);
            }
            if (body.indexOf("~*~*~*~*~*~*~*~*") > -1 || body.indexOf("~ ~ ~ ~ ~ ~ ~ ~ ~") > -1) {
                if (body.indexOf("*~*~*~*~*~*~*~*~*~*") !== -1) {
                    if (body.indexOf("The following meeting has been forwarded:") !== -1 || body.indexOf("Betreff: WG:") !== -1) {
                        const bodyItem = body.replace("</body></html>", "").split("*~*~*~*~*~*~*~*~*~*");
                        if (!!bodyItem && !!bodyItem[1]) {
                            body = bodyItem[1];
                        }
                        if (!!bodyItem && !!bodyItem[2]) {
                            body += bodyItem[2];
                        }
                    } else {
                        body = body.replace("</body></html>", "").split("<div>*~*~*~*~*~*~*~*~*~*</div><br>")[1] ||
                        body.split("*~*~*~*~*~*~*~*~*~*")[1] || "";
                    }
                }
                if (body.indexOf("~ ~ ~ ~ ~ ~ ~ ~ ~") !== -1) {
                    body = body.replace("</body></html>", "").split("~ ~ ~ ~ ~ ~ ~ ~ ~")[1] || "";
                }
            }
            this.description = MailUtils.linkifyHtml(body);
            this.description = this.description.replace("dfsrc", "src");
            this.changeDetectorRef.markForCheck();
        })




    }


    ngOnInit() {
        const language =  this.electronService.isElectron
        ? this.electronService.getFromStorage(MailConstants.MAIL_LANGUAGE) : localStorage.getItem(MailConstants.MAIL_LANGUAGE);
        if (!!language && language !== null) {
            this.locale = language;
        } else {
            this.locale = "en";
        }
        if (this.appointment && this.appointment.mp) {
            this.attachedFileList = MailUtils.getAttachments(this.appointment.mp);
        }
        this.broadcaster.on<any>("CLOSE_APPOINTMENT_PREVIEW").subscribe(() => {
            this.close();
        });
    }

    ngOnDestroy() {
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }

    cancel(): void {
        this.close();
    }

    close(): void {
    }

    getFolderColorById(folderId: string) {
        const fld = [...this.calendarFolders , ...CalenderUtils.getChildFolders(this.calendarFolders)];
        let folderColor: string = "#000099";
        if (!!folderId && !!fld) {
            fld.map( f => {
                if (folderId.toString().indexOf(":") !== -1) {
                    const zid = folderId.split(":")[0];
                    const rid = folderId.split(":")[1];
                    if (!!f.rid && f.rid) {
                        if (f.zid === zid && f.rid.toString() === rid) {
                            folderColor = f.folderColor;
                        }
                    } else {
                        if (f.id === folderId) {
                            folderColor = f.folderColor;
                        }
                    }
                } else {
                    if (f.id === folderId) {
                        folderColor = f.folderColor;
                    }
                }
            });
        }
        return folderColor;
    }

    getFolderNameById(folderId: string) {
        const fld = [...this.calendarFolders , ...CalenderUtils.getChildFolders(this.calendarFolders)];
        let folderName: string = "";
        if (!!folderId) {
            fld.map( f => {
                if (folderId.toString().indexOf(":") !== -1) {
                    const zid = folderId.split(":")[0];
                    const rid = folderId.split(":")[1];
                    if (!!f.rid && f.rid) {
                        if (f.zid === zid && f.rid.toString() === rid) {
                            folderName = f.name;
                        }
                    } else {
                        if (f.id === folderId) {
                            folderName = f.name;
                        }
                    }
                } else {
                    if (f.id === folderId) {
                        folderName = f.name;
                    }
                }
            });
        }
        return folderName;
    }

    isReadOnlyFolder(folderId: string): boolean {
        const fld = [...this.calendarFolders , ...CalenderUtils.getChildFolders(this.calendarFolders)];
        let readOnly: boolean = false;
        if (!!folderId && !!fld) {
            const folder = fld.filter(f => !!f).filter(f => f.id === folderId)[0];
            fld.map( f => {
                if (folderId.toString().indexOf(":") !== -1) {
                    const zid = folderId.split(":")[0];
                    const rid = folderId.split(":")[1];
                    if (!!f.rid && f.rid) {
                        if (f.zid === zid && f.rid.toString() === rid) {
                            if (f.perm && (f.perm === "r" || f.perm === "rp")) {
                                readOnly = true;
                            }
                        }
                    } else if (f.id === folderId) {
                        if (f.perm && (f.perm === "r" || f.perm === "rp")) {
                            readOnly = true;
                        }
                    }
                }
                if (f.id === folderId && f.url) {
                    readOnly = true;
                }
            });
            return readOnly;
        }
    }

    setAlarmToCompose(): void {
        const alaramOptions = this.alaramList;
        this.alarmReminder = "0";
        if (this.appointment.alarmData) {
            this.alarmReminder = this.getAlarmUnit(this.appointment.alarmData[0]);
        }
        this.alarmReminder = !!this.alarmReminder ? this.alarmReminder : "-1";
        this.alarmReminder = this.alarmReminder.toString();
        switch (this.alarmReminder) {
            case "0":
                this.alarmReminderOption = alaramOptions[0];
                break;
            case "-1":
                this.alarmReminderOption = alaramOptions[1];
                break;
            case "1":
                this.alarmReminderOption = alaramOptions[2];
                break;
            case "5":
                this.alarmReminderOption = alaramOptions[3];
                break;
            case "10":
                this.alarmReminderOption = alaramOptions[4];
                break;
            case "15":
                this.alarmReminderOption = alaramOptions[5];
                break;
            case "30":
                this.alarmReminderOption = alaramOptions[6];
                break;
            case "45":
                this.alarmReminderOption = alaramOptions[7];
                break;
            case "60":
                this.alarmReminderOption = alaramOptions[8];
                break;
            case "120":
                this.alarmReminderOption = alaramOptions[9];
                break;
            case "180":
                this.alarmReminderOption = alaramOptions[10];
                break;
            case "240":
                this.alarmReminderOption = alaramOptions[11];
                break;
            case "300":
                this.alarmReminderOption = alaramOptions[12];
                break;
            case "1080":
                this.alarmReminderOption = alaramOptions[13];
                break;
            case "1440":
                this.alarmReminderOption = alaramOptions[14];
                break;
            case "2880":
                this.alarmReminderOption = alaramOptions[15];
                break;
            case "4320":
                this.alarmReminderOption = alaramOptions[16];
                break;
            case "5760":
                this.alarmReminderOption = alaramOptions[17];
                break;
            case "10080":
                this.alarmReminderOption = alaramOptions[18];
                break;
            case "20160":
                this.alarmReminderOption = alaramOptions[19];
                break;
        }
    }

    getAlarmUnit(tmp) {
        if (!tmp) { return; }
        let m, h, d, w;
        const trigger = tmp.trigger;
        let _reminderMinutes;
        const rel = (trigger && (trigger.length > 0)) ? trigger[0].rel : null;
        m = (rel && (rel.length > 0)) ? rel[0].m : null;
        d = (rel && (rel.length > 0)) ? rel[0].d : null;
        h = (rel && (rel.length > 0)) ? rel[0].h : null;
        w = (rel && (rel.length > 0)) ? rel[0].w : null;
        _reminderMinutes = 0;
        if (tmp.action === "DISPLAY") {
            if (m !== null) {
                _reminderMinutes = m;
            }
            if (h !== null && !!h) {
                h = parseInt(h, 10);
                _reminderMinutes = h * 60;
            }
            if (d !== null && !!d) {
                d = parseInt(d, 10);
                _reminderMinutes = d * 24 * 60;
            }
            if (w !== null && !!w) {
                w = parseInt(w, 10);
                _reminderMinutes = w * 7 * 24 * 60;
            }
        }
        return _reminderMinutes;
    }

    getSingleEmail(emails) {
        if (isArray(emails)) {
            return emails[0];
        } else if (emails) {
            return emails;
        }
        return null;
    }

    edit(): void {
    }

    sendReplyRequest(verb: string) {
        const body: any = {
            id: this.appointment.id,
            verb: verb,
            updateOrganizer: "TRUE",
        };
        this.commonService.sendInviteReply(body).pipe(take(1)).subscribe(res => {
            if (verb === "ACCEPT") {
                this.toastService.show("CALENDARS.ACCEPT_APPOINTMENT_MSG");
            } else if (verb === "DECLINE") {
                this.toastService.show("CALENDARS.DECLINE_APPOINTMENT_MSG");
            } else if (verb === "TENTATIVE") {
                this.toastService.show("CALENDARS.TENTETIVE_APPOINTMENT_MSG");
            }
            this.close();
        }, error => {
            this.toastService.showPlainMessage(error);
        });
    }

    printAppointment(): void {
        this.calendarRepository.printAppointment(this.appointment.id);
    }

    deleteAppointment(): void {

    }

    deleteOrganizerAppointment(event): void {
        // const body: any = {
        //     id: this.appointment.id
        // };
        // const deleteCalendarEventRef = this.matDialog.open(ConfirmDialogComponent, {
        //     width: "350px",
        //     data: <CalendarConfirmData>{
        //         event: event,
        //         type: "calendarEvent",
        //         confirmTemplate: CalendarConfirmTemplate.CalendarEventDeleteConfirm
        //     }
        // });
        // deleteCalendarEventRef.afterClosed().subscribe(res => {
        //     if (res.confirm) {
        //         this.commonService.deleteInstanceAppointment(body).pipe(take(1)).subscribe(resp => {
        //             this.close();
        //         }, error => {
        //             this.toastService.showPlainMessage(error);
        //         });
        //     }
        // });
    }

    deleteCalendarEvent(event: CalendarEvent, isInstanced: boolean = false, isFromShortcutForRecurEvent: boolean = false) {
        // const deleteCalendarEventRef = this.matDialog.open(ConfirmDialogComponent, {
        //     width: "350px",
        //     data: <CalendarConfirmData>{
        //         event: event,
        //         type: "calendarEvent",
        //         confirmTemplate: isFromShortcutForRecurEvent
        //             ? CalendarConfirmTemplate.CalendarEventShortcutDeleteRecurConfirm
        //             : CalendarConfirmTemplate.CalendarEventDeleteConfirm
        //     }
        // });
        // deleteCalendarEventRef.afterClosed().subscribe(res => {
        //     if (res.confirm) {
        //         if (res.data.isInstance !== undefined) {
        //             this.deleteCalendarEvent(res.data.confirmArgs.event, res.data.isInstance);
        //         } else {
        //             this.calendarRepository.deleteAppointment(res.data.event, isInstanced);
        //         }
        //     }
        // });
    }

    getFolderNameKey(name) {
        if (!!name) {
          const key = name.toUpperCase() + "_FOLDER";
          let folderName = name;
          if (CalendarConstants.SYSTEM_FOLDERS.indexOf(key) !== -1) {
              this.translateService.get("CALENDARS." + key).pipe(take(1)).subscribe(text => {
                  folderName = text;
              });
          }
          return folderName;
        }
        return "";
     }

     moveEvent(): void {
        const event = this.appointment;
        const folder = this.getFolderById(event.ciFolder);
        const dialogRef = this.matDialog.open(MoveCalendarDialogComponent, {
        maxWidth: "100%",
        autoFocus: true,
        panelClass: "calendar-move-dialog",
        data: { folder: folder, isEventMove: true }
        });
        dialogRef.afterClosed().pipe(take(1)).subscribe( res => {
            if (!!res && res.destinationFolder) {
                const destinationFolder = res.destinationFolder;
                const body = {
                    id: event.apptId,
                    l: destinationFolder.id,
                    op: "move"
                };
                this.commonService.itemAction(body).pipe(take(1)).subscribe( resp => {
                    this.translateService.get("CALENDARS.APPOINTMENT_MOVE_TO_MSG", { destinationFolder: destinationFolder.name
                     }).pipe(take(1)).subscribe((text: string) => {
                        this.toastService.show(text);
                     });
                     this.close();
                     this.calendarRepository.getAllAppointments();
                }, error => {
                    this.toastService.showPlainMessage(error);
                });
            }
        });
     }

     getFolderById(folderId: string) {
        const fld = [...this.calendarFolders , ...CalenderUtils.getChildFolders(this.calendarFolders)];
        let calendarFolder: CalendarFolder;
        if (!!folderId) {
            fld.map( f => {
                if (folderId.toString().indexOf(":") !== -1) {
                    const zid = folderId.split(":")[0];
                    const rid = folderId.split(":")[1];
                    if (!!f.rid && f.rid) {
                        if (f.zid === zid && f.rid.toString() === rid) {
                            calendarFolder = f;
                        }
                    } else {
                        if (f.id === folderId) {
                          calendarFolder = f;
                        }
                    }
                } else {
                    if (f.id === folderId) {
                      calendarFolder = f;
                    }
                }
            });
        }
        return calendarFolder;
      }

      copyEvent(): void {
        // const appt = this.appointment;
        // appt.alarmData = {
        //   group: "MINUTES_BEFORE",
        //   text: "5",
        //   value: 5
        // };
        // appt.recur = "NON";
        // this.matDialog.open(EditAppointmentDialogComponent, {
        //     maxWidth: "100%",
        //     autoFocus: false,
        //     panelClass: "edit-calender-appointment-dialog",
        //     id: "edit-calender-appointment-dialog",
        //     data: { appointment: appt, disableRepeat: false , isInstance: false, isNewAppointment: true, isCopyAppointment: true }
        // });
        // this.close();
      }

    isVnctalkMeeting(loc) {
        return !!loc && (loc.indexOf("assets/meeting/?jwt=") > -1);
    }

    openMeetingUrl(url) {
        if (typeof cordova !== "undefined") {
            if (device.platform === "iOS") {
                window.open(url, "_system");
            } else if (device.platform === "Android") {
                navigator.app.loadUrl(url, {
                    openExternal: true
                });
            }
        } else if (this.electronService.isElectron) {
            this.electronService.openExternalUrl(url);
        } else {
            window.open(url, "_blank");
        }
    }


      downloadAttachment(file: any) {
        // this.calendarRepository.openAttachment(this.appointment.id, file.part);
      }

      openProfile(email) {

    }

    setAttendeesName() {
        if(this.appointment?.at && this.appointment?.at.length > 3 ) {
            this.appointment.at.map((at , index) =>{
                if(index < 3) {
                    this.showAttendeesNames = this.showAttendeesNames + at?.d?.charAt(0).toUpperCase() + at?.d?.slice(1);
                    this.showAttendeesNames = this.showAttendeesNames + (index == 2 ? "" : ", ");
                }
            })
            this.showAttendeesNames = this.showAttendeesNames+ " +" + (this.appointment.at.length - 3);
        } else if(this.appointment?.at && this.appointment?.at?.length == 3 ) {
            this.appointment.at.map((at , index) =>{
                if(index <= 3) {
                    this.showAttendeesNames = this.showAttendeesNames + at?.d?.charAt(0).toUpperCase() + at?.d?.slice(1);
                    this.showAttendeesNames = this.showAttendeesNames + (index == 2 ? "" : ", ");
                }
            })
        } else if(this.appointment?.at && this.appointment?.at.length == 2 ) {
            this.appointment.at.map((at , index) =>{
                if(index <= 3) {
                    this.showAttendeesNames = this.showAttendeesNames + at?.d?.charAt(0).toUpperCase() + at?.d?.slice(1);
                    this.showAttendeesNames = this.showAttendeesNames + (index == 1 ? "" : ", ");
                }
            })
        } else if(this.appointment?.at && this.appointment?.at.length == 1 ) {
            this.appointment.at.map((at , index) =>{
                if(index <= 3) {
                    this.showAttendeesNames = this.showAttendeesNames + at?.d?.charAt(0).toUpperCase() + at?.d?.slice(1);
                    this.showAttendeesNames = this.showAttendeesNames;
                }
            })
        }
    }

    @ViewChild("groupParticipantList") groupParticipantList: MatMenuTrigger;
    openParticipantList() {
        this.groupParticipantList.openMenu();
    }

    closeParticipantList() {
        this.groupParticipantList.closeMenu();
    }

    zoomFull: boolean = false;
    zoomToFull() {
        // this.zoomFull = true;
        // const elements = document.querySelectorAll('.appointment-preview-dialog') as NodeListOf<HTMLElement>;
        // if (elements && elements.length) {
        //     elements[0].style.height = '100vh';
        //     elements[0].style.width = '100vw';
        // }
        // const desktopEl = document.querySelectorAll('.desktop') as NodeListOf<HTMLElement>;
        // if (elements && elements.length) {
        //     desktopEl[0].style.height = '100vh';
        // }
    }

    zoomToDefaultSize() {
        // this.zoomFull = false;
        // const elements = document.querySelectorAll('.appointment-preview-dialog') as NodeListOf<HTMLElement>;
        // if (elements && elements.length) {
        //     elements[0].style.height = "500px";
        //     elements[0].style.width = "500px";
        // }
        // const desktopEl = document.querySelectorAll('.desktop') as NodeListOf<HTMLElement>;
        // if (elements && elements.length) {
        //     desktopEl[0].style.height = "auto";
        // }

    }


    ngOnChanges(changes: SimpleChanges): void {

    }
}
