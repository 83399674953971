
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ContactService } from "../services/contact-service";
import { Store } from "@ngrx/store";
import {
  getSelectedContactFolder
} from "../store/reducers/index";
import {
  LoadContactFoldersSuccess,
  LoadContactFolders,
  LoadContactFoldersFail,
  CreateContactFolder,
  CreateContactFolderFail,
  CreateContactFolderSuccess,
  DeleteContactFolder,
  DeleteContactFolderFail,
  DeleteContactFolderSuccess,
  UpdateContactFolder,
  UpdateContactFolderFail,
  UpdateContactFolderSuccess,
  SetSelectedContactFolder,
  SetContactsList
} from "../store/actions/contact-list.action";
import { ContactFolder } from "../models/contact-folder.model";
import { Injectable } from "@angular/core";
import { ContactRootState } from "../store/reducers/index";
import { ErrorService } from "../../common/providers/error-service";
import { ErrorType } from "../shared/contacts-enum";
import { SuccessService } from "../../common/providers/sucess-service";
import { ContactConstants } from "../shared/contacts-constants";
import { SuccessType } from "../shared/contacts-enum";
import { SetTrashFolder } from "../store/index";
import { MessageTranslatorService } from "../services/message-translator-service";
import { TranslateService } from "@ngx-translate/core";
import { take } from "rxjs/operators";
import { ConfigService } from "src/app/config.service";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { getContactFolders } from "../store/selectors";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";
import { ContactUtils } from "src/app/common/utils/contact-utils";
import { SharedUtils } from "src/app/mail/utils/shared.utils";
import { MailUtils } from "src/app/mail/utils/mail-utils";
import { CommonUtils } from "src/app/common/utils/common-util";

@Injectable()
export class ContactsFolderRepository {
  translatedMessages: any = {
    CONTACT_LIST_CREATED_MSG: "",
    CONTACT_LIST_UPDATED_MSG: "",
    SOME_UNKNOWN_ERROR: "",
    DELETE_ITEM_MSG: "",
    UNDO_ITEM_MSG: "",
    EMPTY_ITEM_MSG: ""
  };

  selectedContactFolder: ContactFolder;
  contactFolders: ContactFolder[] = [];

  constructor(
    private contactService: ContactService,
    private store: Store<ContactRootState>,
    private errorService: ErrorService,
    private success: SuccessService,
    private translate: TranslateService,
    private configService: ConfigService,
    private contactBroadCaster: MailBroadcaster
  ) {
    this.getMessages();
    this.configService.currentLanguage.subscribe(lang => {
      this.getMessages();
    });

    this.contactService.getContactsList().subscribe(v => {
      console.log("getContactsList", v);
      this.store.dispatch(new SetContactsList(v.contact_lists || []));
    });
    // if (!!localStorage.getItem("contactFolders")) {
    //   const folders = JSON.parse(localStorage.getItem("contactFolders"));
    //   const contactFolders = folders.filter(f => f.view === "contact") as ContactFolder[];
    //   console.log("[ContactsFolderRepository] contactFolders from localStorage", contactFolders);
    //   this.store.dispatch(
    //     new LoadContactFoldersSuccess({ folders: contactFolders })
    //   );
    // }
  }

  private getMessages(): void {
    this.translate.get(["SOME_UNKNOWN_ERROR", "CONTACT_LIST_CREATED_MSG", "CONTACT_LIST_UPDATED_MSG",
      "DELETE_ITEM_MSG", "UNDO_ITEM_MSG", "EMPTY_ITEM_MSG"]).pipe(take(1)).subscribe(res => {
        this.translatedMessages.SOME_UNKNOWN_ERROR = res.SOME_UNKNOWN_ERROR;
        this.translatedMessages.CONTACT_LIST_CREATED_MSG = res.CONTACT_LIST_CREATED_MSG;
        this.translatedMessages.CONTACT_LIST_UPDATED_MSG = res.CONTACT_LIST_UPDATED_MSG;
        this.translatedMessages.DELETE_ITEM_MSG = res.DELETE_ITEM_MSG;
        this.translatedMessages.UNDO_ITEM_MSG = res.UNDO_ITEM_MSG;
        this.translatedMessages.EMPTY_ITEM_MSG = res.EMPTY_ITEM_MSG;
      });
  }


  public getContactFolders(setSelected?: boolean) {
    if (!!localStorage.getItem("contactFolders")) {
      this.store.dispatch(
        new LoadContactFoldersSuccess({ folders: JSON.parse(localStorage.getItem("contactFolders")) })
      );
    }
    this.store.dispatch(new LoadContactFolders());
    this.contactService.getContactFolders().subscribe(
      res => {
        console.log("[getContactFolders]", res);
        let trashFolder: ContactFolder = res.find(folder => folder.name === "Trash");
        let profileContactList: ContactFolder = res.find(folder => folder.name === ContactConstants.PROFILEADDRESSBOOK);
        this.store.dispatch(
          new SetTrashFolder(trashFolder)
        );
        /*if (res.indexOf(trashFolder) !== -1) {
          res.splice(res.indexOf(trashFolder), 1);
        }*/
        if (res.indexOf(profileContactList) !== -1) {
          res.splice(res.indexOf(profileContactList), 1);
        }
        localStorage.setItem("contactFolders", JSON.stringify(res));
        this.store.dispatch(
          new LoadContactFoldersSuccess({ folders: res })
        );
      },
      err => {
        this.store.dispatch(new LoadContactFoldersFail());
        this.errorService.emit({ id: ErrorType.Generic, messages: err });
      }
    );
  }

  public createContactFolder(id: string, title: string, appView: string, targetFolder?: ContactFolder) {
    this.store.dispatch(new CreateContactFolder());
    this.contactService.createFolder({ folderId: id, name: title, view: appView }).subscribe(res => {
      this.success.emit({ id: SuccessType.ContactListCreateUpdate, messages: this.translatedMessages.CONTACT_LIST_CREATED_MSG });
      this.contactBroadCaster.broadcast(ContactConstants.CONTACT_FOLDER_CREATE_BROADCAST);
      this.contactBroadCaster.broadcast("MARK_FOR_CHECK_BROADCAST");
      if (targetFolder) {
        const folder: ContactFolder = this.getRootFolder(targetFolder);
        this.getUpdatContactRootFolder(folder);
      } else {
        this.store.dispatch(new CreateContactFolderSuccess({ folder: res }));
      }
    }, err => {
      this.store.dispatch(new CreateContactFolderFail());
      if (err.indexOf("invalid name") !== -1) {
        const error = err.split("invalid name:");
        this.translate.get(MailConstants.INVALID_NAME_ERROR_MSG, { characters: error[1]}).pipe(take(1)).subscribe((text: string) => {
          this.errorService.emit({ id: ErrorType.Generic, messages: text });
        });
      } else if (err.indexOf("name already exists") !== -1) {
        const error = err.split("name already exists:");
        this.translate.get(ContactConstants.CONTACT_LIST_ALREADY_EXISTS_MSG,
          { characters: error[1]}).pipe(take(1)).subscribe((text: string) => {
            this.errorService.emit({ id: ErrorType.Generic, messages: text });
        });
      } else {
        this.errorService.emit({ id: ErrorType.Generic, messages: err });
      }
    });
  }

  public updateContactFolder(contactFolder: ContactFolder, newTitle: string) {
    this.store.dispatch(new UpdateContactFolder());
    let oldtitle = contactFolder.name;
    let selectedFolderTitle = null;
    if (this.selectedContactFolder !== undefined) {
      selectedFolderTitle = this.selectedContactFolder.name;
    }
    this.contactService.folderAction({ id: contactFolder.id, name: newTitle, op: "rename" }).subscribe(res => {
      contactFolder.name = newTitle;
      let path = contactFolder.absFolderPath.substring(0, contactFolder.absFolderPath.lastIndexOf("/") + 1);
      contactFolder.absFolderPath = path + contactFolder.name;
      this.store.dispatch(new UpdateContactFolderSuccess({ id: contactFolder.id, changes: contactFolder }));
      this.success.emit({ id: SuccessType.ContactListCreateUpdate, messages: this.translatedMessages.CONTACT_LIST_UPDATED_MSG });
      if (oldtitle === selectedFolderTitle) {
        this.store.dispatch(new SetSelectedContactFolder(contactFolder));
      }
      this.contactBroadCaster.broadcast("MARK_FOR_CHECK_BROADCAST");
    }, err => {
      this.store.dispatch(new UpdateContactFolderFail());
      if (err.indexOf("invalid name") !== -1) {
        const error = err.split("invalid name:");
        this.translate.get(MailConstants.INVALID_NAME_ERROR_MSG, { characters: error[1]}).pipe(take(1)).subscribe((text: string) => {
          this.errorService.emit({ id: ErrorType.Generic, messages: text });
        });
      } else if (err.indexOf("name already exists") !== -1) {
        const error = err.split("name already exists:");
        this.translate.get(ContactConstants.CONTACT_LIST_ALREADY_EXISTS_MSG,
          { characters: error[1]}).pipe(take(1)).subscribe((text: string) => {
            this.errorService.emit({ id: ErrorType.Generic, messages: text });
        });
      } else {
        this.errorService.emit({ id: ErrorType.Generic, messages: err });
      }
    });
  }

  public deleteContactFolder(contactFolder: ContactFolder) {
    this.store.dispatch(new DeleteContactFolder());
    this.contactService.folderAction({ id: contactFolder.id, op: "trash" }).subscribe(res => {
      if (contactFolder.l === "1") {
        this.store.dispatch(new DeleteContactFolderSuccess({ folder: contactFolder }));
      } else {
        this.removeChildFromParent(contactFolder);
      }
      const trashFolder = this.contactFolders.filter(f => f.id === "3")[0];
      if (!!trashFolder) {
        this.mantainDestinationFolder(trashFolder);
      }
      this.success.emit({ id: SuccessType.ContactListDelete, messages: this.translatedMessages.DELETE_ITEM_MSG, data: contactFolder });
    }, err => {
      this.store.dispatch(new DeleteContactFolderFail());
      this.errorService.emit({ id: ErrorType.Generic, messages: err });
    });
  }

  public restoreDeletedContactFolder(contactFolder: ContactFolder) {
    this.store.dispatch(new CreateContactFolder());
    this.contactService.folderAction({ id: contactFolder.id, op: "move", l: 1, recursive: true }).subscribe(res => {
      this.store.dispatch(new CreateContactFolderSuccess({ folder: contactFolder }));
      this.success.emit({ id: SuccessType.GenericMessage, messages: this.translatedMessages.UNDO_ITEM_MSG });
    }, err => {
      this.store.dispatch(new DeleteContactFolderFail());
      this.errorService.emit({ id: ErrorType.Generic, messages: err });
    });
  }

  public mantainDestinationFolder1(destination: ContactFolder) {
    this.getContactFolders();
  }

  public mantainDestinationFolder(destination: ContactFolder) {
    const folder = this.getRootFolder(destination);
      if (folder) {
        const body = {
          view: "contact",
          folder: {
            uuid: folder.uuid
          }
        };
        this.contactService.getContactFolderList(body).subscribe(res => {
          if (!!res && res.folder) {
            const tempFolder = <any> SharedUtils.parseFolders(res, true);
            this.store.dispatch(new UpdateContactFolderSuccess({ id: folder.id, changes: tempFolder[0] }));
          }
          this.contactBroadCaster.broadcast("MARK_FOR_CHECK_BROADCAST");
        });
      }
  }

  mantainSourceFolder() {
    const folder = this.getRootFolder(this.selectedContactFolder);
    if (folder) {
      const body = {
        view: "contact",
        folder: {
          uuid: folder.uuid
        }
      };
      this.contactService.getContactFolderList(body).subscribe(res => {
        if (!!res && res.folder) {
          const tempFolder = <any> SharedUtils.parseFolders(res, true);
          this.store.dispatch(new UpdateContactFolderSuccess({ id: folder.id, changes: tempFolder[0] }));
        }
        this.contactBroadCaster.broadcast("MARK_FOR_CHECK_BROADCAST");
      });
    }
  }

  updateOfflineFolder(value: number) {
    if (this.selectedContactFolder) {
      let length: number = Number(this.selectedContactFolder.n) + value;
      this.selectedContactFolder.n = length;
      this.store.dispatch(new SetSelectedContactFolder(this.selectedContactFolder));
    }
  }

  getRootFolder(targetFolder: ContactFolder): ContactFolder {
    if (!targetFolder) {
      return null;
    }
    if (targetFolder.id && targetFolder.id.includes(":")) {
      const folderNames: any[] = targetFolder.absFolderPath.split("/");
      const regShareFolderIdExp = /[^:\\]+/;
      const folderZid = targetFolder.id.match(regShareFolderIdExp)[0];

      const rootFolder = this.contactFolders.find(folder => (folder.zid && folder.zid === folderZid &&
        folderNames.indexOf(folder.oname) > -1));
      return rootFolder;
    } else if (targetFolder.absFolderPath) {
      const reg = /[^/\\]+/;
      const rootFolderName = targetFolder.absFolderPath.match(reg)[0];
      return this.contactFolders.find(folder => folder.name.toLowerCase() === rootFolderName.toLowerCase());
    }
  }

  getUpdatContactRootFolder(folder: ContactFolder, keyMessage?) {
    if (!folder) {
      return;
    }
    const body = {
      view: "contact",
      folder: {
        uuid: folder.uuid
      }
    };
    this.contactService.getContactFolderList(body).subscribe(res => {
      if (!!res && res.folder) {
        const tempFolder = <any> SharedUtils.parseFolders(res, true);
        this.store.dispatch(new UpdateContactFolderSuccess({ id: folder.id, changes: tempFolder[0] }));
      }
    });
  }

  createFlatFolder(folders: ContactFolder[]): void {
    for (let i = 0; i < folders.length; i++) {
      const folder = folders[i];
      this.contactService.flatFolders[folder.id] = folder;
      this.contactService.flatArrayFolders.push(folder);
      if (folder.perm) {
        if (folder.id.indexOf(":") !== -1 ) {
          this.contactService.sharedIds.push("inid:" + "\"" + folder.id + "\"");
        } else {
          this.contactService.sharedIds.push("inid:" + folder.id);
        }
      }
      if (folder.children) {
        this.createFlatFolder(folder.children);
      }
    }
  }

  removeChildFromParent(folder) {
    const updatedFolder = this.contactService.flatFolders[folder.id];
    if (updatedFolder) {
      const parentFolder = <any> MailUtils.getParentById(this.contactService.flatFolders, updatedFolder.l);
      console.log("[removeChildFromParent] found the folder", updatedFolder);
      if (parentFolder) {
        MailUtils.removeChildFolder(parentFolder, folder);
        this.store.dispatch(new UpdateContactFolderSuccess({ id: parentFolder.id, changes: parentFolder }));
        console.log("[removeChildFromParent] found the parent and update", parentFolder);
      }
    }
  }

  getAllContactFolder(): ContactFolder[] {
    const allFolders = [...this.contactFolders, ...CommonUtils.getChildFolders(this.contactFolders)];
    return allFolders;
  }

  isDisableContactList(folderId: string): boolean {
    let isDisable: boolean = false;
    const allFolders = this.getAllContactFolder();
    const findFolder = allFolders.filter(f => f.id === folderId)[0];
    if (findFolder === undefined) {
      isDisable = true;
    }
    if (!!findFolder && (findFolder.l === "3" || findFolder.id === "3")) {
      isDisable = true;
    }
    if (!!findFolder && findFolder.perm && findFolder.perm === "r") {
      isDisable = true;
    }
    return isDisable;
  }

  getSelectedSubFolder(folderId: string, rootFolder: ContactFolder): ContactFolder {
    if (rootFolder === null || rootFolder === undefined) {
      return null;
    }
    if (rootFolder.id === folderId) {
      return rootFolder;
    }
    if (rootFolder.children) {
      for (let i = 0; i < rootFolder.children.length; i++) {
        if (folderId === rootFolder.children[i].id) {
          return rootFolder.children[i];
        } else if (rootFolder.children[i].children) {
          const f =  this.getSelectedSubFolder(folderId, rootFolder.children[i]);
          if (f) {
            return f;
          }
        }
      }
    } else {
      return null;
    }
  }

  getContactListByName(name: string): ContactFolder {
    const allFolders = [...this.contactFolders, ...CommonUtils.getChildFolders(this.contactFolders)];
    const folder = allFolders.filter(f => f.name === name)[0];
    if (!!folder) {
      return folder;
    }
    return null;
  }

  public deletePermanentContactFolder(contactFolder: ContactFolder) {
    this.store.dispatch(new DeleteContactFolder());
    this.contactService.folderAction({ id: contactFolder.id, op: "delete" }).subscribe(res => {
      this.removeChildFromParent(contactFolder);
      this.success.emit({
        id: SuccessType.ContactListDeleteParmanent,
        messages: this.translatedMessages.DELETE_ITEM_MSG,
        data: contactFolder
      });
    }, err => {
      this.store.dispatch(new DeleteContactFolderFail());
      this.errorService.emit({ id: ErrorType.Generic, messages: err });
    });
  }

}
