
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Input, Output, OnInit, EventEmitter, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { Router } from "@angular/router";
import { ContactConstants } from "../../shared/contacts-constants";
import { Store } from "@ngrx/store";
import { ErrorService } from "../../../common/providers/error-service";
import { ErrorType } from "../../shared/contacts-enum";
import { TranslateService } from "@ngx-translate/core";
import { ContactBroadcaster } from "../../../common/providers/contact-broadcaster.service";
import { SidebarService } from "../../sidebar/sidebar.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { CommonUtils } from "src/app/common/utils/common-util";
import { MessageTranslatorService } from "../../services/message-translator-service";
import { reducers } from "src/app/reducers";
import { ContactRootState } from "../../store";
import { getContactFolders } from "../../store/selectors";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";
import { FormControl, Validators } from "@angular/forms";


@Component({
  selector: "vp-advanced-contact-search",
  templateUrl: "./advanced-contact-search.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdvancedContactSearchComponent implements OnInit, OnDestroy {
  @Output() onClose = new EventEmitter();
  @Input() parentContainer;
  selectedContactFolder: string = null;
  // searchText: string;
  contactFolders: any[] = [];
   private isAlive$ = new Subject<boolean>();
  searchBlankErrorMsg: string;
  isMobileView: boolean = CommonUtils.isOnMobileDevice();

  searchText: FormControl = new FormControl("", Validators.required);
  searchFilterControl: FormControl = new FormControl("");

  constructor(
    private contactStore: Store<ContactRootState>,
    private router: Router,
    private errorService: ErrorService,
    private translate: TranslateService,
    private contactBroadcaster: MailBroadcaster,
    private sidebarService: SidebarService,
    private changeDetection: ChangeDetectorRef,
    private messageService: MessageTranslatorService
    ) {
  }

  ngOnInit() {
    this.contactStore.select(getContactFolders).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      if (!!res && res.length > 0) {
        this.contactFolders = [];
        let lang = localStorage.getItem("mailLanguage");
        const allFolders = [...res, ...CommonUtils.getChildFolders(res)];
        allFolders.forEach(folder => {
          let viewValue: string = folder.name;
          if (lang && lang === "de" && folder.id === "7") {
            viewValue = "Kontakte";
          } else if (lang && lang === "de" && folder.id === "13") {
            viewValue = "E-Mail-Kontakte";
          }
          this.contactFolders.push({
            value: folder.name,
            label: viewValue
          });
          this.changeDetection.markForCheck();
        });
      }
    });

    this.contactBroadcaster.on<any>("hideAdvanceContactSearchDialog").pipe(takeUntil(this.isAlive$))
      .subscribe(presence => {
        this.close();
        this.changeDetection.markForCheck();
      });
  }

  submit() {
    if (!this.searchText.valid) {
      if (document.querySelector("#demo-toast-example") === null) {
        this.errorService.emit({ id: ErrorType.Generic,
          messages: this.messageService.getTranslation(ContactConstants.SEARCH_STRING_ERR_MSG) });
      }
      return;
    } else {
      this.navigate({ searchText: this.searchText.value, folderName: this.searchFilterControl.value });
    }
  }

  navigate(params: any) {
    if (!params) {
      params = {};
    }
    this.router.navigate(["/contacts", ContactConstants.CONTACT_SEARCH_TITLE], { queryParams: params });
    this.close();
    this.sidebarService.showAdvancedContactFilters$.next(false);
  }

  clear() {
    this.searchText.patchValue("");
    this.searchFilterControl.patchValue(null);
    setTimeout(() => {
      this.changeDetection.markForCheck();
    }, 100);
  }

  close() {
    this.onClose.emit();
    this.changeDetection.markForCheck();
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.unsubscribe();
  }
}
