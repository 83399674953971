
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  Component, Inject, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy,
  ElementRef, ViewChild, NgZone, AfterViewInit
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { ToastService } from "../../common/providers/toast.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs/internal/Subject";
import { Store } from "@ngrx/store";
import { NgxHotkeysService } from "ngx-hotkeys-vnc";
import { CalendarFolder } from "src/app/common/models/calendar.model";
import { CalendarColorControlDialogComponent } from "../color-control-component/color-control-dialog.component";
import { CalendarRepository } from "../repositories/calendar.repository";
import { CalendarState } from "src/app/reducers/calendar.reducer";
import { getCalendarFolders } from "src/app/reducers";

@Component({
  selector: "vp-create-calendar-folder",
  templateUrl: "./calendar-create-folder.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class CreateCalendarFolderComponent implements OnDestroy, AfterViewInit {

  folderTitle: string = "";
  maxFolderTitleLength: number = 128;
  isRename: boolean = false;
  oldFlderName: string = "";
  private isAlive$ = new Subject<boolean>();
  calendarRootFolders: CalendarFolder[] = null;
  folderColor: string;
  freeBusy: boolean = false;
  externalURL: string;
  @ViewChild("folderNameInput", { static: false }) folderNameInput: ElementRef<HTMLInputElement>;

  constructor(
    private dialogRef: MatDialogRef<CreateCalendarFolderComponent>,
    public toastService: ToastService,
    private changeDetectionRef: ChangeDetectorRef,
    private store: Store<CalendarState>,
    private ngZone: NgZone,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private hotKeyService: NgxHotkeysService,
    private calendarRepository: CalendarRepository
  ) {
    this.hotKeyService.pause(this.hotKeyService.hotkeys);
    if (!this.data.targetFolder) {
      this.store.select(getCalendarFolders).pipe(takeUntil(this.isAlive$)).subscribe(res => {
        if (res) {
          this.calendarRootFolders = res;
        }
      });
    }
    this.changeDetectionRef.markForCheck();

    setTimeout(() => {
      if (this.folderNameInput) {
        this.folderNameInput.nativeElement.focus();
      }
    }, 500);

    console.log("[CreateFolderComponent]");
    setTimeout(() => {
      this.changeDetectionRef.detectChanges();
    }, 50);
  }

  changeText(event) {
    if (this.folderTitle.length > this.maxFolderTitleLength) {
      this.folderTitle = this.folderTitle.substring(0, this.maxFolderTitleLength);
      this.changeDetectionRef.markForCheck();
    }
  }

  closeDialog(): void {
    this.close();
  }

  updateDefaultColor(event) {
    this.folderColor = event.value;
    this.changeDetectionRef.markForCheck();
  }

  folderAction(): void {
    if (this.folderTitle.length < 1) {
      return;
    }
    if (this.isFolderAlreadyExist()) {
      this.toastService.show("DUPLICATE_CALENDER_FOLDER_MSG");
      return;
    }
    if (this.data.externalURL) {
      this.externalURL = this.data.externalURL;
    }
    this.calendarRepository.createCalendarFolder(
      this.data.targetFolder, this.folderTitle,  this.freeBusy , this.folderColor, this.externalURL
    );
    this.close();
  }

  openColorDialog() {
    const changeColorDialogRef = this.matDialog.open(CalendarColorControlDialogComponent, {
      height: "auto",
      maxHeight: "70%",
      width: "99%",
      maxWidth: "244px",
      autoFocus: false,
      panelClass: "calendar-color-control-dialog",
      data: { folderColor: this.folderColor }
    });
    changeColorDialogRef.afterClosed().subscribe(operation => {
      if (!!operation && operation !== null && operation.selectedColor) {
        this.folderColor = operation.selectedColor;
      }
    });
  }

  isFolderAlreadyExist() {
    if (!this.data.targetFolder && this.calendarRootFolders.find(folder => folder.name.toLowerCase() === this.folderTitle.toLowerCase())) {
      return true;
    }
    if (this.data.targetFolder && this.data.targetFolder.children) {
      if (this.data.targetFolder.children.find(f => f.name.toLowerCase() === this.folderTitle.toLowerCase())) {
        return true;
      }
    }
    return false;
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
    this.hotKeyService.unpause(this.hotKeyService.hotkeys);
  }

  ngAfterViewInit() {
    this.changeDetectionRef.detectChanges();
  }

  close(): void {
    this.dialogRef.close();
  }
}
