
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Input, TemplateRef, ChangeDetectorRef, EventEmitter, Output } from "@angular/core";
import { DayViewHourSegment } from "calendar-utils";
import { fromEvent } from "rxjs";
import { finalize, takeUntil } from "rxjs/operators";
import { addDays, addMinutes, endOfWeek } from "date-fns";

@Component({
  selector: "vp-calendar-day-view-hour-segment",
  template: `
    <ng-template
      #defaultTemplate
      let-segment="segment"
      let-locale="locale"
      let-segmentHeight="segmentHeight"
      let-hourSegmentContextMenuClicked="hourSegmentContextMenuClicked"
    >
      <div class="cal-hour-segment"
        [style.height.px]="segmentHeight"
        [class.cal-hour-start]="segment.isStart"
        [class.cal-after-hour-start]="!segment.isStart"
        [ngClass]="segment.cssClass"
        (contextmenu)="hourSegmentContextMenuClicked.emit({ $event: $event, segment: segment })"
      >
        <div class="cal-time">
          <span *ngIf="locale !== 'en'">{{ segment.date | date: "HH:mm": "":locale }}</span>
          <span *ngIf="locale === 'en'">{{ segment.date | date: "h a": "":locale }}</span>
        </div>
      </div>
    </ng-template>
    <ng-template
      [ngTemplateOutlet]="customTemplate || defaultTemplate"
      [ngTemplateOutletContext]="{
        segment: segment,
        locale: locale,
        segmentHeight: segmentHeight,
        hourSegmentContextMenuClicked: hourSegmentContextMenuClicked
      }"></ng-template>
  `
})
export class CalendarDayViewHourSegmentComponent {
  @Input() segment: DayViewHourSegment;

  @Input() segmentHeight: number;

  @Input() locale: string;

  @Input() customTemplate: TemplateRef<any>;

  @Output() hourSegmentContextMenuClicked = new EventEmitter<any>();

  // constructor(private cdr: ChangeDetectorRef) { }

  // startDragToCreate(segment: DayViewHourSegment, mouseDownEvent: MouseEvent, segmentElement: HTMLElement) {
  //   console.log("start: ", segment);
  //   // const dragToSelectEvent: CalendarEvent = {
  //   //   id: this.events.length,
  //   //   title: 'New event',
  //   //   start: segment.date,
  //   //   meta: {
  //   //     tmpEvent: true
  //   //   }
  //   // };

  //   // this.events = [...this.events, dragToSelectEvent];
  //   const segmentPosition = segmentElement.getBoundingClientRect();

  //   // this.dragToCreateActive = true;
  //   // const endOfView = endOfWeek(this.viewDate);

  //   fromEvent(document, "mousemove")
  //     .pipe(
  //       finalize(() => {
  //         // delete dragToSelectEvent.meta.tmpEvent;
  //         // this.dragToCreateActive = false;
  //         // this.refresh();
  //       }),
  //       takeUntil(fromEvent(document, "mouseup"))
  //     )
  //     .subscribe((mouseMoveEvent: MouseEvent) => {
  //       const minutesDiff = this.ceilToNearest(
  //         mouseMoveEvent.clientY - segmentPosition.top,
  //         30
  //       );

  //       const daysDiff =
  //         this.floorToNearest(
  //           mouseMoveEvent.clientX - segmentPosition.left,
  //           segmentPosition.width
  //         ) / segmentPosition.width;

  //       const newEnd = addDays(addMinutes(segment.date, minutesDiff), daysDiff);

  //       console.log("segment: ", segment);
  //       console.log("mouseDownEvent: ", mouseDownEvent);
  //       console.log("segmentElement: ", segmentElement);
  //       console.log("minutesDiff: ", minutesDiff);
  //       console.log("newEnd: ", newEnd);


  //       // if (newEnd > segment.date && newEnd < endOfView) {
  //       //   dragToSelectEvent.end = newEnd;
  //       // }
  //       // this.refresh();
  //     });
  // }


  private floorToNearest(amount: number, precision: number) {
    return Math.floor(amount / precision) * precision;
  }

  private ceilToNearest(amount: number, precision: number) {
    return Math.ceil(amount / precision) * precision;
  }
}
